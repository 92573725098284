export const CallInfo = {
    labelCurrency: 'EUR',
    labelDestinationNumber: '',
    labelTime: 0,
    labelCharge:0
}

export interface VerificationNumberAttempt {
    id: string;
    commit: boolean;
    verified: boolean;
    phone_number: string;
}