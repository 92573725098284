<app-onboarding-header></app-onboarding-header>
<form class="md:max-w-xl md:m-auto" [formGroup]="registerForm">
  <div class="flex flex-col items-center p-4">
    <h2>{{ 'ONBOARDING.REGISTER.TITLE' | translate }}</h2>
    <mat-form-field class="calls-full-width">
      <mat-label>{{ 'ONBOARDING.REGISTER.USER' | translate }}</mat-label>
      <input type="text" matInput formControlName="username" />
      <mat-error *ngIf="registerForm.get('username')?.hasError('pattern')">
        {{ 'ONBOARDING.REGISTER.WHITE_SPACE' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="calls-full-width">
      <mat-label>{{ 'ONBOARDING.REGISTER.EMAIL' | translate }}</mat-label>
      <input type="email" matInput formControlName="email" />
      <mat-error *ngIf="registerForm.get('email')?.hasError('pattern')">
        {{ 'ONBOARDING.REGISTER.WHITE_SPACE' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="calls-full-width">
      <mat-label>{{ 'ONBOARDING.REGISTER.PASS1' | translate }}</mat-label>
      <input type="password" matInput formControlName="password" />
      <mat-error *ngIf="registerForm.get('password')?.hasError('pattern')">
        {{ 'ONBOARDING.REGISTER.WHITE_SPACE' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="calls-full-width">
      <mat-label>{{ 'ONBOARDING.REGISTER.PASS2' | translate }}</mat-label>
      <input type="password" matInput formControlName="password2" />
      <mat-error *ngIf="registerForm.get('password2')?.hasError('pattern')">
        {{ 'ONBOARDING.REGISTER.WHITE_SPACE' | translate }}
      </mat-error>
    </mat-form-field>
    <input type="checkbox" id="acceptTermsCheckbox" (click)="toggleTerms()">
    <label for="acceptTermsCheckbox">
      {{ 'ONBOARDING.REGISTER.TERMS' | translate }}
    </label>
    <br><br>
    <app-button (click)="signUp()" [disabled]="!acceptTermsControl">
      {{ 'ONBOARDING.REGISTER.BUTTON' | translate }}
    </app-button>

  </div>
  <div class="p-4">
    <hr />
  </div>
  <div class="flex flex-col items-center p-4">
    <p class="text-sm mb-8 font-medium">
      {{ 'ONBOARDING.REGISTER.QUESTION' | translate }}
    </p>
    <app-button color="secondary" routerLink="/onboarding/login" [replaceUrl]="true">
      {{ 'ONBOARDING.REGISTER.GO_BACK' | translate }}
    </app-button>
  </div>
</form>
<style>
  .mat-mdc-form-field-error {
    color: red;
}
</style>