import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit-my-profile',
  templateUrl: './edit-profile.component.html',
})
export class EditMyProfileComponent implements OnInit {

  user = inject(AuthenticatorService).user;
  auth = inject(AuthService);
  _snackBar = inject(MatSnackBar);
  router = inject(Router);
  createForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    phone_number: new FormControl('', [
      Validators.pattern(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
      ),
    ]),
  });

  ngOnInit(): void {
    const name = this.user.attributes?.['name'];
    this.createForm.patchValue({
      name: name,
      phone_number: this.user.attributes?.phone_number,
      email: this.user.attributes?.email,
    });
  }

  async onUpdate() {
    const fromValue = this.createForm.getRawValue();
    await this.auth.updateUser(fromValue);
    this._snackBar.open('Your profile was updated successfully', 'Close', {
      duration: 5000,
    });
    this.goBack();
  }

  goBack() {
    this.router.navigate(['profile'], { replaceUrl: true });
  }
}
