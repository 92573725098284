import { Component, Inject, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TUI_IS_IOS, TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { TabsService } from './tabs.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/config.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent {
  isVisible$ = this.tabsService.isVisible$;
  window = window;
  transfersUrl = `${inject(ConfigService).config.transfersUrl}?tracker=${inject(AuthService).getUser().id}`;
  constructor(
    public router: Router,
    @Inject(TUI_IS_MOBILE) readonly isMobile: boolean,
    @Inject(TUI_IS_IOS) readonly isIos: boolean,
    private tabsService: TabsService
  ) {}
}
