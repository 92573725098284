import { Component, OnInit, inject } from "@angular/core";
import { BalanceService } from "../../services/balance.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SignalWireService } from "../calls/signalwire/signalwire.service";

@Component({
  selector: "app-balance",
  templateUrl: "./balance.component.html",
  styleUrls: [],
})
export class BalancesComponent implements OnInit {
  private signalWireSvc = inject(SignalWireService);
  private balanceSvc = inject(BalanceService);
  public router = inject(Router);
  balance: number = 0;
  currency: string = "EUR";
  private subscription: Subscription = new Subscription();

  ngOnInit() {
    this.calculateBalance();
    try{
      this.subscription = this.signalWireSvc.balance$.subscribe(newBalance => {
        this.balance = newBalance;
      });
    }
    catch(error)
    {
      console.log("error actualizando saldo: " + error)
    }
    
  }

  async calculateBalance() {
    this.balance = await this.balanceSvc.calculateBalance();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
