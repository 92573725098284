<div class="container my-4">
  <div class="bg-danger text-white rounded-lg p-4">
    <img alt="banner" class="rounded-lg mb-4" height="100"
      src="./assets/icons/EsimHeader.jpg" width="100%" />
    <p class="text-xl font-bold text-black">{{ 'ESIM.TOPUP_TITLE_1' | translate }}</p>
    <p class="mt-2 text-black">{{ 'ESIM.TOPUP_TITLE_2' | translate }}</p>
  </div>

  <div *ngIf="topups.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
    <mat-card *ngFor="let topup of topups" class="esim-card" appearance="outlined">
      <mat-card-header class="esim-card-header p-4">
        <div class="operator-details flex items-center">
          <img src="https://cdn-dev.zendy.tel/images/Esim/EsimCard@2x.png" alt="{{ topup.operator }}" class="operator-image mr-4" />
          <div>
            <mat-card-title class="font-bold text-lg">{{ topup.data }} - {{ topup.day }} {{ topup.day > 1 ? ('ESIM.DAYS' | translate) : ('ESIM.DAY' | translate) }}</mat-card-title>
            <div class="price text-red-600 text-md font-bold">€ {{ topup.price }}</div>
          </div>
        </div>
      </mat-card-header>

      <mat-card-content class="p-4">
        <div class="item-detail">
          <mat-icon>public</mat-icon>
          <span>{{ 'ESIM.COVERAGE' | translate }}: {{ topup.country }}</span>
        </div>
        <div class="item-detail">
          <mat-icon>signal_cellular_alt</mat-icon>
          <span>{{ 'ESIM.DATA' | translate }}: {{ topup.data }}</span>
        </div>
        <div class="item-detail">
          <mat-icon>date_range</mat-icon>
          <span>{{ 'ESIM.VALIDITY' | translate }}: {{ topup.day }} {{ topup.day > 1 ? ('ESIM.DAYS' | translate) : ('ESIM.DAY' | translate) }}</span>
        </div>
      </mat-card-content>

      <mat-card-actions class="justify-center p-4">
        <button mat-button class="buy-button" (click)="submitTopUp(topup.id)">{{ 'ESIM.RECHARGE_NOW' | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div *ngIf="topups.length === 0">
    <p>{{ 'ESIM.NO_TOPUPS_AVAILABLE' | translate }}</p>
  </div>
</div>
