export const environment = {
    production: true,
    config: {
        domain: "dev.zendy.tel",
        environment: "develop-zendy",
        pageSize: 100,
        paginationSize: 5,
        paypal: {
            clientId: 'ASUCA_Fx8qP4SC_UENGDf-G5_eQ26RnWVoJC-TBklYekGvrRqPaiQdyCk9qFbnHVstmHXNQf5Y0Kg7do'
        },
        bbva: {
            notification_url_api: 'https://api.dev.zendy.tel/tpv/notificate',
            tpv_redirect_url: 'https://sis-t.redsys.es:25443/sis/realizarPago',
            currency_code: "978",
            merchant_code: "361362585",
            notification_url_ok: "https://dev.zendy.tel/recharges/checkout/ok",
            notification_url_ko: "https://dev.zendy.tel/recharges/checkout/ko",
            transaction_type: "0",
            merchant_terminal_id: "001",
            description: "Recarga dev.zendy.tel"
        },
        termsAndConditionsVersion: "1",
        transfersUrl: 'https://transfers-dev.zendy.tel',
        transfersAccountURL: 'https://transfers-dev.zendy.tel/account'
    }
};