import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  APIService,
  GetContactQuery,
  ListCallsQuery,
  ListTransactionsQuery,
  ModelCallFilterInput,
} from 'src/app/API.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
})
export class ContactDetailsComponent implements OnInit {
  route = inject(ActivatedRoute);
  api = inject(APIService);
  contactId = this.route.snapshot.params['id'];
  contact!: GetContactQuery;
  calls!: ListCallsQuery;
  transfers!: ListTransactionsQuery;

  async ngOnInit() {
    if (this.contactId) {
      this.contact = await this.api.GetContact(this.contactId);
      //TODO: FIX ME: Tenemos que llamar a endpoint para obtener solo los datos del current contact
      const modelCallFilterInput: ModelCallFilterInput = {
        destinationNumber: { eq: this.contact.telephone },
      };
      this.calls = await this.api.ListCalls(modelCallFilterInput, 5);
      this.transfers = await this.api.ListTransactions({}, 5);
    }
  }
}
