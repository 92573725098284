<h2 mat-dialog-title>{{ 'ESIM.TOPUPS_AVAILABLE' | translate }}</h2>
<mat-dialog-content>
  <div *ngIf="isLoading" class="spinner-container">
    <mat-spinner></mat-spinner> 
    <p>{{ 'ESIM.LOADING_TOPUPS' | translate }}</p>
  </div>
  <div >
    <div *ngIf="topups.length > 0; else noTopups">
      <div class="topup-container">
        <div *ngFor="let topup of topups" class="topup-card">
          <div class="topup-row">
            <div class="topup-data">
              <mat-icon>signal_cellular_alt</mat-icon>
              <span>{{ 'ESIM.DATA' | translate }}</span>
            </div>
            <button 
              class="buy-button"
              [disabled]="isSubmitting"
              (click)="submitTopUp(topup.id)">
              € {{ topup.price  }}
            </button>
          </div>
          <div class="topup-row">
            <div class="data-amount">{{ topup.data }}</div>
            <div class="topup-validity">
              {{ 'ESIM.VALID_FOR' | translate }} {{ topup.day }} {{ topup.day > 1 ? ('ESIM.DAYS' | translate) : ('ESIM.DAY' | translate) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noTopups>
      <p>{{ 'ESIM.NO_TOPUPS_AVAILABLE' | translate }}</p>
    </ng-template>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'ESIM.CLOSE' | translate }}</button>
</mat-dialog-actions>
