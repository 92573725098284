<div class="p-4">
  <h3 class="text-center">
    {{ data.titleKey | translate }}
  </h3>
  <p class="text-center mt-6">{{ data.descKey | translate }}</p>
  <hr class="my-6" />
  <div class="flex justify-between gap-4">
    <app-button
      *ngIf="data.cancelTextKey"
      expand="full"
      color="secondary"
      (click)="close()"
      >{{ data.cancelTextKey | translate }}</app-button
    >
    <app-button *ngIf="data.acceptTextKey" expand="full" (click)="confirm()">{{
      data.acceptTextKey | translate
    }}</app-button>
  </div>
</div>
