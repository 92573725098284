import { Component } from '@angular/core';

@Component({
  selector: 'app-calls',
  template: '',
  styleUrls: []
})
export class CallsComponent {


}
