import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TUI_IS_IOS, TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  template: `
    <mat-toolbar class="w-full bg-color">
      <div class="w-1/3">
        <button
          type="button"
          mat-icon-button
          *ngIf="!showGoBackButton"
          (click)="onOpenMenu.emit(true)"
        >
          <img src="./assets/icons/menu.svg" />
        </button>
        <button mat-icon-button *ngIf="showGoBackButton" (click)="goBack()">
          <img src="./assets/icons/back.svg" />
        </button>
      </div>
      <div class="w-1/3 flex justify-center">
        <img src="./assets/icons/zendy.svg" />
      </div>
      <div class="w-1/3 flex justify-end items-center">
        <app-languages *ngIf="!showGoBackButton"></app-languages>
        <button mat-icon-button routerLink="recharges">
          <img *ngIf="!showGoBackButton" src="./assets/icons/wallet.svg" />
        </button>
      </div>
    </mat-toolbar>
    <app-tabs *ngIf="!isMobile || isIos"></app-tabs>
  `,
  styles: [
    `
      .bg-color {
        background: linear-gradient(180deg, #cf0e10 0%, #97000d 100%);
      }
    `,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() onOpenMenu = new EventEmitter<boolean>();

  goBackRoutes = ['new-contact', 'edit-contact', 'profile/edit'];
  showGoBackButton = false;
  private subscriptions = new Subscription();
  send_money: string = `send_money_button`;
  constructor(
    private authService: AuthService,
    public router: Router,
    private location: Location,
    @Inject(TUI_IS_MOBILE) readonly isMobile: boolean,
    @Inject(TUI_IS_IOS) readonly isIos: boolean
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.showGoBackButton = this.isCurrentRouteInGoBackRoutes();
        }
      })
    );
  }

  isCurrentRouteInGoBackRoutes(): boolean {
    const currentUrl = this.router.url;
    return this.goBackRoutes.some((route) => currentUrl.includes(route));
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  signOut() {
    this.authService.logout();
  }
}
