<div class="flex flex-row w-full justify-between md:justify-center md:gap-14 mt-4">
    <div class="flex flex-col items-center gap-2">
        <a routerLink="new-contact">
            <img src="./assets/icons/new-contact.svg" />
        </a>
        <div class="text-gray text-center">{{'CONTACTS.NEW_CONTACT' | translate}}</div>
    </div>
    <div class="flex flex-col items-center gap-2">
        <a routerLink="/calls">
            <img src="./assets/icons/new-call.svg" />
        </a>
        <div class="text-gray text-center">{{'CONTACTS.NEW_CALL' | translate}}</div>
    </div>
    <div class="flex flex-col items-center gap-2">
        <a target="_blank" href="{{transfersUrl}}">
            <img src="./assets/icons/new-transfer.svg" />
        </a>
        <div class="text-gray text-center">{{'CONTACTS.NEW_TRANSFER' | translate}}</div>
    </div>
</div>
<h2>{{'CONTACTS.TITLE' | translate}}</h2>
<app-contacts-list (onClicked)="onClick($event)"></app-contacts-list>