<h2 mat-dialog-title>eSIM Details</h2>
<mat-dialog-content>
    <div *ngIf="data">

        <!-- Details -->
        <mat-card class="details-card">
            <mat-card-content>
                <p><strong>ICCID:</strong> {{data.details.iccid}}</p>
                <p><strong>Operator:</strong> {{data.operator}}</p>
                <p><strong>Country:</strong> {{data.country}}</p>
            </mat-card-content>
        </mat-card>

        <!-- Data Usage -->
        <mat-card class="data-usage-card" *ngIf="data.usage_data">
            <mat-card-header>
                <mat-card-title>Usage Data</mat-card-title>
            </mat-card-header>
            <mat-card-content class="card-content">
                <div class="card-column-left">
                    <!-- Progreso de uso de datos -->
                    <div class="data-usage-spinner">
                        <mat-progress-spinner [mode]="'determinate'"
                            [value]="calculateUsagePercentage(data.usage_data.total, data.usage_data.remaining)"
                            diameter="100" strokeWidth="10" color="accent" class="custom-progress-spinner">
                        </mat-progress-spinner>
                    </div>
                    <div class="data-usage-info">
                        <p><strong>Used Data:</strong> {{ ((data.usage_data.total - data.usage_data.remaining) /
                            1024).toFixed(2) }} MB</p>
                        <p><strong>Total Data:</strong> {{ (data.usage_data.total / 1024).toFixed(2) }} GB</p>
                    </div>
                </div>

                <div class="card-column-right">
                    <p><strong>Used Voice:</strong> {{data.usage_data.total_voice}}</p>
                    <p><strong>Remaining Voice:</strong> {{data.usage_data.remaining_voice}}</p>
                    <p><strong>Used Text:</strong> {{data.usage_data.total_text}}</p>
                    <p><strong>Remaining Text:</strong> {{data.usage_data.remaining_text}}</p>
                </div>
            </mat-card-content>
        </mat-card>

        <!-- Installation Guide -->
        <mat-expansion-panel class="installation-guide-panel" (opened)="fetchInstallationGuide(data.details.iccid)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Installation Guide
                </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- Mostrar spinner mientras carga -->
            <div *ngIf="isLoading" class="loading-container">
                <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
            </div>

            <!-- Mostrar los datos una vez cargados -->
            <ng-container *ngIf="!isLoading && installationGuide">
                <!-- Panel para el idioma -->
                <mat-expansion-panel class="installation-guide-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ installationGuide.language }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <!-- Subpanel para iOS -->
                    <mat-expansion-panel class="installation-guide-panel" *ngIf="installationGuide.ios?.length">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                iOS Installation
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngFor="let ios of installationGuide.ios">
                            <h4>QR Code Installation</h4>
                            <ul>
                                <li *ngFor="let step of ios.installation_via_qr_code.steps | keyvalue">
                                    Step {{ step.key }}: {{ step.value }}
                                </li>
                            </ul>
                            <h4>Manual Installation</h4>
                            <ul>
                                <li *ngFor="let step of ios.installation_manual.steps | keyvalue">
                                    Step {{ step.key }}: {{ step.value }}
                                </li>
                            </ul>
                            <h4>Network Setup</h4>
                            <ul>
                                <li *ngFor="let step of ios.network_setup.steps | keyvalue">
                                    Step {{ step.key }}: {{ step.value }}
                                </li>
                            </ul>
                        </div>
                    </mat-expansion-panel>

                    <!-- Subpanel para Android -->
                    <mat-expansion-panel class="installation-guide-panel" *ngIf="installationGuide.android?.length">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Android Installation
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngFor="let android of installationGuide.android">
                            <h4>QR Code Installation</h4>
                            <ul>
                                <li *ngFor="let step of android.installation_via_qr_code.steps | keyvalue">
                                    Step {{ step.key }}: {{ step.value }}
                                </li>
                            </ul>
                            <h4>Manual Installation</h4>
                            <ul>
                                <li *ngFor="let step of android.installation_manual.steps | keyvalue">
                                    Step {{ step.key }}: {{ step.value }}
                                </li>
                            </ul>
                            <h4>Network Setup</h4>
                            <ul>
                                <li *ngFor="let step of android.network_setup.steps | keyvalue">
                                    Step {{ step.key }}: {{ step.value }}
                                </li>
                            </ul>
                        </div>
                    </mat-expansion-panel>
                </mat-expansion-panel>
            </ng-container>
        </mat-expansion-panel>


        <!-- QR Code -->
        <mat-expansion-panel class="qr-code-panel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Show QR Code
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="qr-code-image" *ngIf="qrCodeUrl">
                <h4>QR Code</h4>
                <img [src]="qrCodeUrl" alt="QR Code" />
            </div>
        </mat-expansion-panel>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onClose()">Close</button>
</mat-dialog-actions>