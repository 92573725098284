import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TabsComponent } from "./tabs.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule
    ],
    declarations: [
        TabsComponent,
    ],
    exports: [
        TabsComponent,
    ]
})
export class TabsModule { }