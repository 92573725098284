import { LOCALE_ID } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

platformBrowserDynamic().bootstrapModule(AppModule, 
  {
    providers: [{provide: LOCALE_ID, useValue: 'en-US' }]
  }
  )
  .catch(err => console.error(err));

import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);
