<app-onboarding-header></app-onboarding-header>
<form class="md:max-w-xl md:m-auto" [formGroup]="form">
  <div class="md:max-w-xl md:m-auto flex flex-col items-center mt-6 p-4">
    <img
      class="flex w-24 h-24 rounded-full"
      src="./assets/icons/email-validation.svg"
      alt="Profile image"
    />
    <h2 class="text-center">{{'ONBOARDING.PASS.TITLE' | translate}}</h2>
    <p class="text-base text-center">
      {{'ONBOARDING.PASS.DESC' | translate}}
    </p>
    <mat-form-field class="calls-full-width mt-8 mb-4">
      <mat-label>{{'ONBOARDING.PASS.CODE' | translate}}</mat-label>
      <input type="text" matInput formControlName="code" />
    </mat-form-field>
    <mat-form-field class="calls-full-width mb-4">
      <mat-label>{{'ONBOARDING.PASS.PASS1' | translate}}</mat-label>
      <input type="password" matInput formControlName="newPassword" />
    </mat-form-field>
    <mat-form-field class="calls-full-width mb-8">
      <mat-label>{{'ONBOARDING.PASS.PASS2' | translate}}</mat-label>
      <input type="password" matInput formControlName="newPassword2" />
    </mat-form-field>
    <app-button (click)="forgotPasswordSubmit()">
      {{'ONBOARDING.PASS.BUTTON' | translate}}
    </app-button>
  </div>
</form>
