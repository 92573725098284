<form [formGroup]="createForm">
  <mat-form-field class="calls-full-width">
    <mat-label>{{ 'CONTACTS.NEW.NAME' | translate }}</mat-label>
    <input
      formControlName="name"
      autofocus
      type="text"
      matInput
      placeholder="John Doe"
    />
  </mat-form-field>
  
  
  <!-- <mat-label>{{ 'CONTACTS.NEW.TELEPHONE' | translate }}</mat-label> -->
  <div class="flex bg-gray-opacity rounded-xl mb-4">
    <zendy-intl-phone
      class="w-full rounded-none"
      [tuiTextfieldLabelOutside]="true"
      formControlName="telephone"
      [countries]="(countries | tuiSortCountries | async) || []"
      [(countryIsoCode)]="countryIsoCode"
    >
    </zendy-intl-phone>
  </div>
  
  <mat-form-field class="calls-full-width">
    <mat-label>{{ 'CONTACTS.NEW.EMAIL' | translate }}</mat-label>
    <input
      autofocus
      type="email"
      formControlName="email"
      matInput
      placeholder="example@example.com"
    />
  </mat-form-field>
  
  <div class="flex justify-between gap-4 mt-12">
    <app-button expand="full" color="secondary" (click)="goBack()">{{
      'COMMON.CANCEL' | translate
    }}</app-button>
    <app-button *ngIf="!contactId" expand="full" (click)="onCreate()" [disabled]="createForm.invalid">{{
      'CONTACTS.NEW.ADD_CONTACT' | translate
    }}</app-button>
    <app-button *ngIf="contactId" expand="full" (click)="onUpdate()" [disabled]="createForm.invalid">{{
      'COMMON.CONFIRM' | translate
    }}</app-button>
  </div>
</form>
