import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() expand: 'block' | 'full' = 'block';
  @Input() hasIcon: 'hasIcon' | null = null;
  @Input() size: 'large' | 'medium' | 'small' = 'medium';
  @Input() disabled = false;
  @Input() customClass = '';
}
