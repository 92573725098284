
export type DetailFields = {
    name: string;
    path: string;
}
export type Item = {
    id: string;
    icon: string | "folder";
    title: string | "Dummy";
    caption: string | "Dummy";
    caption_format: string | "string";
    args: Array<string> | ["hello"];
    currency?: string;
    amount?: string;
    total_time?: string;
    call_date?: string;
    destination?: string;
}

export type OptionItem = {
    id: string;
    icon: string | "folder";
    title: string | "Dummy";
    caption: string | "Dummy";
    caption_format: string | "string";
    value: string | "dummy";
    args: Array<string> | ["hello"];
}

export type Action = {
    icon: string | "folder";
    name: string | "folder";
    route: string | "/dummy";
    arg_index: number | 0;
    func: any | (() => {});
    func_extra_arg: string | ""
    child_func: string
}
export interface List {
    items: Array<Item>;
    itemSelected: string;
    itemActions: Array<Action>;
    pageSize: number;
}

export interface OptionList {
    items: Array<OptionItem>;
    header: string | null | undefined;
    itemSelected: string;
    itemActions: Array<Action>;
    nextToken: any;
}

export interface KeyValueItem {
    field: string,
    value: string
}

export abstract class ItemAbstract implements List {

    // public showList: boolean = true;
    public showDetail: boolean = false;
    public items: Array<Item> = [];
    public groupedItems: { [initial: string]: Item[] } = {};
    public item: KeyValueItem[] = [];
    // public initialItemsVisible: Array<Item> = [];
    public header: string = "";
    public itemSelected: string = "-1";
    public itemActions: Array<Action> = [];
    public actions: Array<Action> = [];
    public detailFields: Array<DetailFields> = [];
    public nextToken: any = null
    public pageSize: number = 10

    abstract castToItem(items: any[]): void;
    setItemSelected(id: string) {
        this.itemSelected = id;
    }
    abstract go(route: string, arg_index: number): void
    getItemSelected(): any {
        return this.items.filter(i => i.id == this.itemSelected)[0] as Item
    }

    getItemToBeSeen(): string {
        return this.itemSelected
    }
    getItemToBeEdited(): string {
        return this.itemSelected
    }

}


export abstract class OptionItemAbstract extends ItemAbstract {

    public override items: Array<OptionItem> = [];
    public noOptionsFlag: boolean = false;

    override getItemSelected() {
        return this.items.filter(i => i.id == this.itemSelected)[0] as OptionItem
    }

}
