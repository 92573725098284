import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  APIService,
  Contact,
  DeleteContactInput,
  ModelContactConditionInput,
  ModelContactFilterInput,
  ModelSortDirection,
  ModelStringInput,
  ModelStringKeyConditionInput,
} from '../../API.service';
import { AuthService } from '../../services/auth.service';
import { Action, Item, ItemAbstract } from '../../utils/Item';
import {
  ConfirmationComponent,
  ModalData,
} from '../../components/confirmation/confirmation.component';
import { ConfigService } from '../../config.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: [],
})
export class ContactsListComponent extends ItemAbstract implements OnInit {
  @Output() onClicked = new EventEmitter();
  public createForm: FormGroup;
  public editActiveinList: boolean = true;
  public deleteActiveinList: boolean = true;

  constructor(
    private api: APIService,
    private authService: AuthService,
    private fb: FormBuilder,
    public router: Router,
    public configService: ConfigService,
    private dialog: MatDialog
  ) {
    super();
    this.configService = configService;
    this.createForm = this.fb.group({
      id: ['-1', Validators.required],
      name: ['', Validators.required],
      telephone: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
          ),
        ],
      ],
      email: ['', Validators.email],
    });
    this.itemActions = [
      {
        name: `COMPONENTS.LIST.CALL`,
        icon: 'dialpad',
        route: '/calls/dial/',
        arg_index: 1, //Must be in the Cast!
      },
    ] as Action[];
    this.actions = [
      {
        name: `add`,
        child_func: 'hideList',
      },
    ] as Action[];
  }

  async ngOnInit() {
    this.fetchItems();
  }

  fetchItems() {
    this.api
      .ContactsByOwnerAndName(
        this.authService.getUser().username ?? '',
        {} as ModelStringKeyConditionInput,
        'ASC' as ModelSortDirection,
        {} as ModelContactFilterInput,
        this.configService.config.pageSize,
        this.nextToken
      )
      .then((event) => {
        this.nextToken = event.nextToken;
        this.groupedItems = this.groupByInitialLetter(
          this.castToItem(event.items as Contact[]) as Item[]
        );
      });
  }

  castToItem(items: any[]): Item[] {
    return items.map((i) => {
      return {
        id: i.id,
        icon: 'person',
        title: i.name,
        caption: i.telephone,
        caption_format: 'telephone',
        args: [i.id, `${i.telephone}/${i.name}`],
      };
    });
  }

  go(route: string, arg_index: number = 0) {
    for (const key in this.groupedItems) {
      if (this.groupedItems.hasOwnProperty(key)) {
        const elements = this.groupedItems[key];
        const foundedElement = elements.find((element: Item) => {
          return (
            element.args.length > 0 && element.args[0] === this.itemSelected
          );
        });
        if (foundedElement) {
          console.log(foundedElement);
          this.router.navigate([`${route}${foundedElement.args[arg_index]}`]);
          break;
        }
      }
    }
  }

  onDelete(id: string) {
    const data: ModalData = {
      titleKey: 'COMMON.SURE',
      descKey: 'COMMON.SURE_DESC',
      acceptTextKey: 'COMMON.YES',
      cancelTextKey: 'COMMON.NO',
    };
    this.dialog
      .open(ConfirmationComponent, {
        panelClass: 'zendy-modal',
        data,
      })
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          this.delete(id);
        }
      });
  }

  public delete(id: string) {
    const deleteInput: DeleteContactInput = { id: id } as DeleteContactInput;
    const conditionInput: ModelContactConditionInput = {
      owner: {
        eq: this.authService.getUser().username ?? '',
      } as ModelStringInput,
    } as ModelContactConditionInput;
    this.api
      .DeleteContact(deleteInput, conditionInput)
      .then(() => {
        this.fetchItems();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public onEdit(id: string) {
    this.router.navigate(['/contacts/edit-contact/' + id]);
  }

  groupByInitialLetter(contacts: any[]): { [initial: string]: Item[] } {
    return contacts.reduce((acc, contact) => {
      const initial = contact.title.charAt(0).toUpperCase();
      if (!acc[initial]) {
        acc[initial] = [];
      }
      acc[initial].push(contact);
      return acc;
    }, {});
  }

  onClick(event: any) {
    this.onClicked.emit(event);
  }
}
