import { Component, Inject } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styles: [
    `
      p {
        font-size: 14px;
      }
      h2 {
        margin-top: 0px;
      }
    `,
  ],
})


export class ConditionsComponent {
  constructor(private router: Router) {}

  openConditions() {
    this.router.navigate(['conditions']);
  }
  }
