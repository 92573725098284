import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  public username = '';
  constructor() { }

  setUsername(username: string) {
    this.username = username;
  }
}
