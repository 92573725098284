import { Component, OnInit, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'app-email-code-validation',
  templateUrl: './email-code-validation.component.html',
})
export class EmailCodeValidationComponent implements OnInit {
  router = inject(Router);
  onboardingService = inject(OnboardingService);
  authService = inject(AuthService);
  _snackBar = inject(MatSnackBar);
  validationCode!: string;
  errorCode = false;

  ngOnInit(): void {
    if (!this.onboardingService.username) {
      this.router.navigate(['/onboarding/login'], { replaceUrl: true });
    }
  }

  async validate() {
    if (this.validationCode) {
      this.errorCode = false;
      try {
        await this.authService.confirmSignUp(
          this.onboardingService.username,
          this.validationCode
        );
        this.router.navigate(['/onboarding/login'], { replaceUrl: true });
      } catch (e: any) {
        if (e.code === 'CodeMismatchException') {
          this.errorCode = true;
        }
      }
    }
  }

  async resendCode() {
    await this.authService.resendSignUp(this.onboardingService.username);
    this._snackBar.open('The code was sended to your email', 'Close', {
      duration: 5000,
    });
  }
}
