import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { TUI_COUNTRIES } from '@taiga-ui/kit';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-countries-modal',
  templateUrl: './countries-modal.component.html',
  })
export class CountriesModalComponent {

  val = ''
  
  readonly countries = Object.values(TuiCountryIsoCode);
  constructor(
    public dialog: MatDialogRef<CountriesModalComponent>,
    @Inject(TUI_COUNTRIES)
    readonly countriesNames$: Observable<Record<TuiCountryIsoCode, string>>,
    
  ) {}

  close(): void {
    this.dialog.close(false);
  }
  confirm(value: TuiCountryIsoCode): void {
    this.dialog.close(value);
  }  
}
