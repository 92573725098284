<h2 mat-dialog-title class="font-bold">{{ data.package.title }}</h2>

<mat-dialog-content  >
    <div class="overlay" *ngIf="isLoading">
        <div class="loader-popup">
          <!-- Contenido del loader, como un spinner o mensaje de carga -->
          <div class="loader"></div>
          <p>{{ 'ESIM.LOADING_TOPUPS' | translate }}</p>
        </div>
      </div>
    <div class="header-content">
        <div class="header-image">
            <img mat-card-image [src]="data.package.operador[0].image" alt="{{ data.package.operador[0].operador }}"
                class="operator-image">
        </div>
        <div class="header-text">
            <div class="item-detail">
                <mat-icon>public</mat-icon>
                <label class="item-label">{{ 'ESIM.COVERAGE' | translate }}:</label>
                <span class="item-value">{{ data.package.country }}</span>
            </div>
            <hr class="item-separator">
            <div class="item-detail">
                <mat-icon>signal_cellular_alt</mat-icon>
                <label class="item-label">{{ 'ESIM.DATA' | translate }}:</label>
                <span class="item-value">{{ data.package.data }}</span>
            </div>
            <hr class="item-separator">

            <div class="item-detail">
                <mat-icon>date_range</mat-icon>
                <label class="item-label">{{ 'ESIM.VALIDITY' | translate }}:</label>
                <span class="item-value">{{ data.package.day }} {{ data.package.day > 1 ? ('ESIM.DAYS' | translate) :
                    ('ESIM.DAY' | translate) }}</span>
            </div>

            <div class="item-detail">
                <mat-icon>sell</mat-icon>
                <label class="item-label">{{ 'ESIM.PRICE' | translate }}:</label>
                <span class="item-value"> € {{ data.package.price   }}</span>
            </div>
        </div>
    </div>
    <mat-divider class="mb-4"></mat-divider>
    <div class="operator-details mt-4">
        <!-- <div class="item-detail">
            <mat-icon>account_balance_wallet</mat-icon>
            <label class="item-label">Prepaid:</label>
            <span class="item-value">{{ data.package.operador[0].prepaid }}</span>
        </div>
        <hr class="item-separator"> -->
        <div class="item-detail">
            <mat-icon>info</mat-icon>
            <label class="item-label">Type:</label>
            <span class="item-value">{{ data.package.operador[0].type }}</span>
        </div>
        <hr class="item-separator">
        <div class="item-detail">
            <mat-icon>sim_card</mat-icon>
            <label class="item-label">eSIM Type:</label>
            <span class="item-value">{{ data.package.operador[0].esimtype }}</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="footer-content">
        <span class="price">{{ 'ESIM.PRICE' | translate }} € {{ data.package.price  }}</span>
        <button mat-button (click)="onBuyNow()">Comprar</button>
    </div>
</mat-dialog-actions>
<style>

</style>