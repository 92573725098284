<div mat-dialog-content *ngIf="countriesNames$ | async as countriesNames">
  <div class="flex flex-col items-center" style="font-family: 'Montserrat', sans-serif !important;">
    <img class="mb-4" width="64px" height="40px" [src]="data.country | tuiFlag" />
    <h3>{{'RATES_MODAL.TITLE' | translate}}</h3>
    <h1 class="text-gray text-center">
      {{data.prefix}} {{ countriesNames[data.country] }}
    </h1>
    <h3 class="mt-6 text-center">{{'RATES_MODAL.DESC' | translate}}</h3>
  </div>
  <hr />
  <div id="pricelist" class="mt-2 justify-center"  >
    <ng-container *ngFor="let item of data.pricing; let i = index" >
      <div class="flex gap-2 px-4 py-2 container" >
        <div class="pull-right">{{ item.network }}</div>
        <div class="pull-left col-md-2">{{ item.amountPerMinuteEuro }}</div>
      </div>
      <hr />
    </ng-container>
  </div>
  <div class="flex justify-center mt-8">
    <app-button (click)="confirm()"> {{'RATES_MODAL.BUTTON' | translate}}</app-button>
  </div>
</div>


<style>
#pricelist {
  max-height: 250px;
  overflow-y: auto;
}

  .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.container {
  display: flex;
  justify-content: space-between;
  
}
.pull-left {
  margin-left: auto;
}
.pull-right {
  margin-right: auto;
}
.col-md-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333333%;
  flex: 0 0 auto;
  max-width: 8.33333333%;
}
.col-md-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66666667%;
  flex: 0 0 auto;
  max-width: 16.66666667%;
}

</style>