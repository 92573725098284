import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { CheckoutNotificationComponent } from './features/checkout-notification/checkout-notification.component';
import { EditMyProfileComponent } from './features/my-profile/edit-profile/edit-profile.component';
import { MyProfileComponent } from './features/my-profile/my-profile.component';
import { ContactDetailsComponent } from './features/contacts/contact-details/contact-details.component';
import { ContactsComponent } from './features/contacts/contacts.component';
import { NewContactComponent } from './features/contacts/new-contact/new-contact.component';
import { HistoryComponent } from './features/history/history.component';
import { HomeComponent } from './features/home/home.component';
import { RechargesComponent } from './features/recharges/recharges.component';
import { SendMoneyComponent } from './features/send-money/send-money.component';
import { TransfersComponent } from './features/transfers/transfers.component';
import { ConditionsComponent } from './features/about/conditions/conditions.component';
import { HelpComponent } from './features/about/help/help.component';
import { EsimComponent } from './features/esim/esim.component';
import { TopupComponent } from './features/esim/topup/topup.component';
import { DetailsComponent } from './features/esim/details/details.component';

const routes: Routes = [
  { path: 'topup/:iccid', component: TopupComponent },
  { path: 'esim-details', component: DetailsComponent },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'esim',
    component: EsimComponent,
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./features/onboarding/onboarding.module').then((m) => m.OnBoardingModule),
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'send-money', component: SendMoneyComponent },
      { path: 'contacts', component: ContactsComponent },
      { path: 'contacts/new-contact', component: NewContactComponent },
      { path: 'contacts/new-contact/:telephone', component: NewContactComponent },
      { path: 'contacts/edit-contact/:id', component: NewContactComponent },
      { path: 'contact/details/:id', component: ContactDetailsComponent },
      { path: 'recharges', component: RechargesComponent },
      {
        path: 'recharges/checkout/:status',
        component: CheckoutNotificationComponent,
      },
      { path: 'transfers', component: TransfersComponent },
      { path: 'history', component: HistoryComponent },
      {
        path: 'calls',
        loadChildren: () =>
          import('./features/calls/calls.module').then((m) => m.CallsModule),
      },
      { path: 'profile', component: MyProfileComponent },
      { path: 'profile/edit', component: EditMyProfileComponent },
      { path: 'about/conditions', component: ConditionsComponent },
      { path: 'about/help', component: HelpComponent },
     
    ],
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
