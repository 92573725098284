import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { OnboardingService } from '../onboarding.service';
import { MustMatch } from '../register/register.component';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
})
export class NewPasswordComponent implements OnInit {
  authService = inject(AuthService);
  onboardingService = inject(OnboardingService);
  router = inject(Router);
  _snackBar = inject(MatSnackBar);

  public form = new FormGroup(
    {
      code: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.email]),
      newPassword2: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
    },
    MustMatch('newPassword', 'newPassword2')
  );

  ngOnInit(): void {
    if (!this.onboardingService.username) {
      this.router.navigate(['/onboarding/login'], { replaceUrl: true });
    }
  }

  async forgotPasswordSubmit() {
    try {
      const formData = this.form.getRawValue();
      if (formData.code && formData.newPassword) {
        await this.authService.forgotPasswordSubmit(
          this.onboardingService.username,
          formData.code,
          formData.newPassword
        );
        this._snackBar.open('Password Changed', 'Close', {
          duration: 5000,
        });
        this.router.navigate(['onboarding/login'], { replaceUrl: true });
      }
    } catch (err) {
      console.log(err);
    }
  }
}
