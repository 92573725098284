import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BalancesComponent } from "./balance.component";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule
    ],
    declarations: [
        BalancesComponent,
    ],
    exports: [
        BalancesComponent,
    ]
})
export class BalanceModule { }