<div class="p-4">
<h2>AYUDA</h2>

<p>Si necesitas ayuda, o tienes algún comentario o sugerencia, no dudes en ponerte en contacto con nosotros:</p>
<br/>
<p>Nuestro equipo estará encantado de ayudarte!</p>
<br/>
<p>info@zendy.tel</p>
<br/>
<p>+34 629 55 41 18</p>
<br/>
<p>_______________________________________________</p>

<br/>
<br/>

<h2>HELP</h2>
  
<p>If you have any questions, comments, or inquiries, please don't hesitate to get in touch with us.</p>
<br/>
<p>Our team is here to assist you.</p>
<br/>
<p>info@zendy.tel</p>
<br/>
<p>+34 629 55 41 18</p>
</div>
