<div mat-dialog-content *ngIf="countriesNames$ | async as countriesNames">
  <div
    class="flex flex-col gap-4"
    style="font-family: 'Montserrat', sans-serif !important"
  >
    <tui-primitive-textfield
      #input
      tuiTextfieldIconLeft="tuiIconSearchLarge"
      [tuiTextfieldLabelOutside]="true"
      [(value)]="val"
      class="m-2"
    >
      <input tuiTextfield type="text" autocomplete="off" />
    </tui-primitive-textfield>
    <div
      *ngFor="let item of countries | filterCountries : countriesNames : val
      | tuiSortCountries
      | async"
      tuiOption
      (click)="confirm(item)"
    >
      <div class="flex gap-4 items-center py-2 px-4">
        <img class="h-5" [src]="item | tuiFlag" />
        <span class="">
          {{ countriesNames[item] }}
        </span>
      </div>
      <hr class="mt-2" />
    </div>
  </div>
</div>


