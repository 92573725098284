import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styles: [`h2, p{font-family: "Montserrat", system-ui, sans-serif !important;}`]
})
export class DeleteAccountComponent {
  constructor(public dialog: MatDialogRef<DeleteAccountComponent>) {}

  goBack(): void {
    this.dialog.close(false);
  }
  onDelete(): void {
    this.dialog.close(true);
  }

}
