import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  private isVisible = new BehaviorSubject(true);
  isVisible$ = this.isVisible.asObservable();
  constructor() { }

  setVisibility(visibility: boolean) {
    this.isVisible.next(visibility)
  }

}
