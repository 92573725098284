<div *ngIf="step === 1">
  <h2>{{ 'SEND_MONEY.TITLE1' | translate }}</h2>
  <app-contacts-list (onClicked)="onClick($event)"></app-contacts-list>
</div>
<div *ngIf="step === 2">
  <app-balance></app-balance>
  <div
    class="p-4 bg-gray-opacity rounded-2xl shadow mt-8 flex items-center justify-between"
  >
    <img
      width="64px"
      class="rounded-full"
      src="./assets/icons/profile-icon.svg"
      alt="Profile image"
    />
    <div>
      <div class="flex items-center flex-col gap-2">
        <h2 class="m-0 tracking-tight text-gray font-semibold">
          {{ selectedContact.title }}
        </h2>
        <div class="text-gray text-base">{{ selectedContact.caption }}</div>
      </div>
    </div>
  </div>
  <h2>{{ 'SEND_MONEY.TITLE2' | translate }}</h2>
  <div class="grid grid-cols-3 gap-4">
    <button
      *ngFor="let item of itemsList"
      class="bg-gradient-to-t to-light-red from-zendy-dark-red text-white rounded-lg px-4 py-2"
      (click)="sendMoney(item)"
    >
      <div class="flex flex-col">
        <h1>{{ item.value }}</h1>
        {{ currency }}
      </div>
    </button>
  </div>
  <div class="flex mt-12 gap-4">
    <mat-form-field class="calls-full-width">
      <mat-label>{{ 'SEND_MONEY.OTHER' | translate }}</mat-label>
      <input type="number" matInput [(ngModel)]="customAmount" />
    </mat-form-field>
    <app-button
      [disabled]="!customAmount"
      expand="full"
      (click)="sendMoney()"
      hasIcon="hasIcon"
    >
      <img width="24px" height="24px" src="./assets/icons/transfer-white.svg" />
      {{ 'SEND_MONEY.BUTTON' | translate }}</app-button
    >
  </div>
</div>
