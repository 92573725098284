<div class="w-full bg-gray-opacity" [ngClass]="{'fixed bottom-0 left-0 z-50': isMobile && !isIos}" *ngIf="isVisible$ | async">
    <div class="grid h-full max-w-lg md:max-w-3xl grid-cols-5 mx-auto font-medium">
        <button type="button" routerLink="home"
            class="inline-flex flex-col items-center justify-center px-5 py-3 md:py-4 hover:bg-gray-50 group gap-2">
            <img width="24" height="24"
                [src]="router.url.includes('home') ? './assets/icons/home-active.svg' : './assets/icons/home.svg'" />
            <span [ngClass]="router.url.includes('home') ? 'text-light-red' : 'text-gray'" class="text-sm">{{'COMPONENTS.TABS.TAB1' | translate}}</span>
        </button>
        <button type="button" routerLink="contacts"
            class="inline-flex flex-col items-center justify-center px-5 py-3 md:py-4 hover:bg-gray-50 group gap-2">
            <img width="24" height="24"
                [src]="router.url.includes('contacts') ? './assets/icons/agenda-active.svg' : './assets/icons/agenda.svg'" />
            <span [ngClass]="router.url.includes('contacts') ? 'text-light-red' : 'text-gray'"
                class="text-sm mt">{{'COMPONENTS.TABS.TAB2' | translate}}</span>
        </button>
        <button type="button" routerLink="calls"
            class="inline-flex flex-col items-center justify-center px-5 py-3 md:py-4 hover:bg-gray-50 group gap-2">
            <img width="24" height="24"
                [src]="router.url.includes('calls') ? './assets/icons/phone-active.svg' : './assets/icons/phone.svg'" />
            <span [ngClass]="router.url.includes('calls') ? 'text-light-red' : 'text-gray'" class="text-sm">{{'COMPONENTS.TABS.TAB3' | translate}}</span>
        </button>
        <button type="button" (click)="window.open(transfersUrl, '_blank')"
            class="inline-flex flex-col items-center justify-center px-5 py-3 md:py-4 hover:bg-gray-50 group gap-2">
            <img width="24" height="24"
                [src]="router.url.includes('transfers') ? './assets/icons/send-money-active.svg' : './assets/icons/send-money.svg'" />
            <span [ngClass]="router.url.includes('transfers') ? 'text-light-red' : 'text-gray'"
                class="text-sm">{{'COMPONENTS.TABS.TAB4' | translate}}</span>
        </button>
        <button type="button" routerLink="esim"
            class="inline-flex flex-col items-center justify-center px-5 py-3 md:py-4 hover:bg-gray-50 group gap-2">
            <img width="24" height="24"
                [src]="router.url.includes('esims') ? './assets/icons/sim_card.svg' : './assets/icons/sim_card.svg'" />
            <span [ngClass]="router.url.includes('esims') ? 'text-light-red' : 'text-gray'" class="text-sm">{{'COMPONENTS.TABS.TAB5' | translate}}</span>
        </button> 
    </div>
</div>