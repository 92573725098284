import { Component } from '@angular/core';

@Component({
  selector: 'app-onboarding-header',
  template: `
    <div
      class="pt-24 md:pt-4 bg-gradient-to-t to-light-red from-zendy-dark-red flex items-center"
    >
      <div class="w-1/3"></div>
      <div class="w-1/3 flex justify-center">
        <img class="mb-2 w-40 md:w-28" src="./assets/icons/zendy.svg" />
      </div>
      <div class="w-1/3 flex justify-end items-center mr-4">
        <app-languages></app-languages>
      </div>
    </div>
  `,
})
export class OnBoardingHeaderComponent {}
