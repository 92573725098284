import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styles: [
    `
      p {
        font-size: 14px;
      }
      h2 {
        margin-top: 0px;
      }
    `,
  ],
})

export class HelpComponent {
    constructor(private router: Router) {}
  
    openConditions() {
      this.router.navigate(['helps']);
  }
}
