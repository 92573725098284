import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  auth = inject(AuthService);
  constructor(private router: Router) {}

  async canActivate() {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch {
      this.router.navigate(['/onboarding/login']);
      return false;
    }
  }
}
