import { Component, inject } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: [],
})
export class ContactsComponent {
  transfersUrl = `${inject(ConfigService).config.transfersUrl}?tracker=${inject(AuthService).getUser().id}`;
  router = inject(Router);
  onClick(event: any) {
    this.router.navigate(['contact/details/' + event.id], { replaceUrl: true });
  }
}
