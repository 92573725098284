import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import {
  APIService,
  Account,
  Contact,
  CreateTransactionInput,
  CreateTransactionMutation,
  Currency,
  ModelContactFilterInput,
  ModelSortDirection,
  ModelStringKeyConditionInput,
} from "../../API.service";
import { AWSLambda } from "../../AWSLambda.service";
import { ConfigService } from "../../config.service";
import { AuthService } from "../../services/auth.service";
import { Action, OptionItem, OptionItemAbstract } from "../../utils/Item";

@Component({
  selector: "app-send-money",
  templateUrl: "./send-money.component.html",
  styleUrls: [],
})
export class SendMoneyComponent extends OptionItemAbstract implements OnInit {
  step = 1;
  selectedContact: any;
  currency = "EUR";
  itemsList = this.configService.getItems();
  customAmount!: number;

  // sendMoney(event: string) {
  //   this.router.navigate([`payouts/${event}`]);
  // }

  public override header: string = `select_a_contact_from_the_list`;

  constructor(
    private configService: ConfigService,
    private api: APIService,
    private authService: AuthService,
    private router: Router,
    private lambda: AWSLambda,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  //Do Nothing
  go(route: string, arg_index: number): void {
    throw new Error("Method not implemented.");
  }

  async ngOnInit() {
    this.fetchItems();
    this.itemActions = [
      {
        name: `send_money`,
        func: this.sendMoney,
        child_func: "hideList",
      },
    ] as Action[];
  }

  fetchItems() {
    const emailFormat = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    this.api
      .ContactsByOwnerAndName(
        this.authService.getUser().username ?? '',
        {} as ModelStringKeyConditionInput,
        "ASC" as ModelSortDirection,
        {} as ModelContactFilterInput,
        this.configService.config.pageSize,
        this.nextToken
      )
      .then((event) => {
        this.nextToken = event.nextToken;
        //this.items = this.castToItem(event.items as Contact[]) as OptionItem[];
        return (event.items as Contact[]).filter(
          (item: Contact) => item.email !== undefined
        );
      })
      .then((items: Contact[]) => {
        return items.filter((item: Contact) => item.email !== null);
      })
      .then((items: Contact[]) => {
        return items.filter((item: Contact) => item.email?.match(emailFormat));
      })
      .then((items: Contact[]) => {
        this.items = this.castToItem(items) as OptionItem[];
      });
  }

  castToItem(items: any[]): OptionItem[] {
    return items.map((i: Contact) => {
      return {
        id: i.id,
        value: i.id,
        title: `${i.name} ${i.telephone} ${i.email}`,
        caption: i.name,
      };
    }) as Array<object> as OptionItem[];
  }

  onClick(event: any) {
    this.selectedContact = event;
    this.step++;
  }

  sendMoney(item?: OptionItem) {
    const info = {
      payout: {
        name: this.selectedContact.title,
        telephone: this.selectedContact.caption,
        email: "",
      },
      states: {
        changes: {
          started: Math.floor(Date.now() / 1000),
          processing: null,
          completed: null,
          failed: null,
        },
        actual: "started", // started | processing | completed | failed
      },
    };
    this.api
      .CreateTransaction({
        resource: "paypal",
        type: "payout",
        amount: this.customAmount ? this.customAmount : item?.value,
        source: Account.balance,
        destination: Account.payout,
        currency: this.currency == "EUR" ? Currency.EUR : Currency.USD,
        owner: this.authService.getUser().username ?? '',
        info: JSON.stringify(info),
      } as CreateTransactionInput)
      .then((data: CreateTransactionMutation) => {
        return this.lambda.getLambdaFunction("lambda", "/payout", {
          queryStringParameters: { id: data.id },
        });
      })
      .then(() => {
        this.snackBar.open(`Success!`, "", { duration: 5000 });
        this.router.navigate(["/transactions"]);
      });
  }
}
