import { Component, inject } from "@angular/core";
import { ConfigService } from "../../config.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-transfers",
  template: ` <div class="flex flex-row w-full justify-between mt-4 md:justify-center md:gap-14">
      <div class="flex flex-col items-center gap-2">
        <a target="_blank" href="{{transfersUrl}}">
          <img src="./assets/icons/new-transfer.svg" />
        </a>
        <div class="text-gray text-center">{{'TRANSACTIONS.NEW_TRANSFER' | translate}}</div>
      </div>
      <div class="flex flex-col items-center gap-2">
        <a routerLink="new-contact">
          <img src="./assets/icons/new-contact.svg" />
        </a>
        <div class="text-gray text-center">{{'TRANSACTIONS.NEW_CONTACT' | translate}}</div>
      </div>
      <div class="flex flex-col items-center gap-2">
        <a routerLink="/calls">
          <img src="./assets/icons/new-call.svg" />
        </a>
        <div class="text-gray text-center">{{'TRANSACTIONS.NEW_CALL' | translate}}</div>
      </div>
    </div>
    <h2>{{'TRANSACTIONS.LAST_TRANSFER' | translate}}</h2>
    <div class="flex justify-center mt-6">
      <app-button hasIcon="hasIcon" (click)="window.open(transfersAccountURL, '_blank')"
        ><img
          width="24px"
          height="24px"
          src="./assets/icons/button-transfer.svg"
        />{{'TRANSACTIONS.BUTTON_LIST_TRANSFER' | translate}}</app-button
      >
    </div>
    <div class="flex justify-center mt-6">
      <app-button hasIcon="hasIcon" (click)="window.open(transfersUrl, '_blank')"
        ><img
          width="24px"
          height="24px"
          src="./assets/icons/button-transfer.svg"
        />{{'TRANSACTIONS.BUTTON_TRANSFER' | translate}}</app-button
      >
    </div>`,
  styleUrls: [],
})
export class TransfersComponent {
  window = window;
  transfersUrl = `${inject(ConfigService).config.transfersUrl}?tracker=${inject(AuthService).getUser().id}`;
  transfersAccountURL = `${inject(ConfigService).config.transfersAccountURL}?tracker=${inject(AuthService).getUser().id}`;
}
