<mat-dialog-content>
  <div class="flex flex-col justify-center items-center">
    <div class="flex justify-center">
      <img src="./assets/icons/my-profile-delete.svg" />
    </div>
    <h2 class="text-center mt-6">
      {{ 'COMPONENTS.PROFILE.MY_PROFILE.DELETE_PROFILE.TITLE' | translate }}
    </h2>
    <p class="mt-4 text-base text-center">
      {{ 'COMPONENTS.PROFILE.MY_PROFILE.DELETE_PROFILE.TEXT' | translate }}
    </p>
    <p class="mt-2 text-base text-center">{{ 'COMPONENTS.PROFILE.MY_PROFILE.DELETE_PROFILE.TEXT2' | translate }}</p>
    <div class="flex justify-between gap-4 mt-6">
      <app-button expand="full" color="secondary" (click)="goBack()"
        >{{'COMMON.CANCEL' | translate}}</app-button
      >
      <app-button expand="full" (click)="onDelete()">{{'COMMON.DELETE' | translate}}</app-button>
    </div>
  </div>
</mat-dialog-content>
