<div class="p-4">
  <h2>Información Legal</h2>

  <p>“SKYLINK Global SL.” entidad mercantil española domiciliada en Madrid, Calle Gabriel García Márquez 4, 1a Planta, Las Rozas de Madrid, España. C.P. 28232, provista del CIF B-10927978, inscrita en el Registro Mercantil de Madrid al Tomo 43.853, folio 164,
    inscripción 1, Hoja M-773886 (en adelante, “SKYLINK ”). Zendy es la marca bajo la que opera SKYLINK, sociedad
    propietaria y administradora del dominio web www.zendy.tel (en adelante, la "Web" o el "Sitio Web") y de la
    aplicación móvil “ZENDY ” (en adelante, “la Aplicación”).</p>

  <p>Por favor, lee estas condiciones para registrarse correctamente en Zendy.</p>

  <h4>1. Términos y condiciones</h4>
  <p>
    <a href="https://about.zendy.tel/legal.html" target="_blank">https://about.zendy.tel/legal.html</a>
  </p>

  <h4>2. Política de Privacidad</h4>
  <p>
    <a href="https://about.zendy.tel/privacy.html" target="_blank">https://about.zendy.tel/privacy.html</a>
  </p>

  <h4>3. Política de cookies</h4>
  <p>
    <a href="https://about.zendy.tel/cookies.html" target="_blank">https://about.zendy.tel/cookies.html</a>
  </p>
  <br />
  <p>
    Al descargar, instalar o utilizar la aplicación Zendy, aceptas cumplir y quedar vinculado por las presentes
    Condiciones de servicio y Política de privacidad.</p>
  <p>
    Si no estás de acuerdo con estos términos, por favor no utilices Zendy.
  </p>

  <br />
  <br />
  <br />
  <h2>Legal information</h2>

  <p>SKYLINK Global SL." Spanish commercial entity domiciled in Madrid, at Calle Gabriel García Márquez 4, 1a Planta, Las Rozas de Madrid, España. C.P. 28232, with
    CIF B-10927978, registered in the Mercantile Registry of Madrid in Volume 43,853, folio 164, entry 1, Page M-773886
    (hereinafter, "SKYLINK"). Zendy is the brand under which SKYLINK operates, SKYLINK is the company owner and
    administrator of the web domain www.zendy.tel (hereinafter, the "Web" or the "Website") and the mobile application
    "ZENDY" (hereinafter, "the Application").</p>

  <p>Please read these terms to register succesfully in Zendy.</p>

  <h4>1. Terms and Conditions</h4>
  <p>
    <a href="https://about.zendy.tel/legal.html" target="_blank">https://about.zendy.tel/legal.html</a>
  </p>

  <h4>2. Privacy Policy</h4>
  <p>
    <a href="https://about.zendy.tel/privacy.html" target="_blank">https://about.zendy.tel/privacy.html</a>
  </p>

  <h4>3. Cookies Policy</h4>
  <p>
    <a href="https://about.zendy.tel/cookies.html" target="_blank">https://about.zendy.tel/cookies.html</a>
  </p>
  <br />
  <p>
    By downloading, installing, or using the Zendy application, you agree to comply with and be bound by these Terms of
    Service and Privacy Policy.</p>
  <p>
    If you do not agree to these terms, please do not use Zendy.
  </p>


</div>