import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { TUI_COUNTRIES } from '@taiga-ui/kit';
import { Observable } from 'rxjs';
import { RatesModal } from './rates.model';

@Component({
  selector: 'app-rates-modal',
  templateUrl: './rates-modal.component.html',
})
export class RatesModalComponent {
  readonly countries = Object.values(TuiCountryIsoCode);
  constructor(
    public dialog: MatDialogRef<RatesModalComponent>,
    @Inject(TUI_COUNTRIES)
    readonly countriesNames$: Observable<Record<TuiCountryIsoCode, string>>,
    @Inject(MAT_DIALOG_DATA) public data: RatesModal
  ) {}

  confirm(): void {
    this.dialog.close();
  }
}
