<h2 mat-dialog-title class="modal-title">{{ 'ESIM.PURCHASE_DETAILS.TITLE' | translate }}</h2>
<mat-dialog-content class="modal-content">
  <div class="check-container">
    <mat-icon class="check-icon">check_circle</mat-icon>
  </div>
  <div class="details-container">
    <p><strong>{{ 'ESIM.PURCHASE_DETAILS.ORDER_ID' | translate }}:</strong> {{ data.order.id }}</p>
    <p><strong>{{ 'ESIM.PURCHASE_DETAILS.DATA' | translate }}:</strong> {{ data.order.data }}</p>
    <p><strong>{{ 'ESIM.PURCHASE_DETAILS.PACKAGE' | translate }}:</strong> {{ data.order.package }}</p>
    <p><strong>{{ 'ESIM.PURCHASE_DETAILS.PRICE' | translate }}:</strong> {{ data.order.price | currency }}</p>
    <p><strong>{{ 'ESIM.PURCHASE_DETAILS.VALIDITY' | translate }}:</strong> {{ data.order.validity }} {{ data.order.validity > 1 ? ('ESIM.DAYS' | translate) : ('ESIM.DAY' | translate) }}</p>
    <p><strong>{{ 'ESIM.PURCHASE_DETAILS.PURCHASE_DATE' | translate }}:</strong> {{ data.order.created_at }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="modal-actions">
  <button mat-button mat-dialog-close>{{ 'ESIM.CLOSE' | translate }}</button>
</mat-dialog-actions>
