import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  APIService,
  Account,
  ModelSortDirection,
  ModelStringKeyConditionInput,
  ModelTransactionFilterInput,
  Transaction,
} from '../../API.service';
import { ConfigService } from '../../config.service';
import { AuthService } from '../../services/auth.service';
import { Item, ItemAbstract } from '../../utils/Item';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transfers-list',
  template: `<mat-list>
      <mat-list-item
        class="border-b border-solid border-gray-opacity"
        [ngClass]="{ '!h-16': !router.url.includes('home') }"
        *ngFor="let item of items"
      >
        <div class="flex justify-between items-center" matListItemTitle>
          <div class="text-base">
            {{ item.title }}
          </div>
          <div class="flex flex-col items-end">
            <div class="flex items-center gap-1 text-2xl">
              <img [src]="item.icon" />
              {{ item.amount | currency : item.currency : true }}
            </div>
            <span *ngIf="!router.url.includes('home')">{{
              item.caption | date : 'dd/MM/yy'
            }}</span>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
    <div class="warning" *ngIf="items.length <= 0">
      <div class="flex justify-center">
        <img class="mb-2 w-40 md:w-28" src="./assets/icons/no-records.svg" />
      </div>
      <h2>{{ 'TRANSACTIONS.NO_RESULTS_TITLE' | translate }}</h2>
      <p class="text-base mb-8">
        {{ 'TRANSACTIONS.NO_RESULTS_DESC' | translate }}
      </p>
    </div>`,
  styleUrls: [],
})
export class TransfersListComponent extends ItemAbstract implements OnInit {
  constructor(
    private api: APIService,
    private authService: AuthService,
    public configService: ConfigService,
    public router: Router,
    private translate: TranslateService
  ) {
    super();
    this.configService = configService;
  }

  async ngOnInit() {
    this.fetchItems();
  }

  fetchItems() {
    this.api
      .TransactionsByDestinationAndOwner(
        Account.transfer,
        {
          eq: this.authService.getUser().username,
        } as ModelStringKeyConditionInput,
        'DESC' as ModelSortDirection,
        {} as ModelTransactionFilterInput,
        this.router.url.includes('home') ? 5 : 20,
        this.nextToken
      )
      .then((event) => {
        this.nextToken = event.nextToken;
        this.items = this.castToItem(event.items as Transaction[]) as Item[];
      });
  }

  castToItem(items: any[]) {
    return items.map((i) => {
      const info = JSON.parse(i.info);
      return {
        id: i.id,
        title:
          i.destination == 'balance'
            ? this.translate.instant('TRANSACTIONS.BALANCE_RECHARGE')
            : i.destination == 'call'
            ? this.translate.instant('TRANSACTIONS.CALL_TO') + ' ' + info.to
            : this.translate.instant('TRANSACTIONS.TRANSFER'),
        icon:
          i.destination == 'balance'
            ? './assets/icons/transfer-add.svg'
            : './assets/icons/transfer-minus.svg',
        caption: new Date(Date.parse(i.createdAt)).toUTCString(),
        currency: i.currency,
        amount: i.amount,
      };
    });
  }

  calculateItemAmountByType(item: Transaction): number {
    let sign: number = 1;
    switch (item.type) {
      case 'payout':
      case 'calls':
        sign = -1;
        break;
    }
    return sign * item.amount;
  }

  go(route: string, arg_index: number = 0) {
    //Do nothing
  }

  public onSeeDetail() {
    this.showDetail = true;
  }
}
