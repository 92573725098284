import { Component, inject, Input, OnInit } from '@angular/core';
import { BalanceService } from '../../services/balance.service';
import { ConfigService } from 'src/app/config.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-zendy-header',
  template: `
    <div
      class="w-full bg-gray-opacity rounded-2xl shadow pb-4 mt-8 mb-8 flex flex-col items-center"
    >
      <div *ngIf="showCard" class="flex flex-row justify-between w-full">
        <img
          class="relative top-minus30 rotate-minus12"
          width="147px"
          src="./assets/icons/card.svg"
          alt="card"
        />
        <p
          class="flex flex-col mb-2 mt-4 mr-2 tracking-tight text-gray items-end text-end"
        >
          {{ 'COMPONENTS.HEADER.BALANCE' | translate }}
          <span class="text-light-red text-2xl items-end text-right">
            {{ balance | currency : currency : 'symbol' }}
          </span>
          <a class="text-sm text-light-red" routerLink="/recharges">{{
            'COMPONENTS.HEADER.ADD' | translate
          }}</a>
        </p>
      </div>

      <div *ngIf="showButtons" class="flex flex-row w-full justify-around mt-4">
        <div class="flex flex-col items-center gap-2">
          <a routerLink="/calls">
            <img src="./assets/icons/new-call.svg" />
          </a>
          <div class="text-gray text-center">
            {{ 'COMPONENTS.HEADER.NEW_CALL' | translate }}
          </div>
        </div>
        <div class="flex flex-col items-center gap-2" (click)="goToNewTransfer()">
          <img src="./assets/icons/new-transfer.svg" />
          <div class="text-gray text-center">
            {{ 'COMPONENTS.HEADER.NEW_TRANSFER' | translate }}
          </div>
        </div>
        <div class="flex flex-col items-center gap-2">
          <a routerLink="/contacts/new-contact">
            <img src="./assets/icons/new-contact.svg" />
          </a>
          <div class="text-gray text-center">
            {{ 'COMPONENTS.HEADER.NEW_CONTACT' | translate }}
          </div>
        </div>
      </div>
    </div>
  `,
})
export class ZendyHeaderComponent implements OnInit {
  private balanceSvc = inject(BalanceService);
  transfersUrl = `${inject(ConfigService).config.transfersUrl}?tracker=${inject(AuthService).getUser().id}`;

  @Input() showCard = true;
  @Input() showButtons = true;

  balance!: number;
  currency: string = `EUR`;
  ngOnInit() {
    this.calculateBalance();
  }
  async calculateBalance() {
    this.balance = await this.balanceSvc.calculateBalance();
  }

  goToNewTransfer() {
    window.open(this.transfersUrl, '_blank');
  }
}
