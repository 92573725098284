<div class="container mx-auto p-0">
  <div class="no-gap-grid grid grid-cols-1 md:grid-cols-2">
    <!-- Div izquierda -->
    <div class="image-container">
      <img alt="Woman in red shirt talking on phone" class="h-full w-full block object-cover"
        src="./assets/icons/esim-header.png" />
    </div>

    <!-- Div derecha -->
    <div class="content-container bg-red-700 text-white p-6 flex flex-col justify-center">
      <p class="text-xl font-bold">
        {{ 'ESIM.SEARCH_TITLE_1' | translate }}
      </p>
      <p class="mt-2 text-sm item-value mb-4">
        {{ 'ESIM.SEARCH_TITLE_2' | translate }}
      </p>

      <div class="select-container mb-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-select [(ngModel)]="selectedType" class="w-full p-2 rounded bg-white text-black custom-select"
            (selectionChange)="onTypeChange()">

            <!-- <mat-option [value]="'default'" disabled>
              Select an option
            </mat-option> -->

            <mat-option *ngFor="let type of types" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div class="select-container mb-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-select [(ngModel)]="selectedCountry" class="w-full p-2 rounded bg-white text-black"
            placeholder="Select country">
            <mat-option value="">
              {{ 'ESIM.SELECT_COUNTRY' | translate }}:
            </mat-option>
            <mat-option *ngFor="let country of filteredCountries" [value]="country.code">
              <div class="flex items-center">
                <img [src]="country.image" alt="{{ country.country }}" class="country-flag mr-2 w-6 h-4">
                <span>{{ country.country }}</span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="button-container mt-2">
        <button mat-raised-button class="bg-white text-red-700" (click)="loadEsimPackages()">{{ 'ESIM.BTN_SEARCH_ESIM' |
          translate }}</button>
        <button mat-raised-button class="bg-white text-red-700 ml-4" (click)="showMyEsims()">{{ 'ESIM.BTN_MY_ESIM' |
          translate }}</button>
      </div>
    </div>
  </div>
</div>

<!-- Mostrar una tarjeta si solo hay una, aplicando la clase 'esim-card-single' -->
<div *ngIf="filteredPackages.length === 1 && !showEsims"
  class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-4">
  <mat-card *ngFor="let package of filteredPackages" class="esim-card esim-card-single" appearance="outlined">
    <mat-card-header class="mb-4">
      <mat-card-title class="font-bold">{{ package.title }}</mat-card-title>
    </mat-card-header>
    <div class="header-image-container mb-4 flex justify-center relative">
      <img mat-card-image [src]="package.operador[0].image" alt="{{ package.operador[0].operador }}"
        class="sim-card-image-small">
      <span class="country-label">{{ capitalizeCountryName(package.country) }}</span>
    </div>
    <hr>
    <mat-card-content>
      <div class="item-detail">
        <mat-icon>signal_cellular_alt</mat-icon>
        <label class="item-label">{{ 'ESIM.DATA' | translate }}:</label>
        <span class="item-value">{{ package.data }}</span>
      </div>
      <div class="item-detail">
        <mat-icon>date_range</mat-icon>
        <label class="item-label">{{ 'ESIM.VALIDITY' | translate }}:</label>
        <span class="item-value">{{ package.day }} {{ package.day > 1 ? ('ESIM.DAYS' | translate) : ('ESIM.DAY' |
          translate) }}</span>
      </div>
      <div class="item-detail">
        <mat-icon>sell</mat-icon>
        <label class="item-label">{{ 'ESIM.PRICE' | translate }}:</label>
        <span class="item-value">€ {{ package.price }}</span>
      </div>
    </mat-card-content>
    <hr>
    <mat-card-actions>
      <div class="buybutton mb-4">
        <button mat-button (click)="openBuyNowDialog(package)">{{ 'ESIM.BUY_NOW' | translate }}</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

<!-- Mostrar múltiples tarjetas si hay más de una -->
<div *ngIf="filteredPackages.length > 1 && !showEsims"
  class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-4">
  <mat-card *ngFor="let package of filteredPackages" class="esim-card" appearance="outlined">
    <mat-card-header class="mb-4">
      <mat-card-title class="font-bold">{{ package.title }}</mat-card-title>
    </mat-card-header>
    <div class="header-image-container mb-4 flex justify-center relative">
      <img mat-card-image [src]="package.operador[0].image" alt="{{ package.operador[0].operador }}"
        class="sim-card-image-small">
      <span class="country-label">{{ capitalizeCountryName(package.country) }}</span>
    </div>

    <hr>
    <mat-card-content>
      <div class="item-detail">
        <mat-icon>signal_cellular_alt</mat-icon>
        <label class="item-label">{{ 'ESIM.DATA' | translate }}:</label>
        <span class="item-value">{{ package.data }}</span>
      </div>
      <div class="item-detail">
        <mat-icon>date_range</mat-icon>
        <label class="item-label">{{ 'ESIM.VALIDITY' | translate }}:</label>
        <span class="item-value">{{ package.day }} {{ package.day > 1 ? ('ESIM.DAYS' | translate) : ('ESIM.DAY' |
          translate) }}</span>
      </div>
      <div class="item-detail">
        <mat-icon>sell</mat-icon>
        <label class="item-label">{{ 'ESIM.PRICE' | translate }}:</label>
        <span class="item-value">€ {{ package.price }}</span>
      </div>
    </mat-card-content>
    <hr>
    <mat-card-actions>
      <div class="buybutton mb-4">
        <button mat-button (click)="openBuyNowDialog(package)">{{ 'ESIM.BUY_NOW' | translate }}</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>


<!-- Lista de eSIMs del usuario -->
<div *ngIf="showEsims" class="grid md:grid-cols-2 gap-4 mt-2">
  <div *ngIf="!IsOnline; else esimsList">
    <div class="flex flex-col items-center p-4">
      <h2>{{ 'ONBOARDING.LOGIN.TITLE' | translate }}</h2>
    </div>
    <form class="md:max-w-xl md:m-auto" [formGroup]="loginForm">
      <div class="flex flex-col items-center p-4">
        <mat-form-field class="calls-full-width">
          <mat-label>{{ 'ONBOARDING.LOGIN.USER' | translate }}</mat-label>
          <input type="text" matInput formControlName="username" />
        </mat-form-field>
        <mat-form-field class="calls-full-width">
          <mat-label>{{ 'ONBOARDING.LOGIN.PASS' | translate }}</mat-label>
          <input type="password" matInput formControlName="password" />
        </mat-form-field>
        <app-button (click)="login()">
          {{ 'ONBOARDING.LOGIN.BUTTON' | translate }}
        </app-button>
      </div>
      <div class="flex flex-col items-center p-4">
        <a class="font-medium" routerLink="/onboarding/forgot" [replaceUrl]="true">
          {{ 'ONBOARDING.LOGIN.FORGOT' | translate }}
        </a>
      </div>
    </form>
  </div>

  <!-- Mostrar lista de eSIMs si el usuario está logueado -->
  <ng-template #esimsList>
    <!-- Lista de eSIMs del usuario -->
    <div *ngIf="showEsims" class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
      <div *ngIf="!IsOnline; else esimsList">
        <h2>{{ 'ONBOARDING.LOGIN.TITLE' | translate }}</h2>
        <form class="md:max-w-xl md:m-auto" [formGroup]="loginForm">
          <div class="flex flex-col items-center p-4">
            <mat-form-field class="calls-full-width">
              <mat-label>{{ 'ONBOARDING.LOGIN.USER' | translate }}</mat-label>
              <input type="text" matInput formControlName="username" />
            </mat-form-field>
            <mat-form-field class="calls-full-width">
              <mat-label>{{ 'ONBOARDING.LOGIN.PASS' | translate }}</mat-label>
              <input type="password" matInput formControlName="password" />
            </mat-form-field>
            <app-button (click)="login()">
              {{ 'ONBOARDING.LOGIN.BUTTON' | translate }}
            </app-button>
          </div>
          <div class="flex flex-col items-center p-4">
            <a class="font-medium" routerLink="/onboarding/forgot" [replaceUrl]="true">
              {{ 'ONBOARDING.LOGIN.FORGOT' | translate }}
            </a>
          </div>
        </form>
      </div>

      <!-- Mostrar lista de eSIMs si el usuario está logueado -->
      <ng-template #esimsList>
        <div *ngIf="userEsims.length > 0; else noEsims">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            <mat-card *ngFor="let esim of userEsims" class="esim-card" appearance="outlined">
              <mat-card-header class="mb-2 flex justify-between items-center">
                <div class="text-left esim-info">
                  <mat-card-title class="font-bold">{{ esim.details.iccid }}</mat-card-title>
                  <mat-card-subtitle class="font-bold">{{ esim.operator }}</mat-card-subtitle>
                  <mat-card-subtitle class="font-bold">{{ 'ESIM.COVERAGE' | translate }}: {{ esim.country }}</mat-card-subtitle>
                </div>
              
                <div class="esim-my-card-image-container relative ml-4">
                  <img src="https://cdn-dev.zendy.tel/images/Esim/EsimCard@2x.png" alt="SIM Card" class="esim-my-card-image" />
                  <span class="esim-my-country-label">{{ esim.country }}</span> <!-- Nombre del país en la imagen -->
                </div>
              </mat-card-header>
              

              <mat-card-content>
                <!-- Datos -->
                <div class="progress-section">
                  <div class="label-container">
                    <strong>{{ 'ESIM.DATA' | translate }}:</strong>
                    <p class="item-value right-align">
                      {{ 'ESIM.USED' | translate }}: {{ esim.usage_data?.total ? esim.usage_data.total -
                      esim.usage_data.remaining : 0 }}MB
                      {{ 'ESIM.OF' | translate }} {{ esim.usage_data?.total ? esim.usage_data.total : 0 }}MB
                    </p>
                  </div>
                  <mat-progress-bar mode="determinate"
                    [value]="calculatePercentage(esim.usage_data?.total, esim.usage_data?.remaining)"
                    class="progress-bar"></mat-progress-bar>
                </div>

                <!-- Voz -->
                <div class="progress-section">
                  <div class="label-container">
                    <strong>{{ 'ESIM.VOICE' | translate }}:</strong>
                    <p class="item-value right-align">
                      {{ 'ESIM.USED' | translate }}: {{ esim.usage_data?.total_voice ? esim.usage_data.total_voice -
                      esim.usage_data.remaining_voice : 0 }}
                      min {{ 'ESIM.OF' | translate }} {{ esim.usage_data?.total_voice ? esim.usage_data.total_voice : 0
                      }} min
                    </p>
                  </div>
                  <mat-progress-bar mode="determinate"
                    [value]="calculatePercentage(esim.usage_data?.total_voice, esim.usage_data?.remaining_voice)"
                    class="progress-bar"></mat-progress-bar>
                </div>

                <!-- SMS -->
                <div class="progress-section">
                  <div class="label-container">
                    <strong>SMS:</strong>
                    <p class="item-value right-align">
                      {{ 'ESIM.USED' | translate }}: {{ esim.usage_data?.total_text ? esim.usage_data.total_text -
                      esim.usage_data.remaining_text : 0 }}
                      {{ 'ESIM.OF' | translate }} {{ esim.usage_data?.total_text ? esim.usage_data.total_text : 0 }}
                    </p>
                  </div>
                  <mat-progress-bar mode="determinate"
                    [value]="calculatePercentage(esim.usage_data?.total_text, esim.usage_data?.remaining_text)"
                    class="progress-bar"></mat-progress-bar>
                </div>
              </mat-card-content>

              <mat-card-actions class="buybutton mb-4">
                <div class="mr-4">
                  <button mat-button (click)="openEsimDetailsDialog(esim)">{{ 'ESIM.BTN_DETAILS' | translate }}</button>
                </div>
                <div>
                  <button mat-button (click)="openTopupsDialog(esim.details.iccid)">{{ 'ESIM.TOPUP' | translate
                    }}</button>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <ng-template #noEsims>
          <div class="no-esims-container text-center">
            <div class="icon-container mb-4">
              <img src="./assets/icons/no-esims-icon.png" alt="No eSIMs" class="no-esims-icon" />
            </div>
            <h2 class="no-esims-title">{{ 'ESIM.NO_SIMS_REGISTERED' | translate }}</h2>
            <p class="no-esims-subtitle">
              {{ 'ESIM.NO_SIMS_DESCRIPTION' | translate }}
            </p>
          </div>
        </ng-template>
      </ng-template>
    </div>

    <ng-template #noEsims>
      <div class="no-esims-container text-center">
        <div class="icon-container mb-4">
          <img src="./assets/icons/no-esims-icon.png" alt="No eSIMs" class="no-esims-icon" />
        </div>
        <h2 class="no-esims-title">{{ 'ESIM.NO_SIMS_REGISTERED' | translate }}</h2>
        <p class="no-esims-subtitle">
          {{ 'ESIM.NO_SIMS_DESCRIPTION' | translate }}
        </p>
      </div>
    </ng-template>
  </ng-template>
</div>