import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AWSLambda } from '../AWSLambda.service';

@Injectable({
    providedIn: 'root'
})
export class BalanceService {

    lambda = inject(AWSLambda)
    authService = inject(AuthService)

    async calculateBalance(username?: string) {
        const balance = await this.lambda.getLambdaFunction(
            'lambda',
            '/transactions/balance',
            { queryStringParameters: { owner: username ? username : this.authService.getUser().username } }
        );
        return parseFloat(parseFloat(balance.amount).toFixed(2));
    }
}
