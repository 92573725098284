import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OnboardingService } from '../onboarding/onboarding.service';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-esim-login',
  templateUrl: './esim-login.component.html',
})
export class EsimLoginComponent {
  router = inject(Router);
  onboardingService = inject(OnboardingService);
  _snackBar = inject(MatSnackBar);
  authService = inject(AuthService);
  translateService = inject(TranslateService);
  dialogRef = inject(MatDialogRef<EsimLoginComponent>);

  public loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  async login() {
    if (this.loginForm.invalid) return;
    const formData = this.loginForm.getRawValue();
    if (formData.username && formData.password) {
      this.onboardingService.setUsername(formData.username);
      try {
        await this.authService.login(formData.username, formData.password);
        this.router.navigate(['home'], { replaceUrl: true });
        const language = this.translateService.getBrowserLang()
          ? this.translateService.getBrowserLang()
          : 'en';
        const user = await Auth.currentAuthenticatedUser();
        this.translateService.use(
          user.attributes?.['locale'] ? user.attributes?.['locale'] : language
        );

        // Close the login modal
        this.dialogRef.close();
        // Navigate to the /esim route
        this.router.navigate(['/esim'], { replaceUrl: true });

      } catch (e: any) {
        this._snackBar.open(e.message, 'Close', { duration: 5000 });
        if (e.code === 'UserNotConfirmedException') {
          this.router.navigate(['/onboarding/validation'], {
            replaceUrl: true,
          });
        }
      }
    }
  }
}
