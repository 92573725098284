import { Pipe, PipeTransform } from '@angular/core';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';

@Pipe({
  name: 'filterCountries',
  standalone: true
})
export class FilterCountriesPipe implements PipeTransform {
  transform(_: TuiCountryIsoCode[], countries: Record<TuiCountryIsoCode, string>, text: string): TuiCountryIsoCode[] {
    return (Object.keys(countries) as Array<TuiCountryIsoCode>).filter((key) =>
      countries[key].toLowerCase().includes(text?.toLowerCase()),
    ) as TuiCountryIsoCode[];
  }
}
