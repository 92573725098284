<tui-root>
  <ng-container>
    <mat-drawer-container class="!h-full" *ngIf="isLoggedIn$ | async">
      <mat-drawer
        #drawer
        class="w-64 px-6 py-16 !bg-gradient-to-t to-light-red from-zendy-dark-red text-white"
        mode="over"
      >
        <app-profile></app-profile>
        <app-menu></app-menu>
      </mat-drawer>
      <mat-drawer-content>
        <app-header class="sticky" (onOpenMenu)="drawer.toggle()"></app-header>
        <div id="content" class="md:max-w-4xl md:m-auto">
          <div class="custom-container">
            <router-outlet></router-outlet>
          </div>
        </div>

        <app-tabs *ngIf="isMobile && !isIos"></app-tabs>
      </mat-drawer-content>
    </mat-drawer-container>
    <div *ngIf="!(isLoggedIn$ | async)">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</tui-root>
