<app-onboarding-header></app-onboarding-header>
<div class="md:max-w-xl md:m-auto flex flex-col items-center mt-6 p-4">
  <img
    *ngIf="!errorCode"
    class="flex w-24 h-24 rounded-full"
    src="./assets/icons/email-validation.svg"
    alt="Profile image"
  />
  <img
    *ngIf="errorCode"
    class="flex w-24 h-24 rounded-full"
    src="./assets/icons/email-validation-error.svg"
    alt="Profile image"
  />
  <h2 *ngIf="!errorCode" class="text-center">
    {{ 'ONBOARDING.CODE.TITLE_OK' | translate }}
  </h2>
  <h2 *ngIf="errorCode" class="text-center">
    {{ 'ONBOARDING.CODE.TITLE_KO' | translate }}
  </h2>
  <p *ngIf="!errorCode" class="text-base text-center">
    {{ 'ONBOARDING.CODE.DESC_OK' | translate }}
  </p>
  <p *ngIf="errorCode" class="text-base text-center">
    {{ 'ONBOARDING.CODE.DESC_KO' | translate }}
  </p>
  <mat-form-field class="calls-full-width my-8">
    <mat-label>{{ 'ONBOARDING.CODE.CODE' | translate }}</mat-label>
    <input type="text" matInput [(ngModel)]="validationCode" />
  </mat-form-field>
  <app-button (click)="validate()">
    {{ 'ONBOARDING.CODE.CONFIRM' | translate }}
  </app-button>
</div>
<div class="md:max-w-xl md:m-auto p-4">
  <hr />
</div>
<div class="flex flex-col items-center p-4">
  <p class="text-sm mb-8 font-medium">
    {{ 'ONBOARDING.CODE.QUESTION' | translate }}
  </p>
  <app-button color="secondary" (click)="resendCode()">
    {{ 'ONBOARDING.CODE.RESEND_BUTTON' | translate }}
  </app-button>
</div>
