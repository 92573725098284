import { Component, inject } from '@angular/core';
import { ConfigService } from '../../config.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-history',
  template: ` <div class="flex flex-row w-full justify-between mt-4">
      <div class="flex flex-col items-center gap-2">
        <a routerLink="new-contact">
          <img src="./assets/icons/new-contact.svg" />
        </a>
        <div class="text-gray">{{ 'HISTORY.NEW_CONTACT' | translate }}</div>
      </div>
      <div class="flex flex-col items-center gap-2">
        <a routerLink="/calls">
          <img src="./assets/icons/new-call.svg" />
        </a>
        <div class="text-gray">{{ 'HISTORY.NEW_CALL' | translate }}</div>
      </div>
      <div class="flex flex-col items-center gap-2">
        <a href="{{ transfersUrl }}">
          <img src="./assets/icons/new-transfer.svg" />
        </a>
        <div class="text-gray">{{ 'HISTORY.NEW_TRANSFER' | translate }}</div>
      </div>
    </div>
    <h2>{{ 'HISTORY.TITLE' | translate }}</h2>
    <app-history-list></app-history-list>
    <div class="flex justify-center mt-6 mb-10">
      <app-button hasIcon="hasIcon" routerLink="/calls"
        ><img
          width="24px"
          height="24px"
          src="./assets/icons/call-white.svg"
        />{{ 'HISTORY.BUTTON' | translate }}</app-button
      >
    </div>`,
})
export class HistoryComponent {
  transfersUrl = `${inject(ConfigService).config.transfersUrl}?tracker=${inject(AuthService).getUser().id}`;
}
