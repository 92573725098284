import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EsimService } from '../esim.service';

@Component({
  selector: 'app-esim-details-modal',
  templateUrl: './esim-details-modal.component.html',
  styleUrls: ['./esim-details-modal.component.scss']
})
export class EsimDetailsModalComponent {
  qrCodeUrl: string;
  usageData: any;
  details: any;
  country: string;
  operator: string;
  installationGuide: {
    language: string,
    ios?: any[],
    android?: any[]
  } | null = null;
  isLoading: boolean = false;
  isLoaded: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EsimDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private esimService: EsimService
  ) {
    this.usageData = data.usage_data;
    this.details = data.details;
    this.country = data.country;
    this.operator = data.operator;
    this.qrCodeUrl = this.details ? this.details.qrcode_url : ''; 
  }

  onClose(): void {
    this.dialogRef.close();
  }

  calculateUsagePercentage(total: number, remaining: number): number {
    if (total === 0) {
      return 0;
    }
    return ((total - remaining) / total) * 100;
  }

  fetchInstallationGuide(iccid: string): void {
    if (this.isLoaded) {
      return;
    }
    
    this.isLoading = true;
    this.esimService.getInstallationGuide(iccid).then(response => {
      if (response && response.data && response.data.instructions) {
        this.installationGuide = {
          language: response.data.instructions.language,
          ios: response.data.instructions.ios,
          android: response.data.instructions.android
        };
        this.isLoaded = true;
      }
      this.isLoading = false;
    }).catch(error => {
      console.error('Error fetching installation guide:', error);
      this.isLoading = false;
    });
  }
}
