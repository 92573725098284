import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TUI_IS_IOS, TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { Amplify, Auth, I18n } from 'aws-amplify';
import { Subscription } from 'rxjs';
import awsExports from 'src/aws-exports';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn$ = inject(AuthService).isLoggedIn$;
  title = 'skylink-fullstack-code';
  amplify = {
    es: {
      'Sign In': 'Registrarse',
      'Sign Up': 'Regístrate',
    },
  };

  //keep refs to subscriptions to be able to unsubscribe later

  private subscription = new Subscription();

  @ViewChild('drawer') drawer!: MatDrawer;

  constructor(
    private translate: TranslateService,
    @Inject(TUI_IS_MOBILE) readonly isMobile: boolean,
    @Inject(TUI_IS_IOS) readonly isIos: boolean,
    private router: Router
  ) {
    Amplify.configure(awsExports);
    I18n.putVocabularies(this.amplify);
  }

  async ngOnInit() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    const localeLanguage = window.localStorage.getItem('_language');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let language = this.translate.getBrowserLang();
    if (localeLanguage && localeLanguage !== language) {
      language = localeLanguage;
      window.localStorage.setItem('_language', localeLanguage);
      this.translate.use(localeLanguage);
    } else {
      window.localStorage.setItem('_language', language ?? 'en');
      this.translate.use(language ?? 'en');
    }
    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.drawer?.close();
        }
      })
    );
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.subscription.unsubscribe();
  }
}
