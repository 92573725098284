/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateWallet: OnCreateWalletSubscription;
  onUpdateWallet: OnUpdateWalletSubscription;
  onDeleteWallet: OnDeleteWalletSubscription;
  onCreateContact: OnCreateContactSubscription;
  onUpdateContact: OnUpdateContactSubscription;
  onDeleteContact: OnDeleteContactSubscription;
  onCreateEULA: OnCreateEULASubscription;
  onUpdateEULA: OnUpdateEULASubscription;
  onDeleteEULA: OnDeleteEULASubscription;
  onCreateCall: OnCreateCallSubscription;
  onUpdateCall: OnUpdateCallSubscription;
  onDeleteCall: OnDeleteCallSubscription;
  onCreateCallEvent: OnCreateCallEventSubscription;
  onUpdateCallEvent: OnUpdateCallEventSubscription;
  onDeleteCallEvent: OnDeleteCallEventSubscription;
  onCreateTransaction: OnCreateTransactionSubscription;
  onUpdateTransaction: OnUpdateTransactionSubscription;
  onDeleteTransaction: OnDeleteTransactionSubscription;
  onCreateCountryAllowed: OnCreateCountryAllowedSubscription;
  onUpdateCountryAllowed: OnUpdateCountryAllowedSubscription;
  onDeleteCountryAllowed: OnDeleteCountryAllowedSubscription;
  onCreateTPV: OnCreateTPVSubscription;
  onUpdateTPV: OnUpdateTPVSubscription;
  onDeleteTPV: OnDeleteTPVSubscription;
  onCreateWhitelist: OnCreateWhitelistSubscription;
  onUpdateWhitelist: OnUpdateWhitelistSubscription;
  onDeleteWhitelist: OnDeleteWhitelistSubscription;
  onCreateBanned: OnCreateBannedSubscription;
  onUpdateBanned: OnUpdateBannedSubscription;
  onDeleteBanned: OnDeleteBannedSubscription;
  onCreateAiraloPackages: OnCreateAiraloPackagesSubscription;
  onUpdateAiraloPackages: OnUpdateAiraloPackagesSubscription;
  onDeleteAiraloPackages: OnDeleteAiraloPackagesSubscription;
  onCreateAiraloPrices: OnCreateAiraloPricesSubscription;
  onUpdateAiraloPrices: OnUpdateAiraloPricesSubscription;
  onDeleteAiraloPrices: OnDeleteAiraloPricesSubscription;
  onCreateAiraloToken: OnCreateAiraloTokenSubscription;
  onUpdateAiraloToken: OnUpdateAiraloTokenSubscription;
  onDeleteAiraloToken: OnDeleteAiraloTokenSubscription;
  onCreateAiraloEsimOrders: OnCreateAiraloEsimOrdersSubscription;
  onUpdateAiraloEsimOrders: OnUpdateAiraloEsimOrdersSubscription;
  onDeleteAiraloEsimOrders: OnDeleteAiraloEsimOrdersSubscription;
  onCreateAiraloTopUpOrders: OnCreateAiraloTopUpOrdersSubscription;
  onUpdateAiraloTopUpOrders: OnUpdateAiraloTopUpOrdersSubscription;
  onDeleteAiraloTopUpOrders: OnDeleteAiraloTopUpOrdersSubscription;
  onCreateAiraloUserEsim: OnCreateAiraloUserEsimSubscription;
  onUpdateAiraloUserEsim: OnUpdateAiraloUserEsimSubscription;
  onDeleteAiraloUserEsim: OnDeleteAiraloUserEsimSubscription;
  onCreateCredencial: OnCreateCredencialSubscription;
  onUpdateCredencial: OnUpdateCredencialSubscription;
  onDeleteCredencial: OnDeleteCredencialSubscription;
  onCreateVoiceCallsPricing: OnCreateVoiceCallsPricingSubscription;
  onUpdateVoiceCallsPricing: OnUpdateVoiceCallsPricingSubscription;
  onDeleteVoiceCallsPricing: OnDeleteVoiceCallsPricingSubscription;
  onCreateVoiceCallsPricingSchemeNetwork: OnCreateVoiceCallsPricingSchemeNetworkSubscription;
  onUpdateVoiceCallsPricingSchemeNetwork: OnUpdateVoiceCallsPricingSchemeNetworkSubscription;
  onDeleteVoiceCallsPricingSchemeNetwork: OnDeleteVoiceCallsPricingSchemeNetworkSubscription;
  onCreateVoiceCallsPricingSchemeCountry: OnCreateVoiceCallsPricingSchemeCountrySubscription;
  onUpdateVoiceCallsPricingSchemeCountry: OnUpdateVoiceCallsPricingSchemeCountrySubscription;
  onDeleteVoiceCallsPricingSchemeCountry: OnDeleteVoiceCallsPricingSchemeCountrySubscription;
  onCreateVoiceCallsBillingNetwork: OnCreateVoiceCallsBillingNetworkSubscription;
  onUpdateVoiceCallsBillingNetwork: OnUpdateVoiceCallsBillingNetworkSubscription;
  onDeleteVoiceCallsBillingNetwork: OnDeleteVoiceCallsBillingNetworkSubscription;
};

export type ModelWalletConnection = {
  __typename: "ModelWalletConnection";
  items: Array<Wallet | null>;
  nextToken?: string | null;
};

export type Wallet = {
  __typename: "Wallet";
  id: string;
  address_id: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;
  createdAt: string;
  network: Network;
  updatedAt: string;
};

export enum Network {
  MATIC = "MATIC"
}

export type ModelContactConnection = {
  __typename: "ModelContactConnection";
  items: Array<Contact | null>;
  nextToken?: string | null;
};

export type Contact = {
  __typename: "Contact";
  id: string;
  name: string;
  telephone: string;
  email?: string | null;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelCallConnection = {
  __typename: "ModelCallConnection";
  items: Array<Call | null>;
  nextToken?: string | null;
};

export type Call = {
  __typename: "Call";
  id: string;
  identifier: string;
  destinationNumber: string;
  direction: string;
  resource: string;
  owner: string;
  hangup: boolean;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt: string;
  state: string;
  note?: string | null;
  updatedAt: string;
};

export type ModelTransactionConnection = {
  __typename: "ModelTransactionConnection";
  items: Array<Transaction | null>;
  nextToken?: string | null;
};

export type Transaction = {
  __typename: "Transaction";
  id: string;
  resource: string;
  type: string;
  amount: number;
  source: Account;
  destination: Account;
  currency: Currency;
  owner: string;
  createdAt: string;
  info?: string | null;
  updatedAt: string;
};

export enum Account {
  checkout = "checkout",
  paypal = "paypal",
  stripe = "stripe",
  wallet = "wallet",
  call = "call",
  balance = "balance",
  payout = "payout",
  tpv = "tpv",
  transfer = "transfer"
}

export enum Currency {
  USD = "USD",
  EUR = "EUR",
  MATIC = "MATIC"
}

export type ModelVoiceCallsPricingConnection = {
  __typename: "ModelVoiceCallsPricingConnection";
  items: Array<VoiceCallsPricing | null>;
  nextToken?: string | null;
};

export type VoiceCallsPricing = {
  __typename: "VoiceCallsPricing";
  id: string;
  fromCountryCode: string;
  toCountryCode: string;
  landlineOrMobile: LandlineOrMobile;
  vendor: Vendor;
  amountPerMinuteEuro: number;
  createdAt: string;
  updatedAt: string;
};

export enum LandlineOrMobile {
  LANDLINE = "LANDLINE",
  MOBILE = "MOBILE"
}

export enum Vendor {
  SIGNALWIRE = "SIGNALWIRE",
  TWILIO = "TWILIO"
}

export type CreateWalletInput = {
  id?: string | null;
  address_id: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;
  createdAt?: string | null;
  network: Network;
};

export type ModelWalletConditionInput = {
  address_id?: ModelStringInput | null;
  address?: ModelStringInput | null;
  telephone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  network?: ModelNetworkInput | null;
  and?: Array<ModelWalletConditionInput | null> | null;
  or?: Array<ModelWalletConditionInput | null> | null;
  not?: ModelWalletConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelNetworkInput = {
  eq?: Network | null;
  ne?: Network | null;
};

export type UpdateWalletInput = {
  id: string;
  address_id?: string | null;
  address?: string | null;
  telephone?: string | null;
  email?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  network?: Network | null;
};

export type DeleteWalletInput = {
  id: string;
};

export type CreateContactInput = {
  id?: string | null;
  name: string;
  telephone: string;
  email?: string | null;
  owner: string;
};

export type ModelContactConditionInput = {
  name?: ModelStringInput | null;
  telephone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelContactConditionInput | null> | null;
  or?: Array<ModelContactConditionInput | null> | null;
  not?: ModelContactConditionInput | null;
};

export type UpdateContactInput = {
  id: string;
  name?: string | null;
  telephone?: string | null;
  email?: string | null;
  owner?: string | null;
};

export type DeleteContactInput = {
  id: string;
};

export type CreateEULAInput = {
  id?: string | null;
  version: string;
  owner: string;
  createdAt?: string | null;
};

export type ModelEULAConditionInput = {
  version?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelEULAConditionInput | null> | null;
  or?: Array<ModelEULAConditionInput | null> | null;
  not?: ModelEULAConditionInput | null;
};

export type EULA = {
  __typename: "EULA";
  id: string;
  version: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEULAInput = {
  id: string;
  version?: string | null;
  owner?: string | null;
  createdAt?: string | null;
};

export type DeleteEULAInput = {
  id: string;
};

export type CreateCallInput = {
  id?: string | null;
  identifier: string;
  destinationNumber: string;
  direction: string;
  resource: string;
  owner: string;
  hangup: boolean;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt?: string | null;
  state: string;
  note?: string | null;
};

export type ModelCallConditionInput = {
  identifier?: ModelStringInput | null;
  destinationNumber?: ModelStringInput | null;
  direction?: ModelStringInput | null;
  resource?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  hangup?: ModelBooleanInput | null;
  info?: ModelStringInput | null;
  about?: ModelStringInput | null;
  visible?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  state?: ModelStringInput | null;
  note?: ModelStringInput | null;
  and?: Array<ModelCallConditionInput | null> | null;
  or?: Array<ModelCallConditionInput | null> | null;
  not?: ModelCallConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateCallInput = {
  id: string;
  identifier?: string | null;
  destinationNumber?: string | null;
  direction?: string | null;
  resource?: string | null;
  owner?: string | null;
  hangup?: boolean | null;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt?: string | null;
  state?: string | null;
  note?: string | null;
};

export type DeleteCallInput = {
  id: string;
};

export type CreateCallEventInput = {
  id?: string | null;
  call: string;
  event?: string | null;
  createdAt?: string | null;
  owner?: string | null;
};

export type ModelCallEventConditionInput = {
  call?: ModelStringInput | null;
  event?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelCallEventConditionInput | null> | null;
  or?: Array<ModelCallEventConditionInput | null> | null;
  not?: ModelCallEventConditionInput | null;
};

export type CallEvent = {
  __typename: "CallEvent";
  id: string;
  call: string;
  event?: string | null;
  createdAt: string;
  owner?: string | null;
  updatedAt: string;
};

export type UpdateCallEventInput = {
  id: string;
  call?: string | null;
  event?: string | null;
  createdAt?: string | null;
  owner?: string | null;
};

export type DeleteCallEventInput = {
  id: string;
};

export type CreateTransactionInput = {
  id?: string | null;
  resource: string;
  type: string;
  amount: number;
  source: Account;
  destination: Account;
  currency: Currency;
  owner: string;
  createdAt?: string | null;
  info?: string | null;
};

export type ModelTransactionConditionInput = {
  resource?: ModelStringInput | null;
  type?: ModelStringInput | null;
  amount?: ModelFloatInput | null;
  source?: ModelAccountInput | null;
  destination?: ModelAccountInput | null;
  currency?: ModelCurrencyInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  info?: ModelStringInput | null;
  and?: Array<ModelTransactionConditionInput | null> | null;
  or?: Array<ModelTransactionConditionInput | null> | null;
  not?: ModelTransactionConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelAccountInput = {
  eq?: Account | null;
  ne?: Account | null;
};

export type ModelCurrencyInput = {
  eq?: Currency | null;
  ne?: Currency | null;
};

export type UpdateTransactionInput = {
  id: string;
  resource?: string | null;
  type?: string | null;
  amount?: number | null;
  source?: Account | null;
  destination?: Account | null;
  currency?: Currency | null;
  owner?: string | null;
  createdAt?: string | null;
  info?: string | null;
};

export type DeleteTransactionInput = {
  id: string;
};

export type CreateVoiceCallsPricingInput = {
  id?: string | null;
  fromCountryCode: string;
  toCountryCode: string;
  landlineOrMobile: LandlineOrMobile;
  vendor: Vendor;
  amountPerMinuteEuro: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelVoiceCallsPricingConditionInput = {
  fromCountryCode?: ModelStringInput | null;
  toCountryCode?: ModelStringInput | null;
  landlineOrMobile?: ModelLandlineOrMobileInput | null;
  vendor?: ModelVendorInput | null;
  amountPerMinuteEuro?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelVoiceCallsPricingConditionInput | null> | null;
  or?: Array<ModelVoiceCallsPricingConditionInput | null> | null;
  not?: ModelVoiceCallsPricingConditionInput | null;
};

export type ModelLandlineOrMobileInput = {
  eq?: LandlineOrMobile | null;
  ne?: LandlineOrMobile | null;
};

export type ModelVendorInput = {
  eq?: Vendor | null;
  ne?: Vendor | null;
};

export type UpdateVoiceCallsPricingInput = {
  id: string;
  fromCountryCode?: string | null;
  toCountryCode?: string | null;
  landlineOrMobile?: LandlineOrMobile | null;
  vendor?: Vendor | null;
  amountPerMinuteEuro?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteVoiceCallsPricingInput = {
  id: string;
};

export type CreateVoiceCallsPricingSchemeNetworkInput = {
  id?: string | null;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  default: boolean;
  vendor: Vendor;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelVoiceCallsPricingSchemeNetworkConditionInput = {
  network?: ModelStringInput | null;
  amountPerMinuteEuro?: ModelFloatInput | null;
  toCountryCode?: ModelStringInput | null;
  regexFilter?: ModelStringInput | null;
  default?: ModelBooleanInput | null;
  vendor?: ModelVendorInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelVoiceCallsPricingSchemeNetworkConditionInput | null> | null;
  or?: Array<ModelVoiceCallsPricingSchemeNetworkConditionInput | null> | null;
  not?: ModelVoiceCallsPricingSchemeNetworkConditionInput | null;
};

export type VoiceCallsPricingSchemeNetwork = {
  __typename: "VoiceCallsPricingSchemeNetwork";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  default: boolean;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVoiceCallsPricingSchemeNetworkInput = {
  id: string;
  network?: string | null;
  amountPerMinuteEuro?: number | null;
  toCountryCode?: string | null;
  regexFilter?: string | null;
  default?: boolean | null;
  vendor?: Vendor | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteVoiceCallsPricingSchemeNetworkInput = {
  id: string;
};

export type CreateVoiceCallsPricingSchemeCountryInput = {
  id?: string | null;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  vendor: Vendor;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelVoiceCallsPricingSchemeCountryConditionInput = {
  network?: ModelStringInput | null;
  amountPerMinuteEuro?: ModelFloatInput | null;
  toCountryCode?: ModelStringInput | null;
  regexFilter?: ModelStringInput | null;
  vendor?: ModelVendorInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelVoiceCallsPricingSchemeCountryConditionInput | null> | null;
  or?: Array<ModelVoiceCallsPricingSchemeCountryConditionInput | null> | null;
  not?: ModelVoiceCallsPricingSchemeCountryConditionInput | null;
};

export type VoiceCallsPricingSchemeCountry = {
  __typename: "VoiceCallsPricingSchemeCountry";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVoiceCallsPricingSchemeCountryInput = {
  id: string;
  network?: string | null;
  amountPerMinuteEuro?: number | null;
  toCountryCode?: string | null;
  regexFilter?: string | null;
  vendor?: Vendor | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteVoiceCallsPricingSchemeCountryInput = {
  id: string;
};

export type CreateVoiceCallsBillingNetworkInput = {
  id?: string | null;
  customer: string;
  network: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  minutes: number;
  operationalRPM: number;
  operationalRevenue: number;
};

export type ModelVoiceCallsBillingNetworkConditionInput = {
  customer?: ModelStringInput | null;
  network?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  minutes?: ModelFloatInput | null;
  operationalRPM?: ModelFloatInput | null;
  operationalRevenue?: ModelFloatInput | null;
  and?: Array<ModelVoiceCallsBillingNetworkConditionInput | null> | null;
  or?: Array<ModelVoiceCallsBillingNetworkConditionInput | null> | null;
  not?: ModelVoiceCallsBillingNetworkConditionInput | null;
};

export type VoiceCallsBillingNetwork = {
  __typename: "VoiceCallsBillingNetwork";
  id: string;
  customer: string;
  network: string;
  createdAt: string;
  updatedAt: string;
  minutes: number;
  operationalRPM: number;
  operationalRevenue: number;
};

export type UpdateVoiceCallsBillingNetworkInput = {
  id: string;
  customer?: string | null;
  network?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  minutes?: number | null;
  operationalRPM?: number | null;
  operationalRevenue?: number | null;
};

export type DeleteVoiceCallsBillingNetworkInput = {
  id: string;
};

export type CreateCountryAllowedInput = {
  id?: string | null;
  Country: string;
  Prefix: string;
  ISO3: string;
  ISO2?: string | null;
};

export type ModelCountryAllowedConditionInput = {
  Country?: ModelStringInput | null;
  Prefix?: ModelStringInput | null;
  ISO3?: ModelStringInput | null;
  ISO2?: ModelStringInput | null;
  and?: Array<ModelCountryAllowedConditionInput | null> | null;
  or?: Array<ModelCountryAllowedConditionInput | null> | null;
  not?: ModelCountryAllowedConditionInput | null;
};

export type CountryAllowed = {
  __typename: "CountryAllowed";
  id: string;
  Country: string;
  Prefix: string;
  ISO3: string;
  ISO2?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCountryAllowedInput = {
  id: string;
  Country?: string | null;
  Prefix?: string | null;
  ISO3?: string | null;
  ISO2?: string | null;
};

export type DeleteCountryAllowedInput = {
  id: string;
};

export type CreateTPVInput = {
  id?: string | null;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
};

export type ModelTPVConditionInput = {
  domain?: ModelStringInput | null;
  environment?: ModelStringInput | null;
  app?: ModelStringInput | null;
  pageSize?: ModelIntInput | null;
  paginationSize?: ModelIntInput | null;
  entidadbancaria?: ModelStringInput | null;
  termsAndConditionsVersion?: ModelStringInput | null;
  transfersUrl?: ModelStringInput | null;
  transfersAccountURL?: ModelStringInput | null;
  and?: Array<ModelTPVConditionInput | null> | null;
  or?: Array<ModelTPVConditionInput | null> | null;
  not?: ModelTPVConditionInput | null;
};

export type TPV = {
  __typename: "TPV";
  id: string;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTPVInput = {
  id: string;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
};

export type DeleteTPVInput = {
  id: string;
};

export type CreateWhitelistInput = {
  id?: string | null;
  email: string;
  telephone?: string | null;
};

export type ModelWhitelistConditionInput = {
  email?: ModelStringInput | null;
  telephone?: ModelStringInput | null;
  and?: Array<ModelWhitelistConditionInput | null> | null;
  or?: Array<ModelWhitelistConditionInput | null> | null;
  not?: ModelWhitelistConditionInput | null;
};

export type Whitelist = {
  __typename: "Whitelist";
  id: string;
  email: string;
  telephone?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateWhitelistInput = {
  id: string;
  email?: string | null;
  telephone?: string | null;
};

export type DeleteWhitelistInput = {
  id: string;
};

export type CreateBannedInput = {
  id?: string | null;
  sub: string;
  reason: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelBannedConditionInput = {
  sub?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelBannedConditionInput | null> | null;
  or?: Array<ModelBannedConditionInput | null> | null;
  not?: ModelBannedConditionInput | null;
};

export type Banned = {
  __typename: "Banned";
  id: string;
  sub: string;
  reason: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateBannedInput = {
  id: string;
  sub?: string | null;
  reason?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteBannedInput = {
  id: string;
};

export type CreateAiraloPackagesInput = {
  id?: string | null;
  country?: string | null;
  country_code?: string | null;
  id_pkg: string;
};

export type ModelAiraloPackagesConditionInput = {
  country?: ModelStringInput | null;
  country_code?: ModelStringInput | null;
  id_pkg?: ModelStringInput | null;
  and?: Array<ModelAiraloPackagesConditionInput | null> | null;
  or?: Array<ModelAiraloPackagesConditionInput | null> | null;
  not?: ModelAiraloPackagesConditionInput | null;
};

export type AiraloPackages = {
  __typename: "AiraloPackages";
  id: string;
  country?: string | null;
  country_code?: string | null;
  id_pkg: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAiraloPackagesInput = {
  id: string;
  country?: string | null;
  country_code?: string | null;
  id_pkg?: string | null;
};

export type DeleteAiraloPackagesInput = {
  id: string;
};

export type CreateAiraloPricesInput = {
  id?: string | null;
  Country?: string | null;
  PackageId: string;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
};

export type ModelAiraloPricesConditionInput = {
  Country?: ModelStringInput | null;
  PackageId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  Netprice?: ModelFloatInput | null;
  PartnerMinPrice?: ModelFloatInput | null;
  ourPrice?: ModelFloatInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAiraloPricesConditionInput | null> | null;
  or?: Array<ModelAiraloPricesConditionInput | null> | null;
  not?: ModelAiraloPricesConditionInput | null;
};

export type AiraloPrices = {
  __typename: "AiraloPrices";
  id: string;
  Country?: string | null;
  PackageId: string;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
  createdAt: string;
};

export type UpdateAiraloPricesInput = {
  id: string;
  Country?: string | null;
  PackageId?: string | null;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
};

export type DeleteAiraloPricesInput = {
  id: string;
};

export type CreateAiraloTokenInput = {
  id?: string | null;
  token_type?: string | null;
  expires_in?: number | null;
  access_token: string;
  activo?: number | null;
};

export type ModelAiraloTokenConditionInput = {
  token_type?: ModelStringInput | null;
  expires_in?: ModelIntInput | null;
  access_token?: ModelStringInput | null;
  activo?: ModelIntInput | null;
  and?: Array<ModelAiraloTokenConditionInput | null> | null;
  or?: Array<ModelAiraloTokenConditionInput | null> | null;
  not?: ModelAiraloTokenConditionInput | null;
};

export type AiraloToken = {
  __typename: "AiraloToken";
  id: string;
  token_type?: string | null;
  expires_in?: number | null;
  access_token: string;
  activo?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAiraloTokenInput = {
  id: string;
  token_type?: string | null;
  expires_in?: number | null;
  access_token?: string | null;
  activo?: number | null;
};

export type DeleteAiraloTokenInput = {
  id: string;
};

export type CreateAiraloEsimOrdersInput = {
  id?: string | null;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
};

export type ModelAiraloEsimOrdersConditionInput = {
  owner?: ModelStringInput | null;
  type?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updateAt?: ModelStringInput | null;
  and?: Array<ModelAiraloEsimOrdersConditionInput | null> | null;
  or?: Array<ModelAiraloEsimOrdersConditionInput | null> | null;
  not?: ModelAiraloEsimOrdersConditionInput | null;
};

export type AiraloEsimOrders = {
  __typename: "AiraloEsimOrders";
  id: string;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type UpdateAiraloEsimOrdersInput = {
  id: string;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
};

export type DeleteAiraloEsimOrdersInput = {
  id: string;
};

export type CreateAiraloTopUpOrdersInput = {
  id?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
};

export type ModelAiraloTopUpOrdersConditionInput = {
  createdAt?: ModelStringInput | null;
  updateAt?: ModelStringInput | null;
  and?: Array<ModelAiraloTopUpOrdersConditionInput | null> | null;
  or?: Array<ModelAiraloTopUpOrdersConditionInput | null> | null;
  not?: ModelAiraloTopUpOrdersConditionInput | null;
};

export type AiraloTopUpOrders = {
  __typename: "AiraloTopUpOrders";
  id: string;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type UpdateAiraloTopUpOrdersInput = {
  id: string;
  createdAt?: string | null;
  updateAt?: string | null;
};

export type DeleteAiraloTopUpOrdersInput = {
  id: string;
};

export type CreateAiraloUserEsimInput = {
  id?: string | null;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
};

export type ModelAiraloUserEsimConditionInput = {
  active?: ModelIntInput | null;
  country?: ModelStringInput | null;
  iccid?: ModelStringInput | null;
  operator?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updateAt?: ModelStringInput | null;
  and?: Array<ModelAiraloUserEsimConditionInput | null> | null;
  or?: Array<ModelAiraloUserEsimConditionInput | null> | null;
  not?: ModelAiraloUserEsimConditionInput | null;
};

export type AiraloUserEsim = {
  __typename: "AiraloUserEsim";
  id: string;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type UpdateAiraloUserEsimInput = {
  id: string;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
};

export type DeleteAiraloUserEsimInput = {
  id: string;
};

export type CreateCredencialInput = {
  id?: string | null;
  app: string;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
};

export type ModelCredencialConditionInput = {
  app?: ModelStringInput | null;
  token_type?: ModelStringInput | null;
  token?: ModelStringInput | null;
  description?: ModelIntInput | null;
  activo?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updateAt?: ModelStringInput | null;
  and?: Array<ModelCredencialConditionInput | null> | null;
  or?: Array<ModelCredencialConditionInput | null> | null;
  not?: ModelCredencialConditionInput | null;
};

export type Credencial = {
  __typename: "Credencial";
  id: string;
  app: string;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type UpdateCredencialInput = {
  id: string;
  app?: string | null;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
};

export type DeleteCredencialInput = {
  id: string;
};

export type ModelWalletFilterInput = {
  id?: ModelIDInput | null;
  address_id?: ModelStringInput | null;
  address?: ModelStringInput | null;
  telephone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  network?: ModelNetworkInput | null;
  and?: Array<ModelWalletFilterInput | null> | null;
  or?: Array<ModelWalletFilterInput | null> | null;
  not?: ModelWalletFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelContactFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  telephone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelContactFilterInput | null> | null;
  or?: Array<ModelContactFilterInput | null> | null;
  not?: ModelContactFilterInput | null;
};

export type ModelEULAFilterInput = {
  id?: ModelIDInput | null;
  version?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelEULAFilterInput | null> | null;
  or?: Array<ModelEULAFilterInput | null> | null;
  not?: ModelEULAFilterInput | null;
};

export type ModelEULAConnection = {
  __typename: "ModelEULAConnection";
  items: Array<EULA | null>;
  nextToken?: string | null;
};

export type ModelCallFilterInput = {
  id?: ModelIDInput | null;
  identifier?: ModelStringInput | null;
  destinationNumber?: ModelStringInput | null;
  direction?: ModelStringInput | null;
  resource?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  hangup?: ModelBooleanInput | null;
  info?: ModelStringInput | null;
  about?: ModelStringInput | null;
  visible?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  state?: ModelStringInput | null;
  note?: ModelStringInput | null;
  and?: Array<ModelCallFilterInput | null> | null;
  or?: Array<ModelCallFilterInput | null> | null;
  not?: ModelCallFilterInput | null;
};

export type ModelCallEventFilterInput = {
  id?: ModelIDInput | null;
  call?: ModelStringInput | null;
  event?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelCallEventFilterInput | null> | null;
  or?: Array<ModelCallEventFilterInput | null> | null;
  not?: ModelCallEventFilterInput | null;
};

export type ModelCallEventConnection = {
  __typename: "ModelCallEventConnection";
  items: Array<CallEvent | null>;
  nextToken?: string | null;
};

export type ModelTransactionFilterInput = {
  id?: ModelIDInput | null;
  resource?: ModelStringInput | null;
  type?: ModelStringInput | null;
  amount?: ModelFloatInput | null;
  source?: ModelAccountInput | null;
  destination?: ModelAccountInput | null;
  currency?: ModelCurrencyInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  info?: ModelStringInput | null;
  and?: Array<ModelTransactionFilterInput | null> | null;
  or?: Array<ModelTransactionFilterInput | null> | null;
  not?: ModelTransactionFilterInput | null;
};

export type ModelCountryAllowedFilterInput = {
  id?: ModelIDInput | null;
  Country?: ModelStringInput | null;
  Prefix?: ModelStringInput | null;
  ISO3?: ModelStringInput | null;
  ISO2?: ModelStringInput | null;
  and?: Array<ModelCountryAllowedFilterInput | null> | null;
  or?: Array<ModelCountryAllowedFilterInput | null> | null;
  not?: ModelCountryAllowedFilterInput | null;
};

export type ModelCountryAllowedConnection = {
  __typename: "ModelCountryAllowedConnection";
  items: Array<CountryAllowed | null>;
  nextToken?: string | null;
};

export type ModelTPVFilterInput = {
  id?: ModelIDInput | null;
  domain?: ModelStringInput | null;
  environment?: ModelStringInput | null;
  app?: ModelStringInput | null;
  pageSize?: ModelIntInput | null;
  paginationSize?: ModelIntInput | null;
  entidadbancaria?: ModelStringInput | null;
  termsAndConditionsVersion?: ModelStringInput | null;
  transfersUrl?: ModelStringInput | null;
  transfersAccountURL?: ModelStringInput | null;
  and?: Array<ModelTPVFilterInput | null> | null;
  or?: Array<ModelTPVFilterInput | null> | null;
  not?: ModelTPVFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelTPVConnection = {
  __typename: "ModelTPVConnection";
  items: Array<TPV | null>;
  nextToken?: string | null;
};

export type ModelWhitelistFilterInput = {
  id?: ModelIDInput | null;
  email?: ModelStringInput | null;
  telephone?: ModelStringInput | null;
  and?: Array<ModelWhitelistFilterInput | null> | null;
  or?: Array<ModelWhitelistFilterInput | null> | null;
  not?: ModelWhitelistFilterInput | null;
};

export type ModelWhitelistConnection = {
  __typename: "ModelWhitelistConnection";
  items: Array<Whitelist | null>;
  nextToken?: string | null;
};

export type ModelBannedFilterInput = {
  id?: ModelIDInput | null;
  sub?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelBannedFilterInput | null> | null;
  or?: Array<ModelBannedFilterInput | null> | null;
  not?: ModelBannedFilterInput | null;
};

export type ModelBannedConnection = {
  __typename: "ModelBannedConnection";
  items: Array<Banned | null>;
  nextToken?: string | null;
};

export type ModelAiraloPackagesFilterInput = {
  id?: ModelIDInput | null;
  country?: ModelStringInput | null;
  country_code?: ModelStringInput | null;
  id_pkg?: ModelStringInput | null;
  and?: Array<ModelAiraloPackagesFilterInput | null> | null;
  or?: Array<ModelAiraloPackagesFilterInput | null> | null;
  not?: ModelAiraloPackagesFilterInput | null;
};

export type ModelAiraloPackagesConnection = {
  __typename: "ModelAiraloPackagesConnection";
  items: Array<AiraloPackages | null>;
  nextToken?: string | null;
};

export type ModelAiraloPricesFilterInput = {
  id?: ModelIDInput | null;
  Country?: ModelStringInput | null;
  PackageId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  Netprice?: ModelFloatInput | null;
  PartnerMinPrice?: ModelFloatInput | null;
  ourPrice?: ModelFloatInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAiraloPricesFilterInput | null> | null;
  or?: Array<ModelAiraloPricesFilterInput | null> | null;
  not?: ModelAiraloPricesFilterInput | null;
};

export type ModelAiraloPricesConnection = {
  __typename: "ModelAiraloPricesConnection";
  items: Array<AiraloPrices | null>;
  nextToken?: string | null;
};

export type ModelAiraloTokenFilterInput = {
  id?: ModelIDInput | null;
  token_type?: ModelStringInput | null;
  expires_in?: ModelIntInput | null;
  access_token?: ModelStringInput | null;
  activo?: ModelIntInput | null;
  and?: Array<ModelAiraloTokenFilterInput | null> | null;
  or?: Array<ModelAiraloTokenFilterInput | null> | null;
  not?: ModelAiraloTokenFilterInput | null;
};

export type ModelAiraloTokenConnection = {
  __typename: "ModelAiraloTokenConnection";
  items: Array<AiraloToken | null>;
  nextToken?: string | null;
};

export type ModelAiraloEsimOrdersFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  type?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updateAt?: ModelStringInput | null;
  and?: Array<ModelAiraloEsimOrdersFilterInput | null> | null;
  or?: Array<ModelAiraloEsimOrdersFilterInput | null> | null;
  not?: ModelAiraloEsimOrdersFilterInput | null;
};

export type ModelAiraloEsimOrdersConnection = {
  __typename: "ModelAiraloEsimOrdersConnection";
  items: Array<AiraloEsimOrders | null>;
  nextToken?: string | null;
};

export type ModelAiraloTopUpOrdersFilterInput = {
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updateAt?: ModelStringInput | null;
  and?: Array<ModelAiraloTopUpOrdersFilterInput | null> | null;
  or?: Array<ModelAiraloTopUpOrdersFilterInput | null> | null;
  not?: ModelAiraloTopUpOrdersFilterInput | null;
};

export type ModelAiraloTopUpOrdersConnection = {
  __typename: "ModelAiraloTopUpOrdersConnection";
  items: Array<AiraloTopUpOrders | null>;
  nextToken?: string | null;
};

export type ModelAiraloUserEsimFilterInput = {
  id?: ModelIDInput | null;
  active?: ModelIntInput | null;
  country?: ModelStringInput | null;
  iccid?: ModelStringInput | null;
  operator?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updateAt?: ModelStringInput | null;
  and?: Array<ModelAiraloUserEsimFilterInput | null> | null;
  or?: Array<ModelAiraloUserEsimFilterInput | null> | null;
  not?: ModelAiraloUserEsimFilterInput | null;
};

export type ModelAiraloUserEsimConnection = {
  __typename: "ModelAiraloUserEsimConnection";
  items: Array<AiraloUserEsim | null>;
  nextToken?: string | null;
};

export type ModelCredencialFilterInput = {
  id?: ModelIDInput | null;
  app?: ModelStringInput | null;
  token_type?: ModelStringInput | null;
  token?: ModelStringInput | null;
  description?: ModelIntInput | null;
  activo?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updateAt?: ModelStringInput | null;
  and?: Array<ModelCredencialFilterInput | null> | null;
  or?: Array<ModelCredencialFilterInput | null> | null;
  not?: ModelCredencialFilterInput | null;
};

export type ModelCredencialConnection = {
  __typename: "ModelCredencialConnection";
  items: Array<Credencial | null>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyConditionInput = {
  eq?: ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyInput | null;
  le?: ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyInput | null;
  lt?: ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyInput | null;
  ge?: ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyInput | null;
  gt?: ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyInput | null;
  between?: Array<ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyInput | null> | null;
  beginsWith?: ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyInput | null;
};

export type ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyInput = {
  createdAt?: string | null;
  version?: string | null;
};

export type ModelIntKeyConditionInput = {
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelVoiceCallsPricingFilterInput = {
  id?: ModelIDInput | null;
  fromCountryCode?: ModelStringInput | null;
  toCountryCode?: ModelStringInput | null;
  landlineOrMobile?: ModelLandlineOrMobileInput | null;
  vendor?: ModelVendorInput | null;
  amountPerMinuteEuro?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelVoiceCallsPricingFilterInput | null> | null;
  or?: Array<ModelVoiceCallsPricingFilterInput | null> | null;
  not?: ModelVoiceCallsPricingFilterInput | null;
};

export type ModelVoiceCallsPricingSchemeNetworkFilterInput = {
  id?: ModelIDInput | null;
  network?: ModelStringInput | null;
  amountPerMinuteEuro?: ModelFloatInput | null;
  toCountryCode?: ModelStringInput | null;
  regexFilter?: ModelStringInput | null;
  default?: ModelBooleanInput | null;
  vendor?: ModelVendorInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelVoiceCallsPricingSchemeNetworkFilterInput | null> | null;
  or?: Array<ModelVoiceCallsPricingSchemeNetworkFilterInput | null> | null;
  not?: ModelVoiceCallsPricingSchemeNetworkFilterInput | null;
};

export type ModelVoiceCallsPricingSchemeNetworkConnection = {
  __typename: "ModelVoiceCallsPricingSchemeNetworkConnection";
  items: Array<VoiceCallsPricingSchemeNetwork | null>;
  nextToken?: string | null;
};

export type ModelVoiceCallsPricingSchemeCountryFilterInput = {
  id?: ModelIDInput | null;
  network?: ModelStringInput | null;
  amountPerMinuteEuro?: ModelFloatInput | null;
  toCountryCode?: ModelStringInput | null;
  regexFilter?: ModelStringInput | null;
  vendor?: ModelVendorInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelVoiceCallsPricingSchemeCountryFilterInput | null> | null;
  or?: Array<ModelVoiceCallsPricingSchemeCountryFilterInput | null> | null;
  not?: ModelVoiceCallsPricingSchemeCountryFilterInput | null;
};

export type ModelVoiceCallsPricingSchemeCountryConnection = {
  __typename: "ModelVoiceCallsPricingSchemeCountryConnection";
  items: Array<VoiceCallsPricingSchemeCountry | null>;
  nextToken?: string | null;
};

export type ModelVoiceCallsBillingNetworkFilterInput = {
  id?: ModelIDInput | null;
  customer?: ModelStringInput | null;
  network?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  minutes?: ModelFloatInput | null;
  operationalRPM?: ModelFloatInput | null;
  operationalRevenue?: ModelFloatInput | null;
  and?: Array<ModelVoiceCallsBillingNetworkFilterInput | null> | null;
  or?: Array<ModelVoiceCallsBillingNetworkFilterInput | null> | null;
  not?: ModelVoiceCallsBillingNetworkFilterInput | null;
};

export type ModelVoiceCallsBillingNetworkConnection = {
  __typename: "ModelVoiceCallsBillingNetworkConnection";
  items: Array<VoiceCallsBillingNetwork | null>;
  nextToken?: string | null;
};

export type ModelSubscriptionWalletFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  address_id?: ModelSubscriptionStringInput | null;
  address?: ModelSubscriptionStringInput | null;
  telephone?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  network?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionWalletFilterInput | null> | null;
  or?: Array<ModelSubscriptionWalletFilterInput | null> | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionContactFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  telephone?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionContactFilterInput | null> | null;
  or?: Array<ModelSubscriptionContactFilterInput | null> | null;
};

export type ModelSubscriptionEULAFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  version?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionEULAFilterInput | null> | null;
  or?: Array<ModelSubscriptionEULAFilterInput | null> | null;
};

export type ModelSubscriptionCallFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  identifier?: ModelSubscriptionStringInput | null;
  destinationNumber?: ModelSubscriptionStringInput | null;
  direction?: ModelSubscriptionStringInput | null;
  resource?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  hangup?: ModelSubscriptionBooleanInput | null;
  info?: ModelSubscriptionStringInput | null;
  about?: ModelSubscriptionStringInput | null;
  visible?: ModelSubscriptionIntInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  state?: ModelSubscriptionStringInput | null;
  note?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCallFilterInput | null> | null;
  or?: Array<ModelSubscriptionCallFilterInput | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionCallEventFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  call?: ModelSubscriptionStringInput | null;
  event?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCallEventFilterInput | null> | null;
  or?: Array<ModelSubscriptionCallEventFilterInput | null> | null;
};

export type ModelSubscriptionTransactionFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  resource?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  amount?: ModelSubscriptionFloatInput | null;
  source?: ModelSubscriptionStringInput | null;
  destination?: ModelSubscriptionStringInput | null;
  currency?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  info?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionTransactionFilterInput | null> | null;
  or?: Array<ModelSubscriptionTransactionFilterInput | null> | null;
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionCountryAllowedFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  Country?: ModelSubscriptionStringInput | null;
  Prefix?: ModelSubscriptionStringInput | null;
  ISO3?: ModelSubscriptionStringInput | null;
  ISO2?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCountryAllowedFilterInput | null> | null;
  or?: Array<ModelSubscriptionCountryAllowedFilterInput | null> | null;
};

export type ModelSubscriptionTPVFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  domain?: ModelSubscriptionStringInput | null;
  environment?: ModelSubscriptionStringInput | null;
  app?: ModelSubscriptionStringInput | null;
  pageSize?: ModelSubscriptionIntInput | null;
  paginationSize?: ModelSubscriptionIntInput | null;
  entidadbancaria?: ModelSubscriptionStringInput | null;
  termsAndConditionsVersion?: ModelSubscriptionStringInput | null;
  transfersUrl?: ModelSubscriptionStringInput | null;
  transfersAccountURL?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionTPVFilterInput | null> | null;
  or?: Array<ModelSubscriptionTPVFilterInput | null> | null;
};

export type ModelSubscriptionWhitelistFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  email?: ModelSubscriptionStringInput | null;
  telephone?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionWhitelistFilterInput | null> | null;
  or?: Array<ModelSubscriptionWhitelistFilterInput | null> | null;
};

export type ModelSubscriptionBannedFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  sub?: ModelSubscriptionStringInput | null;
  reason?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionBannedFilterInput | null> | null;
  or?: Array<ModelSubscriptionBannedFilterInput | null> | null;
};

export type ModelSubscriptionAiraloPackagesFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  country?: ModelSubscriptionStringInput | null;
  country_code?: ModelSubscriptionStringInput | null;
  id_pkg?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAiraloPackagesFilterInput | null> | null;
  or?: Array<ModelSubscriptionAiraloPackagesFilterInput | null> | null;
};

export type ModelSubscriptionAiraloPricesFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  Country?: ModelSubscriptionStringInput | null;
  PackageId?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  Netprice?: ModelSubscriptionFloatInput | null;
  PartnerMinPrice?: ModelSubscriptionFloatInput | null;
  ourPrice?: ModelSubscriptionFloatInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAiraloPricesFilterInput | null> | null;
  or?: Array<ModelSubscriptionAiraloPricesFilterInput | null> | null;
};

export type ModelSubscriptionAiraloTokenFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  token_type?: ModelSubscriptionStringInput | null;
  expires_in?: ModelSubscriptionIntInput | null;
  access_token?: ModelSubscriptionStringInput | null;
  activo?: ModelSubscriptionIntInput | null;
  and?: Array<ModelSubscriptionAiraloTokenFilterInput | null> | null;
  or?: Array<ModelSubscriptionAiraloTokenFilterInput | null> | null;
};

export type ModelSubscriptionAiraloEsimOrdersFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  owner?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updateAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAiraloEsimOrdersFilterInput | null> | null;
  or?: Array<ModelSubscriptionAiraloEsimOrdersFilterInput | null> | null;
};

export type ModelSubscriptionAiraloTopUpOrdersFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updateAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAiraloTopUpOrdersFilterInput | null> | null;
  or?: Array<ModelSubscriptionAiraloTopUpOrdersFilterInput | null> | null;
};

export type ModelSubscriptionAiraloUserEsimFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  active?: ModelSubscriptionIntInput | null;
  country?: ModelSubscriptionStringInput | null;
  iccid?: ModelSubscriptionStringInput | null;
  operator?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updateAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAiraloUserEsimFilterInput | null> | null;
  or?: Array<ModelSubscriptionAiraloUserEsimFilterInput | null> | null;
};

export type ModelSubscriptionCredencialFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  app?: ModelSubscriptionStringInput | null;
  token_type?: ModelSubscriptionStringInput | null;
  token?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionIntInput | null;
  activo?: ModelSubscriptionIntInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updateAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCredencialFilterInput | null> | null;
  or?: Array<ModelSubscriptionCredencialFilterInput | null> | null;
};

export type ModelSubscriptionVoiceCallsPricingFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  fromCountryCode?: ModelSubscriptionStringInput | null;
  toCountryCode?: ModelSubscriptionStringInput | null;
  landlineOrMobile?: ModelSubscriptionStringInput | null;
  vendor?: ModelSubscriptionStringInput | null;
  amountPerMinuteEuro?: ModelSubscriptionFloatInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionVoiceCallsPricingFilterInput | null> | null;
  or?: Array<ModelSubscriptionVoiceCallsPricingFilterInput | null> | null;
};

export type ModelSubscriptionVoiceCallsPricingSchemeNetworkFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  network?: ModelSubscriptionStringInput | null;
  amountPerMinuteEuro?: ModelSubscriptionFloatInput | null;
  toCountryCode?: ModelSubscriptionStringInput | null;
  regexFilter?: ModelSubscriptionStringInput | null;
  default?: ModelSubscriptionBooleanInput | null;
  vendor?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionVoiceCallsPricingSchemeNetworkFilterInput | null> | null;
  or?: Array<ModelSubscriptionVoiceCallsPricingSchemeNetworkFilterInput | null> | null;
};

export type ModelSubscriptionVoiceCallsPricingSchemeCountryFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  network?: ModelSubscriptionStringInput | null;
  amountPerMinuteEuro?: ModelSubscriptionFloatInput | null;
  toCountryCode?: ModelSubscriptionStringInput | null;
  regexFilter?: ModelSubscriptionStringInput | null;
  vendor?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionVoiceCallsPricingSchemeCountryFilterInput | null> | null;
  or?: Array<ModelSubscriptionVoiceCallsPricingSchemeCountryFilterInput | null> | null;
};

export type ModelSubscriptionVoiceCallsBillingNetworkFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  customer?: ModelSubscriptionStringInput | null;
  network?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  minutes?: ModelSubscriptionFloatInput | null;
  operationalRPM?: ModelSubscriptionFloatInput | null;
  operationalRevenue?: ModelSubscriptionFloatInput | null;
  and?: Array<ModelSubscriptionVoiceCallsBillingNetworkFilterInput | null> | null;
  or?: Array<ModelSubscriptionVoiceCallsBillingNetworkFilterInput | null> | null;
};

export type QueryWalletByEmailQuery = {
  __typename: "ModelWalletConnection";
  items: Array<{
    __typename: "Wallet";
    id: string;
    address_id: string;
    address: string;
    telephone: string;
    email: string;
    network: Network;
    createdAt: string;
  } | null>;
};

export type QueryWalletByOwnerQuery = {
  __typename: "ModelWalletConnection";
  items: Array<{
    __typename: "Wallet";
    id: string;
    address_id: string;
    address: string;
    telephone: string;
    email: string;
    owner: string;
    network: Network;
    createdAt: string;
  } | null>;
};

export type QueryContactByNameQuery = {
  __typename: "ModelContactConnection";
  items: Array<{
    __typename: "Contact";
    id: string;
    name: string;
    telephone: string;
    owner: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
};

export type QueryCallByOwnerQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
    identifier: string;
    destinationNumber: string;
    direction: string;
    resource: string;
    owner: string;
    hangup: boolean;
    info?: string | null;
    visible?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
};

export type QueryTransactionByOwnerQuery = {
  __typename: "ModelTransactionConnection";
  items: Array<{
    __typename: "Transaction";
    id: string;
    resource: string;
    type: string;
    amount: number;
    source: Account;
    destination: Account;
    currency: Currency;
    owner: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
};

export type QueryTransactionsByDestinationQuery = {
  __typename: "ModelTransactionConnection";
  items: Array<{
    __typename: "Transaction";
    id: string;
    resource: string;
    type: string;
    amount: number;
    source: Account;
    destination: Account;
    currency: Currency;
    owner: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
};

export type QueryVoiceCallsPricingsByFromCountryCodeQuery = {
  __typename: "ModelVoiceCallsPricingConnection";
  items: Array<{
    __typename: "VoiceCallsPricing";
    id: string;
    fromCountryCode: string;
    toCountryCode: string;
    landlineOrMobile: LandlineOrMobile;
    vendor: Vendor;
    amountPerMinuteEuro: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
};

export type CreateWalletMutation = {
  __typename: "Wallet";
  id: string;
  address_id: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;
  createdAt: string;
  network: Network;
  updatedAt: string;
};

export type UpdateWalletMutation = {
  __typename: "Wallet";
  id: string;
  address_id: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;
  createdAt: string;
  network: Network;
  updatedAt: string;
};

export type DeleteWalletMutation = {
  __typename: "Wallet";
  id: string;
  address_id: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;
  createdAt: string;
  network: Network;
  updatedAt: string;
};

export type CreateContactMutation = {
  __typename: "Contact";
  id: string;
  name: string;
  telephone: string;
  email?: string | null;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateContactMutation = {
  __typename: "Contact";
  id: string;
  name: string;
  telephone: string;
  email?: string | null;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteContactMutation = {
  __typename: "Contact";
  id: string;
  name: string;
  telephone: string;
  email?: string | null;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateEULAMutation = {
  __typename: "EULA";
  id: string;
  version: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEULAMutation = {
  __typename: "EULA";
  id: string;
  version: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteEULAMutation = {
  __typename: "EULA";
  id: string;
  version: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCallMutation = {
  __typename: "Call";
  id: string;
  identifier: string;
  destinationNumber: string;
  direction: string;
  resource: string;
  owner: string;
  hangup: boolean;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt: string;
  state: string;
  note?: string | null;
  updatedAt: string;
};

export type UpdateCallMutation = {
  __typename: "Call";
  id: string;
  identifier: string;
  destinationNumber: string;
  direction: string;
  resource: string;
  owner: string;
  hangup: boolean;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt: string;
  state: string;
  note?: string | null;
  updatedAt: string;
};

export type DeleteCallMutation = {
  __typename: "Call";
  id: string;
  identifier: string;
  destinationNumber: string;
  direction: string;
  resource: string;
  owner: string;
  hangup: boolean;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt: string;
  state: string;
  note?: string | null;
  updatedAt: string;
};

export type CreateCallEventMutation = {
  __typename: "CallEvent";
  id: string;
  call: string;
  event?: string | null;
  createdAt: string;
  owner?: string | null;
  updatedAt: string;
};

export type UpdateCallEventMutation = {
  __typename: "CallEvent";
  id: string;
  call: string;
  event?: string | null;
  createdAt: string;
  owner?: string | null;
  updatedAt: string;
};

export type DeleteCallEventMutation = {
  __typename: "CallEvent";
  id: string;
  call: string;
  event?: string | null;
  createdAt: string;
  owner?: string | null;
  updatedAt: string;
};

export type CreateTransactionMutation = {
  __typename: "Transaction";
  id: string;
  resource: string;
  type: string;
  amount: number;
  source: Account;
  destination: Account;
  currency: Currency;
  owner: string;
  createdAt: string;
  info?: string | null;
  updatedAt: string;
};

export type UpdateTransactionMutation = {
  __typename: "Transaction";
  id: string;
  resource: string;
  type: string;
  amount: number;
  source: Account;
  destination: Account;
  currency: Currency;
  owner: string;
  createdAt: string;
  info?: string | null;
  updatedAt: string;
};

export type DeleteTransactionMutation = {
  __typename: "Transaction";
  id: string;
  resource: string;
  type: string;
  amount: number;
  source: Account;
  destination: Account;
  currency: Currency;
  owner: string;
  createdAt: string;
  info?: string | null;
  updatedAt: string;
};

export type CreateVoiceCallsPricingMutation = {
  __typename: "VoiceCallsPricing";
  id: string;
  fromCountryCode: string;
  toCountryCode: string;
  landlineOrMobile: LandlineOrMobile;
  vendor: Vendor;
  amountPerMinuteEuro: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVoiceCallsPricingMutation = {
  __typename: "VoiceCallsPricing";
  id: string;
  fromCountryCode: string;
  toCountryCode: string;
  landlineOrMobile: LandlineOrMobile;
  vendor: Vendor;
  amountPerMinuteEuro: number;
  createdAt: string;
  updatedAt: string;
};

export type DeleteVoiceCallsPricingMutation = {
  __typename: "VoiceCallsPricing";
  id: string;
  fromCountryCode: string;
  toCountryCode: string;
  landlineOrMobile: LandlineOrMobile;
  vendor: Vendor;
  amountPerMinuteEuro: number;
  createdAt: string;
  updatedAt: string;
};

export type CreateVoiceCallsPricingSchemeNetworkMutation = {
  __typename: "VoiceCallsPricingSchemeNetwork";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  default: boolean;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVoiceCallsPricingSchemeNetworkMutation = {
  __typename: "VoiceCallsPricingSchemeNetwork";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  default: boolean;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type DeleteVoiceCallsPricingSchemeNetworkMutation = {
  __typename: "VoiceCallsPricingSchemeNetwork";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  default: boolean;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type CreateVoiceCallsPricingSchemeCountryMutation = {
  __typename: "VoiceCallsPricingSchemeCountry";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVoiceCallsPricingSchemeCountryMutation = {
  __typename: "VoiceCallsPricingSchemeCountry";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type DeleteVoiceCallsPricingSchemeCountryMutation = {
  __typename: "VoiceCallsPricingSchemeCountry";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type CreateVoiceCallsBillingNetworkMutation = {
  __typename: "VoiceCallsBillingNetwork";
  id: string;
  customer: string;
  network: string;
  createdAt: string;
  updatedAt: string;
  minutes: number;
  operationalRPM: number;
  operationalRevenue: number;
};

export type UpdateVoiceCallsBillingNetworkMutation = {
  __typename: "VoiceCallsBillingNetwork";
  id: string;
  customer: string;
  network: string;
  createdAt: string;
  updatedAt: string;
  minutes: number;
  operationalRPM: number;
  operationalRevenue: number;
};

export type DeleteVoiceCallsBillingNetworkMutation = {
  __typename: "VoiceCallsBillingNetwork";
  id: string;
  customer: string;
  network: string;
  createdAt: string;
  updatedAt: string;
  minutes: number;
  operationalRPM: number;
  operationalRevenue: number;
};

export type CreateCountryAllowedMutation = {
  __typename: "CountryAllowed";
  id: string;
  Country: string;
  Prefix: string;
  ISO3: string;
  ISO2?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCountryAllowedMutation = {
  __typename: "CountryAllowed";
  id: string;
  Country: string;
  Prefix: string;
  ISO3: string;
  ISO2?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCountryAllowedMutation = {
  __typename: "CountryAllowed";
  id: string;
  Country: string;
  Prefix: string;
  ISO3: string;
  ISO2?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateTPVMutation = {
  __typename: "TPV";
  id: string;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTPVMutation = {
  __typename: "TPV";
  id: string;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTPVMutation = {
  __typename: "TPV";
  id: string;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateWhitelistMutation = {
  __typename: "Whitelist";
  id: string;
  email: string;
  telephone?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateWhitelistMutation = {
  __typename: "Whitelist";
  id: string;
  email: string;
  telephone?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteWhitelistMutation = {
  __typename: "Whitelist";
  id: string;
  email: string;
  telephone?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateBannedMutation = {
  __typename: "Banned";
  id: string;
  sub: string;
  reason: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateBannedMutation = {
  __typename: "Banned";
  id: string;
  sub: string;
  reason: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteBannedMutation = {
  __typename: "Banned";
  id: string;
  sub: string;
  reason: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateAiraloPackagesMutation = {
  __typename: "AiraloPackages";
  id: string;
  country?: string | null;
  country_code?: string | null;
  id_pkg: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAiraloPackagesMutation = {
  __typename: "AiraloPackages";
  id: string;
  country?: string | null;
  country_code?: string | null;
  id_pkg: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAiraloPackagesMutation = {
  __typename: "AiraloPackages";
  id: string;
  country?: string | null;
  country_code?: string | null;
  id_pkg: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateAiraloPricesMutation = {
  __typename: "AiraloPrices";
  id: string;
  Country?: string | null;
  PackageId: string;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
  createdAt: string;
};

export type UpdateAiraloPricesMutation = {
  __typename: "AiraloPrices";
  id: string;
  Country?: string | null;
  PackageId: string;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
  createdAt: string;
};

export type DeleteAiraloPricesMutation = {
  __typename: "AiraloPrices";
  id: string;
  Country?: string | null;
  PackageId: string;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
  createdAt: string;
};

export type CreateAiraloTokenMutation = {
  __typename: "AiraloToken";
  id: string;
  token_type?: string | null;
  expires_in?: number | null;
  access_token: string;
  activo?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAiraloTokenMutation = {
  __typename: "AiraloToken";
  id: string;
  token_type?: string | null;
  expires_in?: number | null;
  access_token: string;
  activo?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAiraloTokenMutation = {
  __typename: "AiraloToken";
  id: string;
  token_type?: string | null;
  expires_in?: number | null;
  access_token: string;
  activo?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAiraloEsimOrdersMutation = {
  __typename: "AiraloEsimOrders";
  id: string;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type UpdateAiraloEsimOrdersMutation = {
  __typename: "AiraloEsimOrders";
  id: string;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type DeleteAiraloEsimOrdersMutation = {
  __typename: "AiraloEsimOrders";
  id: string;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type CreateAiraloTopUpOrdersMutation = {
  __typename: "AiraloTopUpOrders";
  id: string;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type UpdateAiraloTopUpOrdersMutation = {
  __typename: "AiraloTopUpOrders";
  id: string;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type DeleteAiraloTopUpOrdersMutation = {
  __typename: "AiraloTopUpOrders";
  id: string;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type CreateAiraloUserEsimMutation = {
  __typename: "AiraloUserEsim";
  id: string;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type UpdateAiraloUserEsimMutation = {
  __typename: "AiraloUserEsim";
  id: string;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type DeleteAiraloUserEsimMutation = {
  __typename: "AiraloUserEsim";
  id: string;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type CreateCredencialMutation = {
  __typename: "Credencial";
  id: string;
  app: string;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type UpdateCredencialMutation = {
  __typename: "Credencial";
  id: string;
  app: string;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type DeleteCredencialMutation = {
  __typename: "Credencial";
  id: string;
  app: string;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type GetWalletQuery = {
  __typename: "Wallet";
  id: string;
  address_id: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;
  createdAt: string;
  network: Network;
  updatedAt: string;
};

export type ListWalletsQuery = {
  __typename: "ModelWalletConnection";
  items: Array<{
    __typename: "Wallet";
    id: string;
    address_id: string;
    address: string;
    telephone: string;
    email: string;
    owner: string;
    createdAt: string;
    network: Network;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetContactQuery = {
  __typename: "Contact";
  id: string;
  name: string;
  telephone: string;
  email?: string | null;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type ListContactsQuery = {
  __typename: "ModelContactConnection";
  items: Array<{
    __typename: "Contact";
    id: string;
    name: string;
    telephone: string;
    email?: string | null;
    owner: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetEULAQuery = {
  __typename: "EULA";
  id: string;
  version: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type ListEULASQuery = {
  __typename: "ModelEULAConnection";
  items: Array<{
    __typename: "EULA";
    id: string;
    version: string;
    owner: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCallQuery = {
  __typename: "Call";
  id: string;
  identifier: string;
  destinationNumber: string;
  direction: string;
  resource: string;
  owner: string;
  hangup: boolean;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt: string;
  state: string;
  note?: string | null;
  updatedAt: string;
};

export type ListCallsQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
    identifier: string;
    destinationNumber: string;
    direction: string;
    resource: string;
    owner: string;
    hangup: boolean;
    info?: string | null;
    about?: string | null;
    visible?: number | null;
    createdAt: string;
    state: string;
    note?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCallEventQuery = {
  __typename: "CallEvent";
  id: string;
  call: string;
  event?: string | null;
  createdAt: string;
  owner?: string | null;
  updatedAt: string;
};

export type ListCallEventsQuery = {
  __typename: "ModelCallEventConnection";
  items: Array<{
    __typename: "CallEvent";
    id: string;
    call: string;
    event?: string | null;
    createdAt: string;
    owner?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetTransactionQuery = {
  __typename: "Transaction";
  id: string;
  resource: string;
  type: string;
  amount: number;
  source: Account;
  destination: Account;
  currency: Currency;
  owner: string;
  createdAt: string;
  info?: string | null;
  updatedAt: string;
};

export type ListTransactionsQuery = {
  __typename: "ModelTransactionConnection";
  items: Array<{
    __typename: "Transaction";
    id: string;
    resource: string;
    type: string;
    amount: number;
    source: Account;
    destination: Account;
    currency: Currency;
    owner: string;
    createdAt: string;
    info?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCountryAllowedQuery = {
  __typename: "CountryAllowed";
  id: string;
  Country: string;
  Prefix: string;
  ISO3: string;
  ISO2?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCountryAllowedsQuery = {
  __typename: "ModelCountryAllowedConnection";
  items: Array<{
    __typename: "CountryAllowed";
    id: string;
    Country: string;
    Prefix: string;
    ISO3: string;
    ISO2?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetTPVQuery = {
  __typename: "TPV";
  id: string;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTPVSQuery = {
  __typename: "ModelTPVConnection";
  items: Array<{
    __typename: "TPV";
    id: string;
    domain?: string | null;
    environment?: string | null;
    app?: string | null;
    pageSize?: number | null;
    paginationSize?: number | null;
    entidadbancaria?: string | null;
    termsAndConditionsVersion?: string | null;
    transfersUrl?: string | null;
    transfersAccountURL?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetWhitelistQuery = {
  __typename: "Whitelist";
  id: string;
  email: string;
  telephone?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListWhitelistsQuery = {
  __typename: "ModelWhitelistConnection";
  items: Array<{
    __typename: "Whitelist";
    id: string;
    email: string;
    telephone?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetBannedQuery = {
  __typename: "Banned";
  id: string;
  sub: string;
  reason: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListBannedsQuery = {
  __typename: "ModelBannedConnection";
  items: Array<{
    __typename: "Banned";
    id: string;
    sub: string;
    reason: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetAiraloPackagesQuery = {
  __typename: "AiraloPackages";
  id: string;
  country?: string | null;
  country_code?: string | null;
  id_pkg: string;
  createdAt: string;
  updatedAt: string;
};

export type ListAiraloPackagesQuery = {
  __typename: "ModelAiraloPackagesConnection";
  items: Array<{
    __typename: "AiraloPackages";
    id: string;
    country?: string | null;
    country_code?: string | null;
    id_pkg: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAiraloPricesQuery = {
  __typename: "AiraloPrices";
  id: string;
  Country?: string | null;
  PackageId: string;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
  createdAt: string;
};

export type ListAiraloPricesQuery = {
  __typename: "ModelAiraloPricesConnection";
  items: Array<{
    __typename: "AiraloPrices";
    id: string;
    Country?: string | null;
    PackageId: string;
    type?: string | null;
    Netprice?: number | null;
    PartnerMinPrice?: number | null;
    ourPrice?: number | null;
    updatedAt?: string | null;
    createdAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAiraloTokenQuery = {
  __typename: "AiraloToken";
  id: string;
  token_type?: string | null;
  expires_in?: number | null;
  access_token: string;
  activo?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAiraloTokensQuery = {
  __typename: "ModelAiraloTokenConnection";
  items: Array<{
    __typename: "AiraloToken";
    id: string;
    token_type?: string | null;
    expires_in?: number | null;
    access_token: string;
    activo?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAiraloEsimOrdersQuery = {
  __typename: "AiraloEsimOrders";
  id: string;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type ListAiraloEsimOrdersQuery = {
  __typename: "ModelAiraloEsimOrdersConnection";
  items: Array<{
    __typename: "AiraloEsimOrders";
    id: string;
    owner?: string | null;
    type?: string | null;
    createdAt?: string | null;
    updateAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAiraloTopUpOrdersQuery = {
  __typename: "AiraloTopUpOrders";
  id: string;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type ListAiraloTopUpOrdersQuery = {
  __typename: "ModelAiraloTopUpOrdersConnection";
  items: Array<{
    __typename: "AiraloTopUpOrders";
    id: string;
    createdAt?: string | null;
    updateAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAiraloUserEsimQuery = {
  __typename: "AiraloUserEsim";
  id: string;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type ListAiraloUserEsimsQuery = {
  __typename: "ModelAiraloUserEsimConnection";
  items: Array<{
    __typename: "AiraloUserEsim";
    id: string;
    active?: number | null;
    country?: string | null;
    iccid?: string | null;
    operator?: string | null;
    owner?: string | null;
    createdAt?: string | null;
    updateAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCredencialQuery = {
  __typename: "Credencial";
  id: string;
  app: string;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type ListCredencialsQuery = {
  __typename: "ModelCredencialConnection";
  items: Array<{
    __typename: "Credencial";
    id: string;
    app: string;
    token_type?: string | null;
    token?: string | null;
    description?: number | null;
    activo?: number | null;
    createdAt?: string | null;
    updateAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type WalletsByEmailAndTelephoneQuery = {
  __typename: "ModelWalletConnection";
  items: Array<{
    __typename: "Wallet";
    id: string;
    address_id: string;
    address: string;
    telephone: string;
    email: string;
    owner: string;
    createdAt: string;
    network: Network;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type WalletsByOwnerAndEmailQuery = {
  __typename: "ModelWalletConnection";
  items: Array<{
    __typename: "Wallet";
    id: string;
    address_id: string;
    address: string;
    telephone: string;
    email: string;
    owner: string;
    createdAt: string;
    network: Network;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ContactsByOwnerAndNameQuery = {
  __typename: "ModelContactConnection";
  items: Array<{
    __typename: "Contact";
    id: string;
    name: string;
    telephone: string;
    email?: string | null;
    owner: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EULASByOwnerAndCreatedAtAndVersionQuery = {
  __typename: "ModelEULAConnection";
  items: Array<{
    __typename: "EULA";
    id: string;
    version: string;
    owner: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CallsByDestinationNumberAndCreatedAtQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
    identifier: string;
    destinationNumber: string;
    direction: string;
    resource: string;
    owner: string;
    hangup: boolean;
    info?: string | null;
    about?: string | null;
    visible?: number | null;
    createdAt: string;
    state: string;
    note?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CallsByOwnerAndCreatedAtQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
    identifier: string;
    destinationNumber: string;
    direction: string;
    resource: string;
    owner: string;
    hangup: boolean;
    info?: string | null;
    about?: string | null;
    visible?: number | null;
    createdAt: string;
    state: string;
    note?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CallEventsByOwnerAndCreatedAtQuery = {
  __typename: "ModelCallEventConnection";
  items: Array<{
    __typename: "CallEvent";
    id: string;
    call: string;
    event?: string | null;
    createdAt: string;
    owner?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TransactionsByDestinationAndOwnerQuery = {
  __typename: "ModelTransactionConnection";
  items: Array<{
    __typename: "Transaction";
    id: string;
    resource: string;
    type: string;
    amount: number;
    source: Account;
    destination: Account;
    currency: Currency;
    owner: string;
    createdAt: string;
    info?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TransactionsByOwnerAndCreatedAtQuery = {
  __typename: "ModelTransactionConnection";
  items: Array<{
    __typename: "Transaction";
    id: string;
    resource: string;
    type: string;
    amount: number;
    source: Account;
    destination: Account;
    currency: Currency;
    owner: string;
    createdAt: string;
    info?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CountryAllowedsByPrefixAndCountryQuery = {
  __typename: "ModelCountryAllowedConnection";
  items: Array<{
    __typename: "CountryAllowed";
    id: string;
    Country: string;
    Prefix: string;
    ISO3: string;
    ISO2?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CountryAllowedsByISO3AndPrefixQuery = {
  __typename: "ModelCountryAllowedConnection";
  items: Array<{
    __typename: "CountryAllowed";
    id: string;
    Country: string;
    Prefix: string;
    ISO3: string;
    ISO2?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TPVSByDomainAndEnvironmentQuery = {
  __typename: "ModelTPVConnection";
  items: Array<{
    __typename: "TPV";
    id: string;
    domain?: string | null;
    environment?: string | null;
    app?: string | null;
    pageSize?: number | null;
    paginationSize?: number | null;
    entidadbancaria?: string | null;
    termsAndConditionsVersion?: string | null;
    transfersUrl?: string | null;
    transfersAccountURL?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TPVSByEnvironmentAndDomainQuery = {
  __typename: "ModelTPVConnection";
  items: Array<{
    __typename: "TPV";
    id: string;
    domain?: string | null;
    environment?: string | null;
    app?: string | null;
    pageSize?: number | null;
    paginationSize?: number | null;
    entidadbancaria?: string | null;
    termsAndConditionsVersion?: string | null;
    transfersUrl?: string | null;
    transfersAccountURL?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type WhitelistsByEmailAndTelephoneQuery = {
  __typename: "ModelWhitelistConnection";
  items: Array<{
    __typename: "Whitelist";
    id: string;
    email: string;
    telephone?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type WhitelistsByTelephoneAndEmailQuery = {
  __typename: "ModelWhitelistConnection";
  items: Array<{
    __typename: "Whitelist";
    id: string;
    email: string;
    telephone?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AiraloPackagesByCountryAndId_pkgQuery = {
  __typename: "ModelAiraloPackagesConnection";
  items: Array<{
    __typename: "AiraloPackages";
    id: string;
    country?: string | null;
    country_code?: string | null;
    id_pkg: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AiraloPackagesByCountry_codeAndCountryQuery = {
  __typename: "ModelAiraloPackagesConnection";
  items: Array<{
    __typename: "AiraloPackages";
    id: string;
    country?: string | null;
    country_code?: string | null;
    id_pkg: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AiraloPackagesById_pkgAndCountryQuery = {
  __typename: "ModelAiraloPackagesConnection";
  items: Array<{
    __typename: "AiraloPackages";
    id: string;
    country?: string | null;
    country_code?: string | null;
    id_pkg: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AiraloPricesByPackageIdAndCountryQuery = {
  __typename: "ModelAiraloPricesConnection";
  items: Array<{
    __typename: "AiraloPrices";
    id: string;
    Country?: string | null;
    PackageId: string;
    type?: string | null;
    Netprice?: number | null;
    PartnerMinPrice?: number | null;
    ourPrice?: number | null;
    updatedAt?: string | null;
    createdAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AiraloTokensByAccess_tokenAndActivoQuery = {
  __typename: "ModelAiraloTokenConnection";
  items: Array<{
    __typename: "AiraloToken";
    id: string;
    token_type?: string | null;
    expires_in?: number | null;
    access_token: string;
    activo?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AiraloUserEsimsByIccidAndOwnerQuery = {
  __typename: "ModelAiraloUserEsimConnection";
  items: Array<{
    __typename: "AiraloUserEsim";
    id: string;
    active?: number | null;
    country?: string | null;
    iccid?: string | null;
    operator?: string | null;
    owner?: string | null;
    createdAt?: string | null;
    updateAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CredencialsByAppAndActivoQuery = {
  __typename: "ModelCredencialConnection";
  items: Array<{
    __typename: "Credencial";
    id: string;
    app: string;
    token_type?: string | null;
    token?: string | null;
    description?: number | null;
    activo?: number | null;
    createdAt?: string | null;
    updateAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetVoiceCallsPricingQuery = {
  __typename: "VoiceCallsPricing";
  id: string;
  fromCountryCode: string;
  toCountryCode: string;
  landlineOrMobile: LandlineOrMobile;
  vendor: Vendor;
  amountPerMinuteEuro: number;
  createdAt: string;
  updatedAt: string;
};

export type ListVoiceCallsPricingsQuery = {
  __typename: "ModelVoiceCallsPricingConnection";
  items: Array<{
    __typename: "VoiceCallsPricing";
    id: string;
    fromCountryCode: string;
    toCountryCode: string;
    landlineOrMobile: LandlineOrMobile;
    vendor: Vendor;
    amountPerMinuteEuro: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VoiceCallsPricingsByToCountryCodeAndFromCountryCodeQuery = {
  __typename: "ModelVoiceCallsPricingConnection";
  items: Array<{
    __typename: "VoiceCallsPricing";
    id: string;
    fromCountryCode: string;
    toCountryCode: string;
    landlineOrMobile: LandlineOrMobile;
    vendor: Vendor;
    amountPerMinuteEuro: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetVoiceCallsPricingSchemeNetworkQuery = {
  __typename: "VoiceCallsPricingSchemeNetwork";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  default: boolean;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type ListVoiceCallsPricingSchemeNetworksQuery = {
  __typename: "ModelVoiceCallsPricingSchemeNetworkConnection";
  items: Array<{
    __typename: "VoiceCallsPricingSchemeNetwork";
    id: string;
    network: string;
    amountPerMinuteEuro: number;
    toCountryCode: string;
    regexFilter: string;
    default: boolean;
    vendor: Vendor;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VoiceCallsPricingSchemeNetworksByToCountryCodeAndNetworkQuery = {
  __typename: "ModelVoiceCallsPricingSchemeNetworkConnection";
  items: Array<{
    __typename: "VoiceCallsPricingSchemeNetwork";
    id: string;
    network: string;
    amountPerMinuteEuro: number;
    toCountryCode: string;
    regexFilter: string;
    default: boolean;
    vendor: Vendor;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VoiceCallsPricingSchemeNetworksByToCountryCodeAndRegexFilterQuery = {
  __typename: "ModelVoiceCallsPricingSchemeNetworkConnection";
  items: Array<{
    __typename: "VoiceCallsPricingSchemeNetwork";
    id: string;
    network: string;
    amountPerMinuteEuro: number;
    toCountryCode: string;
    regexFilter: string;
    default: boolean;
    vendor: Vendor;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetVoiceCallsPricingSchemeCountryQuery = {
  __typename: "VoiceCallsPricingSchemeCountry";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type ListVoiceCallsPricingSchemeCountriesQuery = {
  __typename: "ModelVoiceCallsPricingSchemeCountryConnection";
  items: Array<{
    __typename: "VoiceCallsPricingSchemeCountry";
    id: string;
    network: string;
    amountPerMinuteEuro: number;
    toCountryCode: string;
    regexFilter: string;
    vendor: Vendor;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VoiceCallsPricingSchemeCountriesByToCountryCodeAndNetworkQuery = {
  __typename: "ModelVoiceCallsPricingSchemeCountryConnection";
  items: Array<{
    __typename: "VoiceCallsPricingSchemeCountry";
    id: string;
    network: string;
    amountPerMinuteEuro: number;
    toCountryCode: string;
    regexFilter: string;
    vendor: Vendor;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetVoiceCallsBillingNetworkQuery = {
  __typename: "VoiceCallsBillingNetwork";
  id: string;
  customer: string;
  network: string;
  createdAt: string;
  updatedAt: string;
  minutes: number;
  operationalRPM: number;
  operationalRevenue: number;
};

export type ListVoiceCallsBillingNetworksQuery = {
  __typename: "ModelVoiceCallsBillingNetworkConnection";
  items: Array<{
    __typename: "VoiceCallsBillingNetwork";
    id: string;
    customer: string;
    network: string;
    createdAt: string;
    updatedAt: string;
    minutes: number;
    operationalRPM: number;
    operationalRevenue: number;
  } | null>;
  nextToken?: string | null;
};

export type VoiceCallsBillingNetworksByCustomerAndCreatedAtQuery = {
  __typename: "ModelVoiceCallsBillingNetworkConnection";
  items: Array<{
    __typename: "VoiceCallsBillingNetwork";
    id: string;
    customer: string;
    network: string;
    createdAt: string;
    updatedAt: string;
    minutes: number;
    operationalRPM: number;
    operationalRevenue: number;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateWalletSubscription = {
  __typename: "Wallet";
  id: string;
  address_id: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;
  createdAt: string;
  network: Network;
  updatedAt: string;
};

export type OnUpdateWalletSubscription = {
  __typename: "Wallet";
  id: string;
  address_id: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;
  createdAt: string;
  network: Network;
  updatedAt: string;
};

export type OnDeleteWalletSubscription = {
  __typename: "Wallet";
  id: string;
  address_id: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;
  createdAt: string;
  network: Network;
  updatedAt: string;
};

export type OnCreateContactSubscription = {
  __typename: "Contact";
  id: string;
  name: string;
  telephone: string;
  email?: string | null;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateContactSubscription = {
  __typename: "Contact";
  id: string;
  name: string;
  telephone: string;
  email?: string | null;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteContactSubscription = {
  __typename: "Contact";
  id: string;
  name: string;
  telephone: string;
  email?: string | null;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateEULASubscription = {
  __typename: "EULA";
  id: string;
  version: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateEULASubscription = {
  __typename: "EULA";
  id: string;
  version: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteEULASubscription = {
  __typename: "EULA";
  id: string;
  version: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallSubscription = {
  __typename: "Call";
  id: string;
  identifier: string;
  destinationNumber: string;
  direction: string;
  resource: string;
  owner: string;
  hangup: boolean;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt: string;
  state: string;
  note?: string | null;
  updatedAt: string;
};

export type OnUpdateCallSubscription = {
  __typename: "Call";
  id: string;
  identifier: string;
  destinationNumber: string;
  direction: string;
  resource: string;
  owner: string;
  hangup: boolean;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt: string;
  state: string;
  note?: string | null;
  updatedAt: string;
};

export type OnDeleteCallSubscription = {
  __typename: "Call";
  id: string;
  identifier: string;
  destinationNumber: string;
  direction: string;
  resource: string;
  owner: string;
  hangup: boolean;
  info?: string | null;
  about?: string | null;
  visible?: number | null;
  createdAt: string;
  state: string;
  note?: string | null;
  updatedAt: string;
};

export type OnCreateCallEventSubscription = {
  __typename: "CallEvent";
  id: string;
  call: string;
  event?: string | null;
  createdAt: string;
  owner?: string | null;
  updatedAt: string;
};

export type OnUpdateCallEventSubscription = {
  __typename: "CallEvent";
  id: string;
  call: string;
  event?: string | null;
  createdAt: string;
  owner?: string | null;
  updatedAt: string;
};

export type OnDeleteCallEventSubscription = {
  __typename: "CallEvent";
  id: string;
  call: string;
  event?: string | null;
  createdAt: string;
  owner?: string | null;
  updatedAt: string;
};

export type OnCreateTransactionSubscription = {
  __typename: "Transaction";
  id: string;
  resource: string;
  type: string;
  amount: number;
  source: Account;
  destination: Account;
  currency: Currency;
  owner: string;
  createdAt: string;
  info?: string | null;
  updatedAt: string;
};

export type OnUpdateTransactionSubscription = {
  __typename: "Transaction";
  id: string;
  resource: string;
  type: string;
  amount: number;
  source: Account;
  destination: Account;
  currency: Currency;
  owner: string;
  createdAt: string;
  info?: string | null;
  updatedAt: string;
};

export type OnDeleteTransactionSubscription = {
  __typename: "Transaction";
  id: string;
  resource: string;
  type: string;
  amount: number;
  source: Account;
  destination: Account;
  currency: Currency;
  owner: string;
  createdAt: string;
  info?: string | null;
  updatedAt: string;
};

export type OnCreateCountryAllowedSubscription = {
  __typename: "CountryAllowed";
  id: string;
  Country: string;
  Prefix: string;
  ISO3: string;
  ISO2?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCountryAllowedSubscription = {
  __typename: "CountryAllowed";
  id: string;
  Country: string;
  Prefix: string;
  ISO3: string;
  ISO2?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCountryAllowedSubscription = {
  __typename: "CountryAllowed";
  id: string;
  Country: string;
  Prefix: string;
  ISO3: string;
  ISO2?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTPVSubscription = {
  __typename: "TPV";
  id: string;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTPVSubscription = {
  __typename: "TPV";
  id: string;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTPVSubscription = {
  __typename: "TPV";
  id: string;
  domain?: string | null;
  environment?: string | null;
  app?: string | null;
  pageSize?: number | null;
  paginationSize?: number | null;
  entidadbancaria?: string | null;
  termsAndConditionsVersion?: string | null;
  transfersUrl?: string | null;
  transfersAccountURL?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateWhitelistSubscription = {
  __typename: "Whitelist";
  id: string;
  email: string;
  telephone?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateWhitelistSubscription = {
  __typename: "Whitelist";
  id: string;
  email: string;
  telephone?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteWhitelistSubscription = {
  __typename: "Whitelist";
  id: string;
  email: string;
  telephone?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateBannedSubscription = {
  __typename: "Banned";
  id: string;
  sub: string;
  reason: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateBannedSubscription = {
  __typename: "Banned";
  id: string;
  sub: string;
  reason: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteBannedSubscription = {
  __typename: "Banned";
  id: string;
  sub: string;
  reason: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateAiraloPackagesSubscription = {
  __typename: "AiraloPackages";
  id: string;
  country?: string | null;
  country_code?: string | null;
  id_pkg: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAiraloPackagesSubscription = {
  __typename: "AiraloPackages";
  id: string;
  country?: string | null;
  country_code?: string | null;
  id_pkg: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAiraloPackagesSubscription = {
  __typename: "AiraloPackages";
  id: string;
  country?: string | null;
  country_code?: string | null;
  id_pkg: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAiraloPricesSubscription = {
  __typename: "AiraloPrices";
  id: string;
  Country?: string | null;
  PackageId: string;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
  createdAt: string;
};

export type OnUpdateAiraloPricesSubscription = {
  __typename: "AiraloPrices";
  id: string;
  Country?: string | null;
  PackageId: string;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
  createdAt: string;
};

export type OnDeleteAiraloPricesSubscription = {
  __typename: "AiraloPrices";
  id: string;
  Country?: string | null;
  PackageId: string;
  type?: string | null;
  Netprice?: number | null;
  PartnerMinPrice?: number | null;
  ourPrice?: number | null;
  updatedAt?: string | null;
  createdAt: string;
};

export type OnCreateAiraloTokenSubscription = {
  __typename: "AiraloToken";
  id: string;
  token_type?: string | null;
  expires_in?: number | null;
  access_token: string;
  activo?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAiraloTokenSubscription = {
  __typename: "AiraloToken";
  id: string;
  token_type?: string | null;
  expires_in?: number | null;
  access_token: string;
  activo?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAiraloTokenSubscription = {
  __typename: "AiraloToken";
  id: string;
  token_type?: string | null;
  expires_in?: number | null;
  access_token: string;
  activo?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAiraloEsimOrdersSubscription = {
  __typename: "AiraloEsimOrders";
  id: string;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnUpdateAiraloEsimOrdersSubscription = {
  __typename: "AiraloEsimOrders";
  id: string;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnDeleteAiraloEsimOrdersSubscription = {
  __typename: "AiraloEsimOrders";
  id: string;
  owner?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnCreateAiraloTopUpOrdersSubscription = {
  __typename: "AiraloTopUpOrders";
  id: string;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnUpdateAiraloTopUpOrdersSubscription = {
  __typename: "AiraloTopUpOrders";
  id: string;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnDeleteAiraloTopUpOrdersSubscription = {
  __typename: "AiraloTopUpOrders";
  id: string;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnCreateAiraloUserEsimSubscription = {
  __typename: "AiraloUserEsim";
  id: string;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnUpdateAiraloUserEsimSubscription = {
  __typename: "AiraloUserEsim";
  id: string;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnDeleteAiraloUserEsimSubscription = {
  __typename: "AiraloUserEsim";
  id: string;
  active?: number | null;
  country?: string | null;
  iccid?: string | null;
  operator?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnCreateCredencialSubscription = {
  __typename: "Credencial";
  id: string;
  app: string;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnUpdateCredencialSubscription = {
  __typename: "Credencial";
  id: string;
  app: string;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnDeleteCredencialSubscription = {
  __typename: "Credencial";
  id: string;
  app: string;
  token_type?: string | null;
  token?: string | null;
  description?: number | null;
  activo?: number | null;
  createdAt?: string | null;
  updateAt?: string | null;
  updatedAt: string;
};

export type OnCreateVoiceCallsPricingSubscription = {
  __typename: "VoiceCallsPricing";
  id: string;
  fromCountryCode: string;
  toCountryCode: string;
  landlineOrMobile: LandlineOrMobile;
  vendor: Vendor;
  amountPerMinuteEuro: number;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateVoiceCallsPricingSubscription = {
  __typename: "VoiceCallsPricing";
  id: string;
  fromCountryCode: string;
  toCountryCode: string;
  landlineOrMobile: LandlineOrMobile;
  vendor: Vendor;
  amountPerMinuteEuro: number;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteVoiceCallsPricingSubscription = {
  __typename: "VoiceCallsPricing";
  id: string;
  fromCountryCode: string;
  toCountryCode: string;
  landlineOrMobile: LandlineOrMobile;
  vendor: Vendor;
  amountPerMinuteEuro: number;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateVoiceCallsPricingSchemeNetworkSubscription = {
  __typename: "VoiceCallsPricingSchemeNetwork";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  default: boolean;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateVoiceCallsPricingSchemeNetworkSubscription = {
  __typename: "VoiceCallsPricingSchemeNetwork";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  default: boolean;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteVoiceCallsPricingSchemeNetworkSubscription = {
  __typename: "VoiceCallsPricingSchemeNetwork";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  default: boolean;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateVoiceCallsPricingSchemeCountrySubscription = {
  __typename: "VoiceCallsPricingSchemeCountry";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateVoiceCallsPricingSchemeCountrySubscription = {
  __typename: "VoiceCallsPricingSchemeCountry";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteVoiceCallsPricingSchemeCountrySubscription = {
  __typename: "VoiceCallsPricingSchemeCountry";
  id: string;
  network: string;
  amountPerMinuteEuro: number;
  toCountryCode: string;
  regexFilter: string;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateVoiceCallsBillingNetworkSubscription = {
  __typename: "VoiceCallsBillingNetwork";
  id: string;
  customer: string;
  network: string;
  createdAt: string;
  updatedAt: string;
  minutes: number;
  operationalRPM: number;
  operationalRevenue: number;
};

export type OnUpdateVoiceCallsBillingNetworkSubscription = {
  __typename: "VoiceCallsBillingNetwork";
  id: string;
  customer: string;
  network: string;
  createdAt: string;
  updatedAt: string;
  minutes: number;
  operationalRPM: number;
  operationalRevenue: number;
};

export type OnDeleteVoiceCallsBillingNetworkSubscription = {
  __typename: "VoiceCallsBillingNetwork";
  id: string;
  customer: string;
  network: string;
  createdAt: string;
  updatedAt: string;
  minutes: number;
  operationalRPM: number;
  operationalRevenue: number;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async QueryWalletByEmail(email: string): Promise<QueryWalletByEmailQuery> {
    const statement = `query QueryWalletByEmail($email: String!) {
        walletsByEmailAndTelephone(email: $email) {
          __typename
          items {
            __typename
            id
            address_id
            address
            telephone
            email
            network
            createdAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      email
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QueryWalletByEmailQuery>response.data.walletsByEmailAndTelephone;
  }
  async QueryWalletByOwner(owner: string): Promise<QueryWalletByOwnerQuery> {
    const statement = `query QueryWalletByOwner($owner: String!) {
        walletsByOwnerAndEmail(owner: $owner) {
          __typename
          items {
            __typename
            id
            address_id
            address
            telephone
            email
            owner
            network
            createdAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QueryWalletByOwnerQuery>response.data.walletsByOwnerAndEmail;
  }
  async QueryContactByName(owner: string): Promise<QueryContactByNameQuery> {
    const statement = `query QueryContactByName($owner: String!) {
        contactsByOwnerAndName(owner: $owner) {
          __typename
          items {
            __typename
            id
            name
            telephone
            owner
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QueryContactByNameQuery>response.data.contactsByOwnerAndName;
  }
  async QueryCallByOwner(owner: string): Promise<QueryCallByOwnerQuery> {
    const statement = `query QueryCallByOwner($owner: String!) {
        callsByOwnerAndCreatedAt(owner: $owner) {
          __typename
          items {
            __typename
            id
            identifier
            destinationNumber
            direction
            resource
            owner
            hangup
            info
            visible
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QueryCallByOwnerQuery>response.data.callsByOwnerAndCreatedAt;
  }
  async QueryTransactionByOwner(
    owner: string
  ): Promise<QueryTransactionByOwnerQuery> {
    const statement = `query QueryTransactionByOwner($owner: String!) {
        transactionsByOwnerAndCreatedAt(owner: $owner) {
          __typename
          items {
            __typename
            id
            resource
            type
            amount
            source
            destination
            currency
            owner
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QueryTransactionByOwnerQuery>(
      response.data.transactionsByOwnerAndCreatedAt
    );
  }
  async QueryTransactionsByDestination(
    destination: Account
  ): Promise<QueryTransactionsByDestinationQuery> {
    const statement = `query QueryTransactionsByDestination($destination: Account!) {
        transactionsByDestinationAndOwner(destination: $destination) {
          __typename
          items {
            __typename
            id
            resource
            type
            amount
            source
            destination
            currency
            owner
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      destination
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QueryTransactionsByDestinationQuery>(
      response.data.transactionsByDestinationAndOwner
    );
  }
  async QueryVoiceCallsPricingsByFromCountryCode(
    toCountryCode: string
  ): Promise<QueryVoiceCallsPricingsByFromCountryCodeQuery> {
    const statement = `query QueryVoiceCallsPricingsByFromCountryCode($toCountryCode: String!) {
        voiceCallsPricingsByToCountryCodeAndFromCountryCode(
          toCountryCode: $toCountryCode
        ) {
          __typename
          items {
            __typename
            id
            fromCountryCode
            toCountryCode
            landlineOrMobile
            vendor
            amountPerMinuteEuro
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      toCountryCode
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QueryVoiceCallsPricingsByFromCountryCodeQuery>(
      response.data.voiceCallsPricingsByToCountryCodeAndFromCountryCode
    );
  }
  async CreateWallet(
    input: CreateWalletInput,
    condition?: ModelWalletConditionInput
  ): Promise<CreateWalletMutation> {
    const statement = `mutation CreateWallet($input: CreateWalletInput!, $condition: ModelWalletConditionInput) {
        createWallet(input: $input, condition: $condition) {
          __typename
          id
          address_id
          address
          telephone
          email
          owner
          createdAt
          network
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateWalletMutation>response.data.createWallet;
  }
  async UpdateWallet(
    input: UpdateWalletInput,
    condition?: ModelWalletConditionInput
  ): Promise<UpdateWalletMutation> {
    const statement = `mutation UpdateWallet($input: UpdateWalletInput!, $condition: ModelWalletConditionInput) {
        updateWallet(input: $input, condition: $condition) {
          __typename
          id
          address_id
          address
          telephone
          email
          owner
          createdAt
          network
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateWalletMutation>response.data.updateWallet;
  }
  async DeleteWallet(
    input: DeleteWalletInput,
    condition?: ModelWalletConditionInput
  ): Promise<DeleteWalletMutation> {
    const statement = `mutation DeleteWallet($input: DeleteWalletInput!, $condition: ModelWalletConditionInput) {
        deleteWallet(input: $input, condition: $condition) {
          __typename
          id
          address_id
          address
          telephone
          email
          owner
          createdAt
          network
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteWalletMutation>response.data.deleteWallet;
  }
  async CreateContact(
    input: CreateContactInput,
    condition?: ModelContactConditionInput
  ): Promise<CreateContactMutation> {
    const statement = `mutation CreateContact($input: CreateContactInput!, $condition: ModelContactConditionInput) {
        createContact(input: $input, condition: $condition) {
          __typename
          id
          name
          telephone
          email
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateContactMutation>response.data.createContact;
  }
  async UpdateContact(
    input: UpdateContactInput,
    condition?: ModelContactConditionInput
  ): Promise<UpdateContactMutation> {
    const statement = `mutation UpdateContact($input: UpdateContactInput!, $condition: ModelContactConditionInput) {
        updateContact(input: $input, condition: $condition) {
          __typename
          id
          name
          telephone
          email
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateContactMutation>response.data.updateContact;
  }
  async DeleteContact(
    input: DeleteContactInput,
    condition?: ModelContactConditionInput
  ): Promise<DeleteContactMutation> {
    const statement = `mutation DeleteContact($input: DeleteContactInput!, $condition: ModelContactConditionInput) {
        deleteContact(input: $input, condition: $condition) {
          __typename
          id
          name
          telephone
          email
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteContactMutation>response.data.deleteContact;
  }
  async CreateEULA(
    input: CreateEULAInput,
    condition?: ModelEULAConditionInput
  ): Promise<CreateEULAMutation> {
    const statement = `mutation CreateEULA($input: CreateEULAInput!, $condition: ModelEULAConditionInput) {
        createEULA(input: $input, condition: $condition) {
          __typename
          id
          version
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEULAMutation>response.data.createEULA;
  }
  async UpdateEULA(
    input: UpdateEULAInput,
    condition?: ModelEULAConditionInput
  ): Promise<UpdateEULAMutation> {
    const statement = `mutation UpdateEULA($input: UpdateEULAInput!, $condition: ModelEULAConditionInput) {
        updateEULA(input: $input, condition: $condition) {
          __typename
          id
          version
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEULAMutation>response.data.updateEULA;
  }
  async DeleteEULA(
    input: DeleteEULAInput,
    condition?: ModelEULAConditionInput
  ): Promise<DeleteEULAMutation> {
    const statement = `mutation DeleteEULA($input: DeleteEULAInput!, $condition: ModelEULAConditionInput) {
        deleteEULA(input: $input, condition: $condition) {
          __typename
          id
          version
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEULAMutation>response.data.deleteEULA;
  }
  async CreateCall(
    input: CreateCallInput,
    condition?: ModelCallConditionInput
  ): Promise<CreateCallMutation> {
    const statement = `mutation CreateCall($input: CreateCallInput!, $condition: ModelCallConditionInput) {
        createCall(input: $input, condition: $condition) {
          __typename
          id
          identifier
          destinationNumber
          direction
          resource
          owner
          hangup
          info
          about
          visible
          createdAt
          state
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallMutation>response.data.createCall;
  }
  async UpdateCall(
    input: UpdateCallInput,
    condition?: ModelCallConditionInput
  ): Promise<UpdateCallMutation> {
    const statement = `mutation UpdateCall($input: UpdateCallInput!, $condition: ModelCallConditionInput) {
        updateCall(input: $input, condition: $condition) {
          __typename
          id
          identifier
          destinationNumber
          direction
          resource
          owner
          hangup
          info
          about
          visible
          createdAt
          state
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallMutation>response.data.updateCall;
  }
  async DeleteCall(
    input: DeleteCallInput,
    condition?: ModelCallConditionInput
  ): Promise<DeleteCallMutation> {
    const statement = `mutation DeleteCall($input: DeleteCallInput!, $condition: ModelCallConditionInput) {
        deleteCall(input: $input, condition: $condition) {
          __typename
          id
          identifier
          destinationNumber
          direction
          resource
          owner
          hangup
          info
          about
          visible
          createdAt
          state
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallMutation>response.data.deleteCall;
  }
  async CreateCallEvent(
    input: CreateCallEventInput,
    condition?: ModelCallEventConditionInput
  ): Promise<CreateCallEventMutation> {
    const statement = `mutation CreateCallEvent($input: CreateCallEventInput!, $condition: ModelCallEventConditionInput) {
        createCallEvent(input: $input, condition: $condition) {
          __typename
          id
          call
          event
          createdAt
          owner
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallEventMutation>response.data.createCallEvent;
  }
  async UpdateCallEvent(
    input: UpdateCallEventInput,
    condition?: ModelCallEventConditionInput
  ): Promise<UpdateCallEventMutation> {
    const statement = `mutation UpdateCallEvent($input: UpdateCallEventInput!, $condition: ModelCallEventConditionInput) {
        updateCallEvent(input: $input, condition: $condition) {
          __typename
          id
          call
          event
          createdAt
          owner
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallEventMutation>response.data.updateCallEvent;
  }
  async DeleteCallEvent(
    input: DeleteCallEventInput,
    condition?: ModelCallEventConditionInput
  ): Promise<DeleteCallEventMutation> {
    const statement = `mutation DeleteCallEvent($input: DeleteCallEventInput!, $condition: ModelCallEventConditionInput) {
        deleteCallEvent(input: $input, condition: $condition) {
          __typename
          id
          call
          event
          createdAt
          owner
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallEventMutation>response.data.deleteCallEvent;
  }
  async CreateTransaction(
    input: CreateTransactionInput,
    condition?: ModelTransactionConditionInput
  ): Promise<CreateTransactionMutation> {
    const statement = `mutation CreateTransaction($input: CreateTransactionInput!, $condition: ModelTransactionConditionInput) {
        createTransaction(input: $input, condition: $condition) {
          __typename
          id
          resource
          type
          amount
          source
          destination
          currency
          owner
          createdAt
          info
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTransactionMutation>response.data.createTransaction;
  }
  async UpdateTransaction(
    input: UpdateTransactionInput,
    condition?: ModelTransactionConditionInput
  ): Promise<UpdateTransactionMutation> {
    const statement = `mutation UpdateTransaction($input: UpdateTransactionInput!, $condition: ModelTransactionConditionInput) {
        updateTransaction(input: $input, condition: $condition) {
          __typename
          id
          resource
          type
          amount
          source
          destination
          currency
          owner
          createdAt
          info
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTransactionMutation>response.data.updateTransaction;
  }
  async DeleteTransaction(
    input: DeleteTransactionInput,
    condition?: ModelTransactionConditionInput
  ): Promise<DeleteTransactionMutation> {
    const statement = `mutation DeleteTransaction($input: DeleteTransactionInput!, $condition: ModelTransactionConditionInput) {
        deleteTransaction(input: $input, condition: $condition) {
          __typename
          id
          resource
          type
          amount
          source
          destination
          currency
          owner
          createdAt
          info
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTransactionMutation>response.data.deleteTransaction;
  }
  async CreateVoiceCallsPricing(
    input: CreateVoiceCallsPricingInput,
    condition?: ModelVoiceCallsPricingConditionInput
  ): Promise<CreateVoiceCallsPricingMutation> {
    const statement = `mutation CreateVoiceCallsPricing($input: CreateVoiceCallsPricingInput!, $condition: ModelVoiceCallsPricingConditionInput) {
        createVoiceCallsPricing(input: $input, condition: $condition) {
          __typename
          id
          fromCountryCode
          toCountryCode
          landlineOrMobile
          vendor
          amountPerMinuteEuro
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVoiceCallsPricingMutation>(
      response.data.createVoiceCallsPricing
    );
  }
  async UpdateVoiceCallsPricing(
    input: UpdateVoiceCallsPricingInput,
    condition?: ModelVoiceCallsPricingConditionInput
  ): Promise<UpdateVoiceCallsPricingMutation> {
    const statement = `mutation UpdateVoiceCallsPricing($input: UpdateVoiceCallsPricingInput!, $condition: ModelVoiceCallsPricingConditionInput) {
        updateVoiceCallsPricing(input: $input, condition: $condition) {
          __typename
          id
          fromCountryCode
          toCountryCode
          landlineOrMobile
          vendor
          amountPerMinuteEuro
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVoiceCallsPricingMutation>(
      response.data.updateVoiceCallsPricing
    );
  }
  async DeleteVoiceCallsPricing(
    input: DeleteVoiceCallsPricingInput,
    condition?: ModelVoiceCallsPricingConditionInput
  ): Promise<DeleteVoiceCallsPricingMutation> {
    const statement = `mutation DeleteVoiceCallsPricing($input: DeleteVoiceCallsPricingInput!, $condition: ModelVoiceCallsPricingConditionInput) {
        deleteVoiceCallsPricing(input: $input, condition: $condition) {
          __typename
          id
          fromCountryCode
          toCountryCode
          landlineOrMobile
          vendor
          amountPerMinuteEuro
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVoiceCallsPricingMutation>(
      response.data.deleteVoiceCallsPricing
    );
  }
  async CreateVoiceCallsPricingSchemeNetwork(
    input: CreateVoiceCallsPricingSchemeNetworkInput,
    condition?: ModelVoiceCallsPricingSchemeNetworkConditionInput
  ): Promise<CreateVoiceCallsPricingSchemeNetworkMutation> {
    const statement = `mutation CreateVoiceCallsPricingSchemeNetwork($input: CreateVoiceCallsPricingSchemeNetworkInput!, $condition: ModelVoiceCallsPricingSchemeNetworkConditionInput) {
        createVoiceCallsPricingSchemeNetwork(input: $input, condition: $condition) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          default
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVoiceCallsPricingSchemeNetworkMutation>(
      response.data.createVoiceCallsPricingSchemeNetwork
    );
  }
  async UpdateVoiceCallsPricingSchemeNetwork(
    input: UpdateVoiceCallsPricingSchemeNetworkInput,
    condition?: ModelVoiceCallsPricingSchemeNetworkConditionInput
  ): Promise<UpdateVoiceCallsPricingSchemeNetworkMutation> {
    const statement = `mutation UpdateVoiceCallsPricingSchemeNetwork($input: UpdateVoiceCallsPricingSchemeNetworkInput!, $condition: ModelVoiceCallsPricingSchemeNetworkConditionInput) {
        updateVoiceCallsPricingSchemeNetwork(input: $input, condition: $condition) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          default
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVoiceCallsPricingSchemeNetworkMutation>(
      response.data.updateVoiceCallsPricingSchemeNetwork
    );
  }
  async DeleteVoiceCallsPricingSchemeNetwork(
    input: DeleteVoiceCallsPricingSchemeNetworkInput,
    condition?: ModelVoiceCallsPricingSchemeNetworkConditionInput
  ): Promise<DeleteVoiceCallsPricingSchemeNetworkMutation> {
    const statement = `mutation DeleteVoiceCallsPricingSchemeNetwork($input: DeleteVoiceCallsPricingSchemeNetworkInput!, $condition: ModelVoiceCallsPricingSchemeNetworkConditionInput) {
        deleteVoiceCallsPricingSchemeNetwork(input: $input, condition: $condition) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          default
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVoiceCallsPricingSchemeNetworkMutation>(
      response.data.deleteVoiceCallsPricingSchemeNetwork
    );
  }
  async CreateVoiceCallsPricingSchemeCountry(
    input: CreateVoiceCallsPricingSchemeCountryInput,
    condition?: ModelVoiceCallsPricingSchemeCountryConditionInput
  ): Promise<CreateVoiceCallsPricingSchemeCountryMutation> {
    const statement = `mutation CreateVoiceCallsPricingSchemeCountry($input: CreateVoiceCallsPricingSchemeCountryInput!, $condition: ModelVoiceCallsPricingSchemeCountryConditionInput) {
        createVoiceCallsPricingSchemeCountry(input: $input, condition: $condition) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVoiceCallsPricingSchemeCountryMutation>(
      response.data.createVoiceCallsPricingSchemeCountry
    );
  }
  async UpdateVoiceCallsPricingSchemeCountry(
    input: UpdateVoiceCallsPricingSchemeCountryInput,
    condition?: ModelVoiceCallsPricingSchemeCountryConditionInput
  ): Promise<UpdateVoiceCallsPricingSchemeCountryMutation> {
    const statement = `mutation UpdateVoiceCallsPricingSchemeCountry($input: UpdateVoiceCallsPricingSchemeCountryInput!, $condition: ModelVoiceCallsPricingSchemeCountryConditionInput) {
        updateVoiceCallsPricingSchemeCountry(input: $input, condition: $condition) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVoiceCallsPricingSchemeCountryMutation>(
      response.data.updateVoiceCallsPricingSchemeCountry
    );
  }
  async DeleteVoiceCallsPricingSchemeCountry(
    input: DeleteVoiceCallsPricingSchemeCountryInput,
    condition?: ModelVoiceCallsPricingSchemeCountryConditionInput
  ): Promise<DeleteVoiceCallsPricingSchemeCountryMutation> {
    const statement = `mutation DeleteVoiceCallsPricingSchemeCountry($input: DeleteVoiceCallsPricingSchemeCountryInput!, $condition: ModelVoiceCallsPricingSchemeCountryConditionInput) {
        deleteVoiceCallsPricingSchemeCountry(input: $input, condition: $condition) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVoiceCallsPricingSchemeCountryMutation>(
      response.data.deleteVoiceCallsPricingSchemeCountry
    );
  }
  async CreateVoiceCallsBillingNetwork(
    input: CreateVoiceCallsBillingNetworkInput,
    condition?: ModelVoiceCallsBillingNetworkConditionInput
  ): Promise<CreateVoiceCallsBillingNetworkMutation> {
    const statement = `mutation CreateVoiceCallsBillingNetwork($input: CreateVoiceCallsBillingNetworkInput!, $condition: ModelVoiceCallsBillingNetworkConditionInput) {
        createVoiceCallsBillingNetwork(input: $input, condition: $condition) {
          __typename
          id
          customer
          network
          createdAt
          updatedAt
          minutes
          operationalRPM
          operationalRevenue
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVoiceCallsBillingNetworkMutation>(
      response.data.createVoiceCallsBillingNetwork
    );
  }
  async UpdateVoiceCallsBillingNetwork(
    input: UpdateVoiceCallsBillingNetworkInput,
    condition?: ModelVoiceCallsBillingNetworkConditionInput
  ): Promise<UpdateVoiceCallsBillingNetworkMutation> {
    const statement = `mutation UpdateVoiceCallsBillingNetwork($input: UpdateVoiceCallsBillingNetworkInput!, $condition: ModelVoiceCallsBillingNetworkConditionInput) {
        updateVoiceCallsBillingNetwork(input: $input, condition: $condition) {
          __typename
          id
          customer
          network
          createdAt
          updatedAt
          minutes
          operationalRPM
          operationalRevenue
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVoiceCallsBillingNetworkMutation>(
      response.data.updateVoiceCallsBillingNetwork
    );
  }
  async DeleteVoiceCallsBillingNetwork(
    input: DeleteVoiceCallsBillingNetworkInput,
    condition?: ModelVoiceCallsBillingNetworkConditionInput
  ): Promise<DeleteVoiceCallsBillingNetworkMutation> {
    const statement = `mutation DeleteVoiceCallsBillingNetwork($input: DeleteVoiceCallsBillingNetworkInput!, $condition: ModelVoiceCallsBillingNetworkConditionInput) {
        deleteVoiceCallsBillingNetwork(input: $input, condition: $condition) {
          __typename
          id
          customer
          network
          createdAt
          updatedAt
          minutes
          operationalRPM
          operationalRevenue
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVoiceCallsBillingNetworkMutation>(
      response.data.deleteVoiceCallsBillingNetwork
    );
  }
  async CreateCountryAllowed(
    input: CreateCountryAllowedInput,
    condition?: ModelCountryAllowedConditionInput
  ): Promise<CreateCountryAllowedMutation> {
    const statement = `mutation CreateCountryAllowed($input: CreateCountryAllowedInput!, $condition: ModelCountryAllowedConditionInput) {
        createCountryAllowed(input: $input, condition: $condition) {
          __typename
          id
          Country
          Prefix
          ISO3
          ISO2
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCountryAllowedMutation>response.data.createCountryAllowed;
  }
  async UpdateCountryAllowed(
    input: UpdateCountryAllowedInput,
    condition?: ModelCountryAllowedConditionInput
  ): Promise<UpdateCountryAllowedMutation> {
    const statement = `mutation UpdateCountryAllowed($input: UpdateCountryAllowedInput!, $condition: ModelCountryAllowedConditionInput) {
        updateCountryAllowed(input: $input, condition: $condition) {
          __typename
          id
          Country
          Prefix
          ISO3
          ISO2
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCountryAllowedMutation>response.data.updateCountryAllowed;
  }
  async DeleteCountryAllowed(
    input: DeleteCountryAllowedInput,
    condition?: ModelCountryAllowedConditionInput
  ): Promise<DeleteCountryAllowedMutation> {
    const statement = `mutation DeleteCountryAllowed($input: DeleteCountryAllowedInput!, $condition: ModelCountryAllowedConditionInput) {
        deleteCountryAllowed(input: $input, condition: $condition) {
          __typename
          id
          Country
          Prefix
          ISO3
          ISO2
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCountryAllowedMutation>response.data.deleteCountryAllowed;
  }
  async CreateTPV(
    input: CreateTPVInput,
    condition?: ModelTPVConditionInput
  ): Promise<CreateTPVMutation> {
    const statement = `mutation CreateTPV($input: CreateTPVInput!, $condition: ModelTPVConditionInput) {
        createTPV(input: $input, condition: $condition) {
          __typename
          id
          domain
          environment
          app
          pageSize
          paginationSize
          entidadbancaria
          termsAndConditionsVersion
          transfersUrl
          transfersAccountURL
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTPVMutation>response.data.createTPV;
  }
  async UpdateTPV(
    input: UpdateTPVInput,
    condition?: ModelTPVConditionInput
  ): Promise<UpdateTPVMutation> {
    const statement = `mutation UpdateTPV($input: UpdateTPVInput!, $condition: ModelTPVConditionInput) {
        updateTPV(input: $input, condition: $condition) {
          __typename
          id
          domain
          environment
          app
          pageSize
          paginationSize
          entidadbancaria
          termsAndConditionsVersion
          transfersUrl
          transfersAccountURL
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTPVMutation>response.data.updateTPV;
  }
  async DeleteTPV(
    input: DeleteTPVInput,
    condition?: ModelTPVConditionInput
  ): Promise<DeleteTPVMutation> {
    const statement = `mutation DeleteTPV($input: DeleteTPVInput!, $condition: ModelTPVConditionInput) {
        deleteTPV(input: $input, condition: $condition) {
          __typename
          id
          domain
          environment
          app
          pageSize
          paginationSize
          entidadbancaria
          termsAndConditionsVersion
          transfersUrl
          transfersAccountURL
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTPVMutation>response.data.deleteTPV;
  }
  async CreateWhitelist(
    input: CreateWhitelistInput,
    condition?: ModelWhitelistConditionInput
  ): Promise<CreateWhitelistMutation> {
    const statement = `mutation CreateWhitelist($input: CreateWhitelistInput!, $condition: ModelWhitelistConditionInput) {
        createWhitelist(input: $input, condition: $condition) {
          __typename
          id
          email
          telephone
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateWhitelistMutation>response.data.createWhitelist;
  }
  async UpdateWhitelist(
    input: UpdateWhitelistInput,
    condition?: ModelWhitelistConditionInput
  ): Promise<UpdateWhitelistMutation> {
    const statement = `mutation UpdateWhitelist($input: UpdateWhitelistInput!, $condition: ModelWhitelistConditionInput) {
        updateWhitelist(input: $input, condition: $condition) {
          __typename
          id
          email
          telephone
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateWhitelistMutation>response.data.updateWhitelist;
  }
  async DeleteWhitelist(
    input: DeleteWhitelistInput,
    condition?: ModelWhitelistConditionInput
  ): Promise<DeleteWhitelistMutation> {
    const statement = `mutation DeleteWhitelist($input: DeleteWhitelistInput!, $condition: ModelWhitelistConditionInput) {
        deleteWhitelist(input: $input, condition: $condition) {
          __typename
          id
          email
          telephone
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteWhitelistMutation>response.data.deleteWhitelist;
  }
  async CreateBanned(
    input: CreateBannedInput,
    condition?: ModelBannedConditionInput
  ): Promise<CreateBannedMutation> {
    const statement = `mutation CreateBanned($input: CreateBannedInput!, $condition: ModelBannedConditionInput) {
        createBanned(input: $input, condition: $condition) {
          __typename
          id
          sub
          reason
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBannedMutation>response.data.createBanned;
  }
  async UpdateBanned(
    input: UpdateBannedInput,
    condition?: ModelBannedConditionInput
  ): Promise<UpdateBannedMutation> {
    const statement = `mutation UpdateBanned($input: UpdateBannedInput!, $condition: ModelBannedConditionInput) {
        updateBanned(input: $input, condition: $condition) {
          __typename
          id
          sub
          reason
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBannedMutation>response.data.updateBanned;
  }
  async DeleteBanned(
    input: DeleteBannedInput,
    condition?: ModelBannedConditionInput
  ): Promise<DeleteBannedMutation> {
    const statement = `mutation DeleteBanned($input: DeleteBannedInput!, $condition: ModelBannedConditionInput) {
        deleteBanned(input: $input, condition: $condition) {
          __typename
          id
          sub
          reason
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBannedMutation>response.data.deleteBanned;
  }
  async CreateAiraloPackages(
    input: CreateAiraloPackagesInput,
    condition?: ModelAiraloPackagesConditionInput
  ): Promise<CreateAiraloPackagesMutation> {
    const statement = `mutation CreateAiraloPackages($input: CreateAiraloPackagesInput!, $condition: ModelAiraloPackagesConditionInput) {
        createAiraloPackages(input: $input, condition: $condition) {
          __typename
          id
          country
          country_code
          id_pkg
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAiraloPackagesMutation>response.data.createAiraloPackages;
  }
  async UpdateAiraloPackages(
    input: UpdateAiraloPackagesInput,
    condition?: ModelAiraloPackagesConditionInput
  ): Promise<UpdateAiraloPackagesMutation> {
    const statement = `mutation UpdateAiraloPackages($input: UpdateAiraloPackagesInput!, $condition: ModelAiraloPackagesConditionInput) {
        updateAiraloPackages(input: $input, condition: $condition) {
          __typename
          id
          country
          country_code
          id_pkg
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAiraloPackagesMutation>response.data.updateAiraloPackages;
  }
  async DeleteAiraloPackages(
    input: DeleteAiraloPackagesInput,
    condition?: ModelAiraloPackagesConditionInput
  ): Promise<DeleteAiraloPackagesMutation> {
    const statement = `mutation DeleteAiraloPackages($input: DeleteAiraloPackagesInput!, $condition: ModelAiraloPackagesConditionInput) {
        deleteAiraloPackages(input: $input, condition: $condition) {
          __typename
          id
          country
          country_code
          id_pkg
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAiraloPackagesMutation>response.data.deleteAiraloPackages;
  }
  async CreateAiraloPrices(
    input: CreateAiraloPricesInput,
    condition?: ModelAiraloPricesConditionInput
  ): Promise<CreateAiraloPricesMutation> {
    const statement = `mutation CreateAiraloPrices($input: CreateAiraloPricesInput!, $condition: ModelAiraloPricesConditionInput) {
        createAiraloPrices(input: $input, condition: $condition) {
          __typename
          id
          Country
          PackageId
          type
          Netprice
          PartnerMinPrice
          ourPrice
          updatedAt
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAiraloPricesMutation>response.data.createAiraloPrices;
  }
  async UpdateAiraloPrices(
    input: UpdateAiraloPricesInput,
    condition?: ModelAiraloPricesConditionInput
  ): Promise<UpdateAiraloPricesMutation> {
    const statement = `mutation UpdateAiraloPrices($input: UpdateAiraloPricesInput!, $condition: ModelAiraloPricesConditionInput) {
        updateAiraloPrices(input: $input, condition: $condition) {
          __typename
          id
          Country
          PackageId
          type
          Netprice
          PartnerMinPrice
          ourPrice
          updatedAt
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAiraloPricesMutation>response.data.updateAiraloPrices;
  }
  async DeleteAiraloPrices(
    input: DeleteAiraloPricesInput,
    condition?: ModelAiraloPricesConditionInput
  ): Promise<DeleteAiraloPricesMutation> {
    const statement = `mutation DeleteAiraloPrices($input: DeleteAiraloPricesInput!, $condition: ModelAiraloPricesConditionInput) {
        deleteAiraloPrices(input: $input, condition: $condition) {
          __typename
          id
          Country
          PackageId
          type
          Netprice
          PartnerMinPrice
          ourPrice
          updatedAt
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAiraloPricesMutation>response.data.deleteAiraloPrices;
  }
  async CreateAiraloToken(
    input: CreateAiraloTokenInput,
    condition?: ModelAiraloTokenConditionInput
  ): Promise<CreateAiraloTokenMutation> {
    const statement = `mutation CreateAiraloToken($input: CreateAiraloTokenInput!, $condition: ModelAiraloTokenConditionInput) {
        createAiraloToken(input: $input, condition: $condition) {
          __typename
          id
          token_type
          expires_in
          access_token
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAiraloTokenMutation>response.data.createAiraloToken;
  }
  async UpdateAiraloToken(
    input: UpdateAiraloTokenInput,
    condition?: ModelAiraloTokenConditionInput
  ): Promise<UpdateAiraloTokenMutation> {
    const statement = `mutation UpdateAiraloToken($input: UpdateAiraloTokenInput!, $condition: ModelAiraloTokenConditionInput) {
        updateAiraloToken(input: $input, condition: $condition) {
          __typename
          id
          token_type
          expires_in
          access_token
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAiraloTokenMutation>response.data.updateAiraloToken;
  }
  async DeleteAiraloToken(
    input: DeleteAiraloTokenInput,
    condition?: ModelAiraloTokenConditionInput
  ): Promise<DeleteAiraloTokenMutation> {
    const statement = `mutation DeleteAiraloToken($input: DeleteAiraloTokenInput!, $condition: ModelAiraloTokenConditionInput) {
        deleteAiraloToken(input: $input, condition: $condition) {
          __typename
          id
          token_type
          expires_in
          access_token
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAiraloTokenMutation>response.data.deleteAiraloToken;
  }
  async CreateAiraloEsimOrders(
    input: CreateAiraloEsimOrdersInput,
    condition?: ModelAiraloEsimOrdersConditionInput
  ): Promise<CreateAiraloEsimOrdersMutation> {
    const statement = `mutation CreateAiraloEsimOrders($input: CreateAiraloEsimOrdersInput!, $condition: ModelAiraloEsimOrdersConditionInput) {
        createAiraloEsimOrders(input: $input, condition: $condition) {
          __typename
          id
          owner
          type
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAiraloEsimOrdersMutation>response.data.createAiraloEsimOrders;
  }
  async UpdateAiraloEsimOrders(
    input: UpdateAiraloEsimOrdersInput,
    condition?: ModelAiraloEsimOrdersConditionInput
  ): Promise<UpdateAiraloEsimOrdersMutation> {
    const statement = `mutation UpdateAiraloEsimOrders($input: UpdateAiraloEsimOrdersInput!, $condition: ModelAiraloEsimOrdersConditionInput) {
        updateAiraloEsimOrders(input: $input, condition: $condition) {
          __typename
          id
          owner
          type
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAiraloEsimOrdersMutation>response.data.updateAiraloEsimOrders;
  }
  async DeleteAiraloEsimOrders(
    input: DeleteAiraloEsimOrdersInput,
    condition?: ModelAiraloEsimOrdersConditionInput
  ): Promise<DeleteAiraloEsimOrdersMutation> {
    const statement = `mutation DeleteAiraloEsimOrders($input: DeleteAiraloEsimOrdersInput!, $condition: ModelAiraloEsimOrdersConditionInput) {
        deleteAiraloEsimOrders(input: $input, condition: $condition) {
          __typename
          id
          owner
          type
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAiraloEsimOrdersMutation>response.data.deleteAiraloEsimOrders;
  }
  async CreateAiraloTopUpOrders(
    input: CreateAiraloTopUpOrdersInput,
    condition?: ModelAiraloTopUpOrdersConditionInput
  ): Promise<CreateAiraloTopUpOrdersMutation> {
    const statement = `mutation CreateAiraloTopUpOrders($input: CreateAiraloTopUpOrdersInput!, $condition: ModelAiraloTopUpOrdersConditionInput) {
        createAiraloTopUpOrders(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAiraloTopUpOrdersMutation>(
      response.data.createAiraloTopUpOrders
    );
  }
  async UpdateAiraloTopUpOrders(
    input: UpdateAiraloTopUpOrdersInput,
    condition?: ModelAiraloTopUpOrdersConditionInput
  ): Promise<UpdateAiraloTopUpOrdersMutation> {
    const statement = `mutation UpdateAiraloTopUpOrders($input: UpdateAiraloTopUpOrdersInput!, $condition: ModelAiraloTopUpOrdersConditionInput) {
        updateAiraloTopUpOrders(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAiraloTopUpOrdersMutation>(
      response.data.updateAiraloTopUpOrders
    );
  }
  async DeleteAiraloTopUpOrders(
    input: DeleteAiraloTopUpOrdersInput,
    condition?: ModelAiraloTopUpOrdersConditionInput
  ): Promise<DeleteAiraloTopUpOrdersMutation> {
    const statement = `mutation DeleteAiraloTopUpOrders($input: DeleteAiraloTopUpOrdersInput!, $condition: ModelAiraloTopUpOrdersConditionInput) {
        deleteAiraloTopUpOrders(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAiraloTopUpOrdersMutation>(
      response.data.deleteAiraloTopUpOrders
    );
  }
  async CreateAiraloUserEsim(
    input: CreateAiraloUserEsimInput,
    condition?: ModelAiraloUserEsimConditionInput
  ): Promise<CreateAiraloUserEsimMutation> {
    const statement = `mutation CreateAiraloUserEsim($input: CreateAiraloUserEsimInput!, $condition: ModelAiraloUserEsimConditionInput) {
        createAiraloUserEsim(input: $input, condition: $condition) {
          __typename
          id
          active
          country
          iccid
          operator
          owner
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAiraloUserEsimMutation>response.data.createAiraloUserEsim;
  }
  async UpdateAiraloUserEsim(
    input: UpdateAiraloUserEsimInput,
    condition?: ModelAiraloUserEsimConditionInput
  ): Promise<UpdateAiraloUserEsimMutation> {
    const statement = `mutation UpdateAiraloUserEsim($input: UpdateAiraloUserEsimInput!, $condition: ModelAiraloUserEsimConditionInput) {
        updateAiraloUserEsim(input: $input, condition: $condition) {
          __typename
          id
          active
          country
          iccid
          operator
          owner
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAiraloUserEsimMutation>response.data.updateAiraloUserEsim;
  }
  async DeleteAiraloUserEsim(
    input: DeleteAiraloUserEsimInput,
    condition?: ModelAiraloUserEsimConditionInput
  ): Promise<DeleteAiraloUserEsimMutation> {
    const statement = `mutation DeleteAiraloUserEsim($input: DeleteAiraloUserEsimInput!, $condition: ModelAiraloUserEsimConditionInput) {
        deleteAiraloUserEsim(input: $input, condition: $condition) {
          __typename
          id
          active
          country
          iccid
          operator
          owner
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAiraloUserEsimMutation>response.data.deleteAiraloUserEsim;
  }
  async CreateCredencial(
    input: CreateCredencialInput,
    condition?: ModelCredencialConditionInput
  ): Promise<CreateCredencialMutation> {
    const statement = `mutation CreateCredencial($input: CreateCredencialInput!, $condition: ModelCredencialConditionInput) {
        createCredencial(input: $input, condition: $condition) {
          __typename
          id
          app
          token_type
          token
          description
          activo
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCredencialMutation>response.data.createCredencial;
  }
  async UpdateCredencial(
    input: UpdateCredencialInput,
    condition?: ModelCredencialConditionInput
  ): Promise<UpdateCredencialMutation> {
    const statement = `mutation UpdateCredencial($input: UpdateCredencialInput!, $condition: ModelCredencialConditionInput) {
        updateCredencial(input: $input, condition: $condition) {
          __typename
          id
          app
          token_type
          token
          description
          activo
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCredencialMutation>response.data.updateCredencial;
  }
  async DeleteCredencial(
    input: DeleteCredencialInput,
    condition?: ModelCredencialConditionInput
  ): Promise<DeleteCredencialMutation> {
    const statement = `mutation DeleteCredencial($input: DeleteCredencialInput!, $condition: ModelCredencialConditionInput) {
        deleteCredencial(input: $input, condition: $condition) {
          __typename
          id
          app
          token_type
          token
          description
          activo
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCredencialMutation>response.data.deleteCredencial;
  }
  async GetWallet(id: string): Promise<GetWalletQuery> {
    const statement = `query GetWallet($id: ID!) {
        getWallet(id: $id) {
          __typename
          id
          address_id
          address
          telephone
          email
          owner
          createdAt
          network
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWalletQuery>response.data.getWallet;
  }
  async ListWallets(
    filter?: ModelWalletFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWalletsQuery> {
    const statement = `query ListWallets($filter: ModelWalletFilterInput, $limit: Int, $nextToken: String) {
        listWallets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            address_id
            address
            telephone
            email
            owner
            createdAt
            network
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWalletsQuery>response.data.listWallets;
  }
  async GetContact(id: string): Promise<GetContactQuery> {
    const statement = `query GetContact($id: ID!) {
        getContact(id: $id) {
          __typename
          id
          name
          telephone
          email
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetContactQuery>response.data.getContact;
  }
  async ListContacts(
    filter?: ModelContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContactsQuery> {
    const statement = `query ListContacts($filter: ModelContactFilterInput, $limit: Int, $nextToken: String) {
        listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            telephone
            email
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContactsQuery>response.data.listContacts;
  }
  async GetEULA(id: string): Promise<GetEULAQuery> {
    const statement = `query GetEULA($id: ID!) {
        getEULA(id: $id) {
          __typename
          id
          version
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEULAQuery>response.data.getEULA;
  }
  async ListEULAS(
    filter?: ModelEULAFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEULASQuery> {
    const statement = `query ListEULAS($filter: ModelEULAFilterInput, $limit: Int, $nextToken: String) {
        listEULAS(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            version
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEULASQuery>response.data.listEULAS;
  }
  async GetCall(id: string): Promise<GetCallQuery> {
    const statement = `query GetCall($id: ID!) {
        getCall(id: $id) {
          __typename
          id
          identifier
          destinationNumber
          direction
          resource
          owner
          hangup
          info
          about
          visible
          createdAt
          state
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallQuery>response.data.getCall;
  }
  async ListCalls(
    filter?: ModelCallFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallsQuery> {
    const statement = `query ListCalls($filter: ModelCallFilterInput, $limit: Int, $nextToken: String) {
        listCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            identifier
            destinationNumber
            direction
            resource
            owner
            hangup
            info
            about
            visible
            createdAt
            state
            note
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallsQuery>response.data.listCalls;
  }
  async GetCallEvent(id: string): Promise<GetCallEventQuery> {
    const statement = `query GetCallEvent($id: ID!) {
        getCallEvent(id: $id) {
          __typename
          id
          call
          event
          createdAt
          owner
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallEventQuery>response.data.getCallEvent;
  }
  async ListCallEvents(
    filter?: ModelCallEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallEventsQuery> {
    const statement = `query ListCallEvents($filter: ModelCallEventFilterInput, $limit: Int, $nextToken: String) {
        listCallEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            call
            event
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallEventsQuery>response.data.listCallEvents;
  }
  async GetTransaction(id: string): Promise<GetTransactionQuery> {
    const statement = `query GetTransaction($id: ID!) {
        getTransaction(id: $id) {
          __typename
          id
          resource
          type
          amount
          source
          destination
          currency
          owner
          createdAt
          info
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTransactionQuery>response.data.getTransaction;
  }
  async ListTransactions(
    filter?: ModelTransactionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTransactionsQuery> {
    const statement = `query ListTransactions($filter: ModelTransactionFilterInput, $limit: Int, $nextToken: String) {
        listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            resource
            type
            amount
            source
            destination
            currency
            owner
            createdAt
            info
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTransactionsQuery>response.data.listTransactions;
  }
  async GetCountryAllowed(id: string): Promise<GetCountryAllowedQuery> {
    const statement = `query GetCountryAllowed($id: ID!) {
        getCountryAllowed(id: $id) {
          __typename
          id
          Country
          Prefix
          ISO3
          ISO2
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCountryAllowedQuery>response.data.getCountryAllowed;
  }
  async ListCountryAlloweds(
    filter?: ModelCountryAllowedFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCountryAllowedsQuery> {
    const statement = `query ListCountryAlloweds($filter: ModelCountryAllowedFilterInput, $limit: Int, $nextToken: String) {
        listCountryAlloweds(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            Country
            Prefix
            ISO3
            ISO2
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCountryAllowedsQuery>response.data.listCountryAlloweds;
  }
  async GetTPV(id: string): Promise<GetTPVQuery> {
    const statement = `query GetTPV($id: ID!) {
        getTPV(id: $id) {
          __typename
          id
          domain
          environment
          app
          pageSize
          paginationSize
          entidadbancaria
          termsAndConditionsVersion
          transfersUrl
          transfersAccountURL
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTPVQuery>response.data.getTPV;
  }
  async ListTPVS(
    id?: string,
    filter?: ModelTPVFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListTPVSQuery> {
    const statement = `query ListTPVS($id: ID, $filter: ModelTPVFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listTPVS(
          id: $id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            id
            domain
            environment
            app
            pageSize
            paginationSize
            entidadbancaria
            termsAndConditionsVersion
            transfersUrl
            transfersAccountURL
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTPVSQuery>response.data.listTPVS;
  }
  async GetWhitelist(id: string): Promise<GetWhitelistQuery> {
    const statement = `query GetWhitelist($id: ID!) {
        getWhitelist(id: $id) {
          __typename
          id
          email
          telephone
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWhitelistQuery>response.data.getWhitelist;
  }
  async ListWhitelists(
    filter?: ModelWhitelistFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWhitelistsQuery> {
    const statement = `query ListWhitelists($filter: ModelWhitelistFilterInput, $limit: Int, $nextToken: String) {
        listWhitelists(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            email
            telephone
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWhitelistsQuery>response.data.listWhitelists;
  }
  async GetBanned(id: string): Promise<GetBannedQuery> {
    const statement = `query GetBanned($id: ID!) {
        getBanned(id: $id) {
          __typename
          id
          sub
          reason
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBannedQuery>response.data.getBanned;
  }
  async ListBanneds(
    filter?: ModelBannedFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBannedsQuery> {
    const statement = `query ListBanneds($filter: ModelBannedFilterInput, $limit: Int, $nextToken: String) {
        listBanneds(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            sub
            reason
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBannedsQuery>response.data.listBanneds;
  }
  async GetAiraloPackages(id: string): Promise<GetAiraloPackagesQuery> {
    const statement = `query GetAiraloPackages($id: ID!) {
        getAiraloPackages(id: $id) {
          __typename
          id
          country
          country_code
          id_pkg
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAiraloPackagesQuery>response.data.getAiraloPackages;
  }
  async ListAiraloPackages(
    filter?: ModelAiraloPackagesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAiraloPackagesQuery> {
    const statement = `query ListAiraloPackages($filter: ModelAiraloPackagesFilterInput, $limit: Int, $nextToken: String) {
        listAiraloPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            country
            country_code
            id_pkg
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAiraloPackagesQuery>response.data.listAiraloPackages;
  }
  async GetAiraloPrices(id: string): Promise<GetAiraloPricesQuery> {
    const statement = `query GetAiraloPrices($id: ID!) {
        getAiraloPrices(id: $id) {
          __typename
          id
          Country
          PackageId
          type
          Netprice
          PartnerMinPrice
          ourPrice
          updatedAt
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAiraloPricesQuery>response.data.getAiraloPrices;
  }
  async ListAiraloPrices(
    filter?: ModelAiraloPricesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAiraloPricesQuery> {
    const statement = `query ListAiraloPrices($filter: ModelAiraloPricesFilterInput, $limit: Int, $nextToken: String) {
        listAiraloPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            Country
            PackageId
            type
            Netprice
            PartnerMinPrice
            ourPrice
            updatedAt
            createdAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAiraloPricesQuery>response.data.listAiraloPrices;
  }
  async GetAiraloToken(id: string): Promise<GetAiraloTokenQuery> {
    const statement = `query GetAiraloToken($id: ID!) {
        getAiraloToken(id: $id) {
          __typename
          id
          token_type
          expires_in
          access_token
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAiraloTokenQuery>response.data.getAiraloToken;
  }
  async ListAiraloTokens(
    filter?: ModelAiraloTokenFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAiraloTokensQuery> {
    const statement = `query ListAiraloTokens($filter: ModelAiraloTokenFilterInput, $limit: Int, $nextToken: String) {
        listAiraloTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            token_type
            expires_in
            access_token
            activo
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAiraloTokensQuery>response.data.listAiraloTokens;
  }
  async GetAiraloEsimOrders(id: string): Promise<GetAiraloEsimOrdersQuery> {
    const statement = `query GetAiraloEsimOrders($id: ID!) {
        getAiraloEsimOrders(id: $id) {
          __typename
          id
          owner
          type
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAiraloEsimOrdersQuery>response.data.getAiraloEsimOrders;
  }
  async ListAiraloEsimOrders(
    filter?: ModelAiraloEsimOrdersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAiraloEsimOrdersQuery> {
    const statement = `query ListAiraloEsimOrders($filter: ModelAiraloEsimOrdersFilterInput, $limit: Int, $nextToken: String) {
        listAiraloEsimOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            owner
            type
            createdAt
            updateAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAiraloEsimOrdersQuery>response.data.listAiraloEsimOrders;
  }
  async GetAiraloTopUpOrders(id: string): Promise<GetAiraloTopUpOrdersQuery> {
    const statement = `query GetAiraloTopUpOrders($id: ID!) {
        getAiraloTopUpOrders(id: $id) {
          __typename
          id
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAiraloTopUpOrdersQuery>response.data.getAiraloTopUpOrders;
  }
  async ListAiraloTopUpOrders(
    filter?: ModelAiraloTopUpOrdersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAiraloTopUpOrdersQuery> {
    const statement = `query ListAiraloTopUpOrders($filter: ModelAiraloTopUpOrdersFilterInput, $limit: Int, $nextToken: String) {
        listAiraloTopUpOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            updateAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAiraloTopUpOrdersQuery>response.data.listAiraloTopUpOrders;
  }
  async GetAiraloUserEsim(id: string): Promise<GetAiraloUserEsimQuery> {
    const statement = `query GetAiraloUserEsim($id: ID!) {
        getAiraloUserEsim(id: $id) {
          __typename
          id
          active
          country
          iccid
          operator
          owner
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAiraloUserEsimQuery>response.data.getAiraloUserEsim;
  }
  async ListAiraloUserEsims(
    filter?: ModelAiraloUserEsimFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAiraloUserEsimsQuery> {
    const statement = `query ListAiraloUserEsims($filter: ModelAiraloUserEsimFilterInput, $limit: Int, $nextToken: String) {
        listAiraloUserEsims(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            active
            country
            iccid
            operator
            owner
            createdAt
            updateAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAiraloUserEsimsQuery>response.data.listAiraloUserEsims;
  }
  async GetCredencial(id: string): Promise<GetCredencialQuery> {
    const statement = `query GetCredencial($id: ID!) {
        getCredencial(id: $id) {
          __typename
          id
          app
          token_type
          token
          description
          activo
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCredencialQuery>response.data.getCredencial;
  }
  async ListCredencials(
    filter?: ModelCredencialFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCredencialsQuery> {
    const statement = `query ListCredencials($filter: ModelCredencialFilterInput, $limit: Int, $nextToken: String) {
        listCredencials(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            app
            token_type
            token
            description
            activo
            createdAt
            updateAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCredencialsQuery>response.data.listCredencials;
  }
  async WalletsByEmailAndTelephone(
    email: string,
    telephone?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelWalletFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<WalletsByEmailAndTelephoneQuery> {
    const statement = `query WalletsByEmailAndTelephone($email: String!, $telephone: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelWalletFilterInput, $limit: Int, $nextToken: String) {
        walletsByEmailAndTelephone(
          email: $email
          telephone: $telephone
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            address_id
            address
            telephone
            email
            owner
            createdAt
            network
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      email
    };
    if (telephone) {
      gqlAPIServiceArguments.telephone = telephone;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WalletsByEmailAndTelephoneQuery>(
      response.data.walletsByEmailAndTelephone
    );
  }
  async WalletsByOwnerAndEmail(
    owner: string,
    email?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelWalletFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<WalletsByOwnerAndEmailQuery> {
    const statement = `query WalletsByOwnerAndEmail($owner: String!, $email: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelWalletFilterInput, $limit: Int, $nextToken: String) {
        walletsByOwnerAndEmail(
          owner: $owner
          email: $email
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            address_id
            address
            telephone
            email
            owner
            createdAt
            network
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WalletsByOwnerAndEmailQuery>response.data.walletsByOwnerAndEmail;
  }
  async ContactsByOwnerAndName(
    owner: string,
    name?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ContactsByOwnerAndNameQuery> {
    const statement = `query ContactsByOwnerAndName($owner: String!, $name: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelContactFilterInput, $limit: Int, $nextToken: String) {
        contactsByOwnerAndName(
          owner: $owner
          name: $name
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            telephone
            email
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ContactsByOwnerAndNameQuery>response.data.contactsByOwnerAndName;
  }
  async EULASByOwnerAndCreatedAtAndVersion(
    owner: string,
    createdAtVersion?: ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEULAFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EULASByOwnerAndCreatedAtAndVersionQuery> {
    const statement = `query EULASByOwnerAndCreatedAtAndVersion($owner: String!, $createdAtVersion: ModelEULAEULASByOwnerAndCreatedAtAndVersionCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEULAFilterInput, $limit: Int, $nextToken: String) {
        eULASByOwnerAndCreatedAtAndVersion(
          owner: $owner
          createdAtVersion: $createdAtVersion
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            version
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    if (createdAtVersion) {
      gqlAPIServiceArguments.createdAtVersion = createdAtVersion;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EULASByOwnerAndCreatedAtAndVersionQuery>(
      response.data.eULASByOwnerAndCreatedAtAndVersion
    );
  }
  async CallsByDestinationNumberAndCreatedAt(
    destinationNumber: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallsByDestinationNumberAndCreatedAtQuery> {
    const statement = `query CallsByDestinationNumberAndCreatedAt($destinationNumber: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCallFilterInput, $limit: Int, $nextToken: String) {
        callsByDestinationNumberAndCreatedAt(
          destinationNumber: $destinationNumber
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            identifier
            destinationNumber
            direction
            resource
            owner
            hangup
            info
            about
            visible
            createdAt
            state
            note
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      destinationNumber
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallsByDestinationNumberAndCreatedAtQuery>(
      response.data.callsByDestinationNumberAndCreatedAt
    );
  }
  async CallsByOwnerAndCreatedAt(
    owner: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallsByOwnerAndCreatedAtQuery> {
    const statement = `query CallsByOwnerAndCreatedAt($owner: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCallFilterInput, $limit: Int, $nextToken: String) {
        callsByOwnerAndCreatedAt(
          owner: $owner
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            identifier
            destinationNumber
            direction
            resource
            owner
            hangup
            info
            about
            visible
            createdAt
            state
            note
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallsByOwnerAndCreatedAtQuery>(
      response.data.callsByOwnerAndCreatedAt
    );
  }
  async CallEventsByOwnerAndCreatedAt(
    owner: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallEventsByOwnerAndCreatedAtQuery> {
    const statement = `query CallEventsByOwnerAndCreatedAt($owner: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCallEventFilterInput, $limit: Int, $nextToken: String) {
        callEventsByOwnerAndCreatedAt(
          owner: $owner
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            call
            event
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallEventsByOwnerAndCreatedAtQuery>(
      response.data.callEventsByOwnerAndCreatedAt
    );
  }
  async TransactionsByDestinationAndOwner(
    destination: Account,
    owner?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTransactionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TransactionsByDestinationAndOwnerQuery> {
    const statement = `query TransactionsByDestinationAndOwner($destination: Account!, $owner: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTransactionFilterInput, $limit: Int, $nextToken: String) {
        transactionsByDestinationAndOwner(
          destination: $destination
          owner: $owner
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            resource
            type
            amount
            source
            destination
            currency
            owner
            createdAt
            info
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      destination
    };
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TransactionsByDestinationAndOwnerQuery>(
      response.data.transactionsByDestinationAndOwner
    );
  }
  async TransactionsByOwnerAndCreatedAt(
    owner: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTransactionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TransactionsByOwnerAndCreatedAtQuery> {
    const statement = `query TransactionsByOwnerAndCreatedAt($owner: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTransactionFilterInput, $limit: Int, $nextToken: String) {
        transactionsByOwnerAndCreatedAt(
          owner: $owner
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            resource
            type
            amount
            source
            destination
            currency
            owner
            createdAt
            info
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      owner
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TransactionsByOwnerAndCreatedAtQuery>(
      response.data.transactionsByOwnerAndCreatedAt
    );
  }
  async CountryAllowedsByPrefixAndCountry(
    Prefix: string,
    Country?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCountryAllowedFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CountryAllowedsByPrefixAndCountryQuery> {
    const statement = `query CountryAllowedsByPrefixAndCountry($Prefix: String!, $Country: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCountryAllowedFilterInput, $limit: Int, $nextToken: String) {
        countryAllowedsByPrefixAndCountry(
          Prefix: $Prefix
          Country: $Country
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            Country
            Prefix
            ISO3
            ISO2
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      Prefix
    };
    if (Country) {
      gqlAPIServiceArguments.Country = Country;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CountryAllowedsByPrefixAndCountryQuery>(
      response.data.countryAllowedsByPrefixAndCountry
    );
  }
  async CountryAllowedsByISO3AndPrefix(
    ISO3: string,
    Prefix?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCountryAllowedFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CountryAllowedsByISO3AndPrefixQuery> {
    const statement = `query CountryAllowedsByISO3AndPrefix($ISO3: String!, $Prefix: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCountryAllowedFilterInput, $limit: Int, $nextToken: String) {
        countryAllowedsByISO3AndPrefix(
          ISO3: $ISO3
          Prefix: $Prefix
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            Country
            Prefix
            ISO3
            ISO2
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      ISO3
    };
    if (Prefix) {
      gqlAPIServiceArguments.Prefix = Prefix;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CountryAllowedsByISO3AndPrefixQuery>(
      response.data.countryAllowedsByISO3AndPrefix
    );
  }
  async TPVSByDomainAndEnvironment(
    domain: string,
    environment?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTPVFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TPVSByDomainAndEnvironmentQuery> {
    const statement = `query TPVSByDomainAndEnvironment($domain: String!, $environment: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTPVFilterInput, $limit: Int, $nextToken: String) {
        tPVSByDomainAndEnvironment(
          domain: $domain
          environment: $environment
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            domain
            environment
            app
            pageSize
            paginationSize
            entidadbancaria
            termsAndConditionsVersion
            transfersUrl
            transfersAccountURL
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      domain
    };
    if (environment) {
      gqlAPIServiceArguments.environment = environment;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TPVSByDomainAndEnvironmentQuery>(
      response.data.tPVSByDomainAndEnvironment
    );
  }
  async TPVSByEnvironmentAndDomain(
    environment: string,
    domain?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTPVFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TPVSByEnvironmentAndDomainQuery> {
    const statement = `query TPVSByEnvironmentAndDomain($environment: String!, $domain: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTPVFilterInput, $limit: Int, $nextToken: String) {
        tPVSByEnvironmentAndDomain(
          environment: $environment
          domain: $domain
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            domain
            environment
            app
            pageSize
            paginationSize
            entidadbancaria
            termsAndConditionsVersion
            transfersUrl
            transfersAccountURL
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      environment
    };
    if (domain) {
      gqlAPIServiceArguments.domain = domain;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TPVSByEnvironmentAndDomainQuery>(
      response.data.tPVSByEnvironmentAndDomain
    );
  }
  async WhitelistsByEmailAndTelephone(
    email: string,
    telephone?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelWhitelistFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<WhitelistsByEmailAndTelephoneQuery> {
    const statement = `query WhitelistsByEmailAndTelephone($email: String!, $telephone: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelWhitelistFilterInput, $limit: Int, $nextToken: String) {
        whitelistsByEmailAndTelephone(
          email: $email
          telephone: $telephone
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            email
            telephone
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      email
    };
    if (telephone) {
      gqlAPIServiceArguments.telephone = telephone;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WhitelistsByEmailAndTelephoneQuery>(
      response.data.whitelistsByEmailAndTelephone
    );
  }
  async WhitelistsByTelephoneAndEmail(
    telephone: string,
    email?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelWhitelistFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<WhitelistsByTelephoneAndEmailQuery> {
    const statement = `query WhitelistsByTelephoneAndEmail($telephone: String!, $email: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelWhitelistFilterInput, $limit: Int, $nextToken: String) {
        whitelistsByTelephoneAndEmail(
          telephone: $telephone
          email: $email
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            email
            telephone
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      telephone
    };
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WhitelistsByTelephoneAndEmailQuery>(
      response.data.whitelistsByTelephoneAndEmail
    );
  }
  async AiraloPackagesByCountryAndId_pkg(
    country: string,
    id_pkg?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAiraloPackagesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AiraloPackagesByCountryAndId_pkgQuery> {
    const statement = `query AiraloPackagesByCountryAndId_pkg($country: String!, $id_pkg: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAiraloPackagesFilterInput, $limit: Int, $nextToken: String) {
        airaloPackagesByCountryAndId_pkg(
          country: $country
          id_pkg: $id_pkg
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            country
            country_code
            id_pkg
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country
    };
    if (id_pkg) {
      gqlAPIServiceArguments.id_pkg = id_pkg;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AiraloPackagesByCountryAndId_pkgQuery>(
      response.data.airaloPackagesByCountryAndId_pkg
    );
  }
  async AiraloPackagesByCountry_codeAndCountry(
    country_code: string,
    country?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAiraloPackagesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AiraloPackagesByCountry_codeAndCountryQuery> {
    const statement = `query AiraloPackagesByCountry_codeAndCountry($country_code: String!, $country: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAiraloPackagesFilterInput, $limit: Int, $nextToken: String) {
        airaloPackagesByCountry_codeAndCountry(
          country_code: $country_code
          country: $country
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            country
            country_code
            id_pkg
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country_code
    };
    if (country) {
      gqlAPIServiceArguments.country = country;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AiraloPackagesByCountry_codeAndCountryQuery>(
      response.data.airaloPackagesByCountry_codeAndCountry
    );
  }
  async AiraloPackagesById_pkgAndCountry(
    id_pkg: string,
    country?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAiraloPackagesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AiraloPackagesById_pkgAndCountryQuery> {
    const statement = `query AiraloPackagesById_pkgAndCountry($id_pkg: String!, $country: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAiraloPackagesFilterInput, $limit: Int, $nextToken: String) {
        airaloPackagesById_pkgAndCountry(
          id_pkg: $id_pkg
          country: $country
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            country
            country_code
            id_pkg
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id_pkg
    };
    if (country) {
      gqlAPIServiceArguments.country = country;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AiraloPackagesById_pkgAndCountryQuery>(
      response.data.airaloPackagesById_pkgAndCountry
    );
  }
  async AiraloPricesByPackageIdAndCountry(
    PackageId: string,
    Country?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAiraloPricesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AiraloPricesByPackageIdAndCountryQuery> {
    const statement = `query AiraloPricesByPackageIdAndCountry($PackageId: String!, $Country: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAiraloPricesFilterInput, $limit: Int, $nextToken: String) {
        airaloPricesByPackageIdAndCountry(
          PackageId: $PackageId
          Country: $Country
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            Country
            PackageId
            type
            Netprice
            PartnerMinPrice
            ourPrice
            updatedAt
            createdAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PackageId
    };
    if (Country) {
      gqlAPIServiceArguments.Country = Country;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AiraloPricesByPackageIdAndCountryQuery>(
      response.data.airaloPricesByPackageIdAndCountry
    );
  }
  async AiraloTokensByAccess_tokenAndActivo(
    access_token: string,
    activo?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAiraloTokenFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AiraloTokensByAccess_tokenAndActivoQuery> {
    const statement = `query AiraloTokensByAccess_tokenAndActivo($access_token: String!, $activo: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAiraloTokenFilterInput, $limit: Int, $nextToken: String) {
        airaloTokensByAccess_tokenAndActivo(
          access_token: $access_token
          activo: $activo
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            token_type
            expires_in
            access_token
            activo
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      access_token
    };
    if (activo) {
      gqlAPIServiceArguments.activo = activo;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AiraloTokensByAccess_tokenAndActivoQuery>(
      response.data.airaloTokensByAccess_tokenAndActivo
    );
  }
  async AiraloUserEsimsByIccidAndOwner(
    iccid: string,
    owner?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAiraloUserEsimFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AiraloUserEsimsByIccidAndOwnerQuery> {
    const statement = `query AiraloUserEsimsByIccidAndOwner($iccid: String!, $owner: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAiraloUserEsimFilterInput, $limit: Int, $nextToken: String) {
        airaloUserEsimsByIccidAndOwner(
          iccid: $iccid
          owner: $owner
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            active
            country
            iccid
            operator
            owner
            createdAt
            updateAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      iccid
    };
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AiraloUserEsimsByIccidAndOwnerQuery>(
      response.data.airaloUserEsimsByIccidAndOwner
    );
  }
  async CredencialsByAppAndActivo(
    app: string,
    activo?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCredencialFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CredencialsByAppAndActivoQuery> {
    const statement = `query CredencialsByAppAndActivo($app: String!, $activo: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCredencialFilterInput, $limit: Int, $nextToken: String) {
        credencialsByAppAndActivo(
          app: $app
          activo: $activo
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            app
            token_type
            token
            description
            activo
            createdAt
            updateAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      app
    };
    if (activo) {
      gqlAPIServiceArguments.activo = activo;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CredencialsByAppAndActivoQuery>(
      response.data.credencialsByAppAndActivo
    );
  }
  async GetVoiceCallsPricing(id: string): Promise<GetVoiceCallsPricingQuery> {
    const statement = `query GetVoiceCallsPricing($id: ID!) {
        getVoiceCallsPricing(id: $id) {
          __typename
          id
          fromCountryCode
          toCountryCode
          landlineOrMobile
          vendor
          amountPerMinuteEuro
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVoiceCallsPricingQuery>response.data.getVoiceCallsPricing;
  }
  async ListVoiceCallsPricings(
    filter?: ModelVoiceCallsPricingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVoiceCallsPricingsQuery> {
    const statement = `query ListVoiceCallsPricings($filter: ModelVoiceCallsPricingFilterInput, $limit: Int, $nextToken: String) {
        listVoiceCallsPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            fromCountryCode
            toCountryCode
            landlineOrMobile
            vendor
            amountPerMinuteEuro
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVoiceCallsPricingsQuery>response.data.listVoiceCallsPricings;
  }
  async VoiceCallsPricingsByToCountryCodeAndFromCountryCode(
    toCountryCode: string,
    fromCountryCode?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVoiceCallsPricingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VoiceCallsPricingsByToCountryCodeAndFromCountryCodeQuery> {
    const statement = `query VoiceCallsPricingsByToCountryCodeAndFromCountryCode($toCountryCode: String!, $fromCountryCode: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVoiceCallsPricingFilterInput, $limit: Int, $nextToken: String) {
        voiceCallsPricingsByToCountryCodeAndFromCountryCode(
          toCountryCode: $toCountryCode
          fromCountryCode: $fromCountryCode
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            fromCountryCode
            toCountryCode
            landlineOrMobile
            vendor
            amountPerMinuteEuro
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      toCountryCode
    };
    if (fromCountryCode) {
      gqlAPIServiceArguments.fromCountryCode = fromCountryCode;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VoiceCallsPricingsByToCountryCodeAndFromCountryCodeQuery>(
      response.data.voiceCallsPricingsByToCountryCodeAndFromCountryCode
    );
  }
  async GetVoiceCallsPricingSchemeNetwork(
    id: string
  ): Promise<GetVoiceCallsPricingSchemeNetworkQuery> {
    const statement = `query GetVoiceCallsPricingSchemeNetwork($id: ID!) {
        getVoiceCallsPricingSchemeNetwork(id: $id) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          default
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVoiceCallsPricingSchemeNetworkQuery>(
      response.data.getVoiceCallsPricingSchemeNetwork
    );
  }
  async ListVoiceCallsPricingSchemeNetworks(
    filter?: ModelVoiceCallsPricingSchemeNetworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVoiceCallsPricingSchemeNetworksQuery> {
    const statement = `query ListVoiceCallsPricingSchemeNetworks($filter: ModelVoiceCallsPricingSchemeNetworkFilterInput, $limit: Int, $nextToken: String) {
        listVoiceCallsPricingSchemeNetworks(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            network
            amountPerMinuteEuro
            toCountryCode
            regexFilter
            default
            vendor
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVoiceCallsPricingSchemeNetworksQuery>(
      response.data.listVoiceCallsPricingSchemeNetworks
    );
  }
  async VoiceCallsPricingSchemeNetworksByToCountryCodeAndNetwork(
    toCountryCode: string,
    network?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVoiceCallsPricingSchemeNetworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VoiceCallsPricingSchemeNetworksByToCountryCodeAndNetworkQuery> {
    const statement = `query VoiceCallsPricingSchemeNetworksByToCountryCodeAndNetwork($toCountryCode: String!, $network: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVoiceCallsPricingSchemeNetworkFilterInput, $limit: Int, $nextToken: String) {
        voiceCallsPricingSchemeNetworksByToCountryCodeAndNetwork(
          toCountryCode: $toCountryCode
          network: $network
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            network
            amountPerMinuteEuro
            toCountryCode
            regexFilter
            default
            vendor
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      toCountryCode
    };
    if (network) {
      gqlAPIServiceArguments.network = network;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VoiceCallsPricingSchemeNetworksByToCountryCodeAndNetworkQuery>(
      response.data.voiceCallsPricingSchemeNetworksByToCountryCodeAndNetwork
    );
  }
  async VoiceCallsPricingSchemeNetworksByToCountryCodeAndRegexFilter(
    toCountryCode: string,
    regexFilter?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVoiceCallsPricingSchemeNetworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<
    VoiceCallsPricingSchemeNetworksByToCountryCodeAndRegexFilterQuery
  > {
    const statement = `query VoiceCallsPricingSchemeNetworksByToCountryCodeAndRegexFilter($toCountryCode: String!, $regexFilter: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVoiceCallsPricingSchemeNetworkFilterInput, $limit: Int, $nextToken: String) {
        voiceCallsPricingSchemeNetworksByToCountryCodeAndRegexFilter(
          toCountryCode: $toCountryCode
          regexFilter: $regexFilter
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            network
            amountPerMinuteEuro
            toCountryCode
            regexFilter
            default
            vendor
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      toCountryCode
    };
    if (regexFilter) {
      gqlAPIServiceArguments.regexFilter = regexFilter;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VoiceCallsPricingSchemeNetworksByToCountryCodeAndRegexFilterQuery>(
      response.data.voiceCallsPricingSchemeNetworksByToCountryCodeAndRegexFilter
    );
  }
  async GetVoiceCallsPricingSchemeCountry(
    id: string
  ): Promise<GetVoiceCallsPricingSchemeCountryQuery> {
    const statement = `query GetVoiceCallsPricingSchemeCountry($id: ID!) {
        getVoiceCallsPricingSchemeCountry(id: $id) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVoiceCallsPricingSchemeCountryQuery>(
      response.data.getVoiceCallsPricingSchemeCountry
    );
  }
  async ListVoiceCallsPricingSchemeCountries(
    filter?: ModelVoiceCallsPricingSchemeCountryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVoiceCallsPricingSchemeCountriesQuery> {
    const statement = `query ListVoiceCallsPricingSchemeCountries($filter: ModelVoiceCallsPricingSchemeCountryFilterInput, $limit: Int, $nextToken: String) {
        listVoiceCallsPricingSchemeCountries(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            network
            amountPerMinuteEuro
            toCountryCode
            regexFilter
            vendor
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVoiceCallsPricingSchemeCountriesQuery>(
      response.data.listVoiceCallsPricingSchemeCountries
    );
  }
  async VoiceCallsPricingSchemeCountriesByToCountryCodeAndNetwork(
    toCountryCode: string,
    network?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVoiceCallsPricingSchemeCountryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VoiceCallsPricingSchemeCountriesByToCountryCodeAndNetworkQuery> {
    const statement = `query VoiceCallsPricingSchemeCountriesByToCountryCodeAndNetwork($toCountryCode: String!, $network: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVoiceCallsPricingSchemeCountryFilterInput, $limit: Int, $nextToken: String) {
        voiceCallsPricingSchemeCountriesByToCountryCodeAndNetwork(
          toCountryCode: $toCountryCode
          network: $network
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            network
            amountPerMinuteEuro
            toCountryCode
            regexFilter
            vendor
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      toCountryCode
    };
    if (network) {
      gqlAPIServiceArguments.network = network;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VoiceCallsPricingSchemeCountriesByToCountryCodeAndNetworkQuery>(
      response.data.voiceCallsPricingSchemeCountriesByToCountryCodeAndNetwork
    );
  }
  async GetVoiceCallsBillingNetwork(
    id: string
  ): Promise<GetVoiceCallsBillingNetworkQuery> {
    const statement = `query GetVoiceCallsBillingNetwork($id: ID!) {
        getVoiceCallsBillingNetwork(id: $id) {
          __typename
          id
          customer
          network
          createdAt
          updatedAt
          minutes
          operationalRPM
          operationalRevenue
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVoiceCallsBillingNetworkQuery>(
      response.data.getVoiceCallsBillingNetwork
    );
  }
  async ListVoiceCallsBillingNetworks(
    filter?: ModelVoiceCallsBillingNetworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVoiceCallsBillingNetworksQuery> {
    const statement = `query ListVoiceCallsBillingNetworks($filter: ModelVoiceCallsBillingNetworkFilterInput, $limit: Int, $nextToken: String) {
        listVoiceCallsBillingNetworks(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            customer
            network
            createdAt
            updatedAt
            minutes
            operationalRPM
            operationalRevenue
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVoiceCallsBillingNetworksQuery>(
      response.data.listVoiceCallsBillingNetworks
    );
  }
  async VoiceCallsBillingNetworksByCustomerAndCreatedAt(
    customer: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVoiceCallsBillingNetworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VoiceCallsBillingNetworksByCustomerAndCreatedAtQuery> {
    const statement = `query VoiceCallsBillingNetworksByCustomerAndCreatedAt($customer: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVoiceCallsBillingNetworkFilterInput, $limit: Int, $nextToken: String) {
        voiceCallsBillingNetworksByCustomerAndCreatedAt(
          customer: $customer
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            customer
            network
            createdAt
            updatedAt
            minutes
            operationalRPM
            operationalRevenue
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customer
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VoiceCallsBillingNetworksByCustomerAndCreatedAtQuery>(
      response.data.voiceCallsBillingNetworksByCustomerAndCreatedAt
    );
  }
  OnCreateWalletListener(
    filter?: ModelSubscriptionWalletFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateWallet">>
  > {
    const statement = `subscription OnCreateWallet($filter: ModelSubscriptionWalletFilterInput) {
        onCreateWallet(filter: $filter) {
          __typename
          id
          address_id
          address
          telephone
          email
          owner
          createdAt
          network
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateWallet">>
    >;
  }

  OnUpdateWalletListener(
    filter?: ModelSubscriptionWalletFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateWallet">>
  > {
    const statement = `subscription OnUpdateWallet($filter: ModelSubscriptionWalletFilterInput) {
        onUpdateWallet(filter: $filter) {
          __typename
          id
          address_id
          address
          telephone
          email
          owner
          createdAt
          network
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateWallet">>
    >;
  }

  OnDeleteWalletListener(
    filter?: ModelSubscriptionWalletFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteWallet">>
  > {
    const statement = `subscription OnDeleteWallet($filter: ModelSubscriptionWalletFilterInput) {
        onDeleteWallet(filter: $filter) {
          __typename
          id
          address_id
          address
          telephone
          email
          owner
          createdAt
          network
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteWallet">>
    >;
  }

  OnCreateContactListener(
    filter?: ModelSubscriptionContactFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateContact">>
  > {
    const statement = `subscription OnCreateContact($filter: ModelSubscriptionContactFilterInput) {
        onCreateContact(filter: $filter) {
          __typename
          id
          name
          telephone
          email
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateContact">>
    >;
  }

  OnUpdateContactListener(
    filter?: ModelSubscriptionContactFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateContact">>
  > {
    const statement = `subscription OnUpdateContact($filter: ModelSubscriptionContactFilterInput) {
        onUpdateContact(filter: $filter) {
          __typename
          id
          name
          telephone
          email
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateContact">>
    >;
  }

  OnDeleteContactListener(
    filter?: ModelSubscriptionContactFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteContact">>
  > {
    const statement = `subscription OnDeleteContact($filter: ModelSubscriptionContactFilterInput) {
        onDeleteContact(filter: $filter) {
          __typename
          id
          name
          telephone
          email
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteContact">>
    >;
  }

  OnCreateEULAListener(
    filter?: ModelSubscriptionEULAFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEULA">>
  > {
    const statement = `subscription OnCreateEULA($filter: ModelSubscriptionEULAFilterInput) {
        onCreateEULA(filter: $filter) {
          __typename
          id
          version
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEULA">>
    >;
  }

  OnUpdateEULAListener(
    filter?: ModelSubscriptionEULAFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEULA">>
  > {
    const statement = `subscription OnUpdateEULA($filter: ModelSubscriptionEULAFilterInput) {
        onUpdateEULA(filter: $filter) {
          __typename
          id
          version
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEULA">>
    >;
  }

  OnDeleteEULAListener(
    filter?: ModelSubscriptionEULAFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEULA">>
  > {
    const statement = `subscription OnDeleteEULA($filter: ModelSubscriptionEULAFilterInput) {
        onDeleteEULA(filter: $filter) {
          __typename
          id
          version
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEULA">>
    >;
  }

  OnCreateCallListener(
    filter?: ModelSubscriptionCallFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCall">>
  > {
    const statement = `subscription OnCreateCall($filter: ModelSubscriptionCallFilterInput) {
        onCreateCall(filter: $filter) {
          __typename
          id
          identifier
          destinationNumber
          direction
          resource
          owner
          hangup
          info
          about
          visible
          createdAt
          state
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCall">>
    >;
  }

  OnUpdateCallListener(
    filter?: ModelSubscriptionCallFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCall">>
  > {
    const statement = `subscription OnUpdateCall($filter: ModelSubscriptionCallFilterInput) {
        onUpdateCall(filter: $filter) {
          __typename
          id
          identifier
          destinationNumber
          direction
          resource
          owner
          hangup
          info
          about
          visible
          createdAt
          state
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCall">>
    >;
  }

  OnDeleteCallListener(
    filter?: ModelSubscriptionCallFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCall">>
  > {
    const statement = `subscription OnDeleteCall($filter: ModelSubscriptionCallFilterInput) {
        onDeleteCall(filter: $filter) {
          __typename
          id
          identifier
          destinationNumber
          direction
          resource
          owner
          hangup
          info
          about
          visible
          createdAt
          state
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCall">>
    >;
  }

  OnCreateCallEventListener(
    filter?: ModelSubscriptionCallEventFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallEvent">>
  > {
    const statement = `subscription OnCreateCallEvent($filter: ModelSubscriptionCallEventFilterInput) {
        onCreateCallEvent(filter: $filter) {
          __typename
          id
          call
          event
          createdAt
          owner
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallEvent">>
    >;
  }

  OnUpdateCallEventListener(
    filter?: ModelSubscriptionCallEventFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallEvent">>
  > {
    const statement = `subscription OnUpdateCallEvent($filter: ModelSubscriptionCallEventFilterInput) {
        onUpdateCallEvent(filter: $filter) {
          __typename
          id
          call
          event
          createdAt
          owner
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallEvent">>
    >;
  }

  OnDeleteCallEventListener(
    filter?: ModelSubscriptionCallEventFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallEvent">>
  > {
    const statement = `subscription OnDeleteCallEvent($filter: ModelSubscriptionCallEventFilterInput) {
        onDeleteCallEvent(filter: $filter) {
          __typename
          id
          call
          event
          createdAt
          owner
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallEvent">>
    >;
  }

  OnCreateTransactionListener(
    filter?: ModelSubscriptionTransactionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTransaction">>
  > {
    const statement = `subscription OnCreateTransaction($filter: ModelSubscriptionTransactionFilterInput) {
        onCreateTransaction(filter: $filter) {
          __typename
          id
          resource
          type
          amount
          source
          destination
          currency
          owner
          createdAt
          info
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTransaction">>
    >;
  }

  OnUpdateTransactionListener(
    filter?: ModelSubscriptionTransactionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTransaction">>
  > {
    const statement = `subscription OnUpdateTransaction($filter: ModelSubscriptionTransactionFilterInput) {
        onUpdateTransaction(filter: $filter) {
          __typename
          id
          resource
          type
          amount
          source
          destination
          currency
          owner
          createdAt
          info
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTransaction">>
    >;
  }

  OnDeleteTransactionListener(
    filter?: ModelSubscriptionTransactionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTransaction">>
  > {
    const statement = `subscription OnDeleteTransaction($filter: ModelSubscriptionTransactionFilterInput) {
        onDeleteTransaction(filter: $filter) {
          __typename
          id
          resource
          type
          amount
          source
          destination
          currency
          owner
          createdAt
          info
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTransaction">>
    >;
  }

  OnCreateCountryAllowedListener(
    filter?: ModelSubscriptionCountryAllowedFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCountryAllowed">
    >
  > {
    const statement = `subscription OnCreateCountryAllowed($filter: ModelSubscriptionCountryAllowedFilterInput) {
        onCreateCountryAllowed(filter: $filter) {
          __typename
          id
          Country
          Prefix
          ISO3
          ISO2
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCountryAllowed">
      >
    >;
  }

  OnUpdateCountryAllowedListener(
    filter?: ModelSubscriptionCountryAllowedFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCountryAllowed">
    >
  > {
    const statement = `subscription OnUpdateCountryAllowed($filter: ModelSubscriptionCountryAllowedFilterInput) {
        onUpdateCountryAllowed(filter: $filter) {
          __typename
          id
          Country
          Prefix
          ISO3
          ISO2
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCountryAllowed">
      >
    >;
  }

  OnDeleteCountryAllowedListener(
    filter?: ModelSubscriptionCountryAllowedFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCountryAllowed">
    >
  > {
    const statement = `subscription OnDeleteCountryAllowed($filter: ModelSubscriptionCountryAllowedFilterInput) {
        onDeleteCountryAllowed(filter: $filter) {
          __typename
          id
          Country
          Prefix
          ISO3
          ISO2
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCountryAllowed">
      >
    >;
  }

  OnCreateTPVListener(
    filter?: ModelSubscriptionTPVFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTPV">>
  > {
    const statement = `subscription OnCreateTPV($filter: ModelSubscriptionTPVFilterInput) {
        onCreateTPV(filter: $filter) {
          __typename
          id
          domain
          environment
          app
          pageSize
          paginationSize
          entidadbancaria
          termsAndConditionsVersion
          transfersUrl
          transfersAccountURL
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTPV">>
    >;
  }

  OnUpdateTPVListener(
    filter?: ModelSubscriptionTPVFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTPV">>
  > {
    const statement = `subscription OnUpdateTPV($filter: ModelSubscriptionTPVFilterInput) {
        onUpdateTPV(filter: $filter) {
          __typename
          id
          domain
          environment
          app
          pageSize
          paginationSize
          entidadbancaria
          termsAndConditionsVersion
          transfersUrl
          transfersAccountURL
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTPV">>
    >;
  }

  OnDeleteTPVListener(
    filter?: ModelSubscriptionTPVFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTPV">>
  > {
    const statement = `subscription OnDeleteTPV($filter: ModelSubscriptionTPVFilterInput) {
        onDeleteTPV(filter: $filter) {
          __typename
          id
          domain
          environment
          app
          pageSize
          paginationSize
          entidadbancaria
          termsAndConditionsVersion
          transfersUrl
          transfersAccountURL
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTPV">>
    >;
  }

  OnCreateWhitelistListener(
    filter?: ModelSubscriptionWhitelistFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateWhitelist">>
  > {
    const statement = `subscription OnCreateWhitelist($filter: ModelSubscriptionWhitelistFilterInput) {
        onCreateWhitelist(filter: $filter) {
          __typename
          id
          email
          telephone
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateWhitelist">>
    >;
  }

  OnUpdateWhitelistListener(
    filter?: ModelSubscriptionWhitelistFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateWhitelist">>
  > {
    const statement = `subscription OnUpdateWhitelist($filter: ModelSubscriptionWhitelistFilterInput) {
        onUpdateWhitelist(filter: $filter) {
          __typename
          id
          email
          telephone
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateWhitelist">>
    >;
  }

  OnDeleteWhitelistListener(
    filter?: ModelSubscriptionWhitelistFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteWhitelist">>
  > {
    const statement = `subscription OnDeleteWhitelist($filter: ModelSubscriptionWhitelistFilterInput) {
        onDeleteWhitelist(filter: $filter) {
          __typename
          id
          email
          telephone
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteWhitelist">>
    >;
  }

  OnCreateBannedListener(
    filter?: ModelSubscriptionBannedFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBanned">>
  > {
    const statement = `subscription OnCreateBanned($filter: ModelSubscriptionBannedFilterInput) {
        onCreateBanned(filter: $filter) {
          __typename
          id
          sub
          reason
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBanned">>
    >;
  }

  OnUpdateBannedListener(
    filter?: ModelSubscriptionBannedFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBanned">>
  > {
    const statement = `subscription OnUpdateBanned($filter: ModelSubscriptionBannedFilterInput) {
        onUpdateBanned(filter: $filter) {
          __typename
          id
          sub
          reason
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBanned">>
    >;
  }

  OnDeleteBannedListener(
    filter?: ModelSubscriptionBannedFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBanned">>
  > {
    const statement = `subscription OnDeleteBanned($filter: ModelSubscriptionBannedFilterInput) {
        onDeleteBanned(filter: $filter) {
          __typename
          id
          sub
          reason
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBanned">>
    >;
  }

  OnCreateAiraloPackagesListener(
    filter?: ModelSubscriptionAiraloPackagesFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAiraloPackages">
    >
  > {
    const statement = `subscription OnCreateAiraloPackages($filter: ModelSubscriptionAiraloPackagesFilterInput) {
        onCreateAiraloPackages(filter: $filter) {
          __typename
          id
          country
          country_code
          id_pkg
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAiraloPackages">
      >
    >;
  }

  OnUpdateAiraloPackagesListener(
    filter?: ModelSubscriptionAiraloPackagesFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAiraloPackages">
    >
  > {
    const statement = `subscription OnUpdateAiraloPackages($filter: ModelSubscriptionAiraloPackagesFilterInput) {
        onUpdateAiraloPackages(filter: $filter) {
          __typename
          id
          country
          country_code
          id_pkg
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAiraloPackages">
      >
    >;
  }

  OnDeleteAiraloPackagesListener(
    filter?: ModelSubscriptionAiraloPackagesFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAiraloPackages">
    >
  > {
    const statement = `subscription OnDeleteAiraloPackages($filter: ModelSubscriptionAiraloPackagesFilterInput) {
        onDeleteAiraloPackages(filter: $filter) {
          __typename
          id
          country
          country_code
          id_pkg
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAiraloPackages">
      >
    >;
  }

  OnCreateAiraloPricesListener(
    filter?: ModelSubscriptionAiraloPricesFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAiraloPrices">>
  > {
    const statement = `subscription OnCreateAiraloPrices($filter: ModelSubscriptionAiraloPricesFilterInput) {
        onCreateAiraloPrices(filter: $filter) {
          __typename
          id
          Country
          PackageId
          type
          Netprice
          PartnerMinPrice
          ourPrice
          updatedAt
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAiraloPrices">
      >
    >;
  }

  OnUpdateAiraloPricesListener(
    filter?: ModelSubscriptionAiraloPricesFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAiraloPrices">>
  > {
    const statement = `subscription OnUpdateAiraloPrices($filter: ModelSubscriptionAiraloPricesFilterInput) {
        onUpdateAiraloPrices(filter: $filter) {
          __typename
          id
          Country
          PackageId
          type
          Netprice
          PartnerMinPrice
          ourPrice
          updatedAt
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAiraloPrices">
      >
    >;
  }

  OnDeleteAiraloPricesListener(
    filter?: ModelSubscriptionAiraloPricesFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAiraloPrices">>
  > {
    const statement = `subscription OnDeleteAiraloPrices($filter: ModelSubscriptionAiraloPricesFilterInput) {
        onDeleteAiraloPrices(filter: $filter) {
          __typename
          id
          Country
          PackageId
          type
          Netprice
          PartnerMinPrice
          ourPrice
          updatedAt
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAiraloPrices">
      >
    >;
  }

  OnCreateAiraloTokenListener(
    filter?: ModelSubscriptionAiraloTokenFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAiraloToken">>
  > {
    const statement = `subscription OnCreateAiraloToken($filter: ModelSubscriptionAiraloTokenFilterInput) {
        onCreateAiraloToken(filter: $filter) {
          __typename
          id
          token_type
          expires_in
          access_token
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAiraloToken">>
    >;
  }

  OnUpdateAiraloTokenListener(
    filter?: ModelSubscriptionAiraloTokenFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAiraloToken">>
  > {
    const statement = `subscription OnUpdateAiraloToken($filter: ModelSubscriptionAiraloTokenFilterInput) {
        onUpdateAiraloToken(filter: $filter) {
          __typename
          id
          token_type
          expires_in
          access_token
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAiraloToken">>
    >;
  }

  OnDeleteAiraloTokenListener(
    filter?: ModelSubscriptionAiraloTokenFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAiraloToken">>
  > {
    const statement = `subscription OnDeleteAiraloToken($filter: ModelSubscriptionAiraloTokenFilterInput) {
        onDeleteAiraloToken(filter: $filter) {
          __typename
          id
          token_type
          expires_in
          access_token
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAiraloToken">>
    >;
  }

  OnCreateAiraloEsimOrdersListener(
    filter?: ModelSubscriptionAiraloEsimOrdersFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAiraloEsimOrders">
    >
  > {
    const statement = `subscription OnCreateAiraloEsimOrders($filter: ModelSubscriptionAiraloEsimOrdersFilterInput) {
        onCreateAiraloEsimOrders(filter: $filter) {
          __typename
          id
          owner
          type
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAiraloEsimOrders">
      >
    >;
  }

  OnUpdateAiraloEsimOrdersListener(
    filter?: ModelSubscriptionAiraloEsimOrdersFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAiraloEsimOrders">
    >
  > {
    const statement = `subscription OnUpdateAiraloEsimOrders($filter: ModelSubscriptionAiraloEsimOrdersFilterInput) {
        onUpdateAiraloEsimOrders(filter: $filter) {
          __typename
          id
          owner
          type
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAiraloEsimOrders">
      >
    >;
  }

  OnDeleteAiraloEsimOrdersListener(
    filter?: ModelSubscriptionAiraloEsimOrdersFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAiraloEsimOrders">
    >
  > {
    const statement = `subscription OnDeleteAiraloEsimOrders($filter: ModelSubscriptionAiraloEsimOrdersFilterInput) {
        onDeleteAiraloEsimOrders(filter: $filter) {
          __typename
          id
          owner
          type
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAiraloEsimOrders">
      >
    >;
  }

  OnCreateAiraloTopUpOrdersListener(
    filter?: ModelSubscriptionAiraloTopUpOrdersFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAiraloTopUpOrders">
    >
  > {
    const statement = `subscription OnCreateAiraloTopUpOrders($filter: ModelSubscriptionAiraloTopUpOrdersFilterInput) {
        onCreateAiraloTopUpOrders(filter: $filter) {
          __typename
          id
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAiraloTopUpOrders">
      >
    >;
  }

  OnUpdateAiraloTopUpOrdersListener(
    filter?: ModelSubscriptionAiraloTopUpOrdersFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAiraloTopUpOrders">
    >
  > {
    const statement = `subscription OnUpdateAiraloTopUpOrders($filter: ModelSubscriptionAiraloTopUpOrdersFilterInput) {
        onUpdateAiraloTopUpOrders(filter: $filter) {
          __typename
          id
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAiraloTopUpOrders">
      >
    >;
  }

  OnDeleteAiraloTopUpOrdersListener(
    filter?: ModelSubscriptionAiraloTopUpOrdersFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAiraloTopUpOrders">
    >
  > {
    const statement = `subscription OnDeleteAiraloTopUpOrders($filter: ModelSubscriptionAiraloTopUpOrdersFilterInput) {
        onDeleteAiraloTopUpOrders(filter: $filter) {
          __typename
          id
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAiraloTopUpOrders">
      >
    >;
  }

  OnCreateAiraloUserEsimListener(
    filter?: ModelSubscriptionAiraloUserEsimFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAiraloUserEsim">
    >
  > {
    const statement = `subscription OnCreateAiraloUserEsim($filter: ModelSubscriptionAiraloUserEsimFilterInput) {
        onCreateAiraloUserEsim(filter: $filter) {
          __typename
          id
          active
          country
          iccid
          operator
          owner
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAiraloUserEsim">
      >
    >;
  }

  OnUpdateAiraloUserEsimListener(
    filter?: ModelSubscriptionAiraloUserEsimFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAiraloUserEsim">
    >
  > {
    const statement = `subscription OnUpdateAiraloUserEsim($filter: ModelSubscriptionAiraloUserEsimFilterInput) {
        onUpdateAiraloUserEsim(filter: $filter) {
          __typename
          id
          active
          country
          iccid
          operator
          owner
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAiraloUserEsim">
      >
    >;
  }

  OnDeleteAiraloUserEsimListener(
    filter?: ModelSubscriptionAiraloUserEsimFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAiraloUserEsim">
    >
  > {
    const statement = `subscription OnDeleteAiraloUserEsim($filter: ModelSubscriptionAiraloUserEsimFilterInput) {
        onDeleteAiraloUserEsim(filter: $filter) {
          __typename
          id
          active
          country
          iccid
          operator
          owner
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAiraloUserEsim">
      >
    >;
  }

  OnCreateCredencialListener(
    filter?: ModelSubscriptionCredencialFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCredencial">>
  > {
    const statement = `subscription OnCreateCredencial($filter: ModelSubscriptionCredencialFilterInput) {
        onCreateCredencial(filter: $filter) {
          __typename
          id
          app
          token_type
          token
          description
          activo
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCredencial">>
    >;
  }

  OnUpdateCredencialListener(
    filter?: ModelSubscriptionCredencialFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCredencial">>
  > {
    const statement = `subscription OnUpdateCredencial($filter: ModelSubscriptionCredencialFilterInput) {
        onUpdateCredencial(filter: $filter) {
          __typename
          id
          app
          token_type
          token
          description
          activo
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCredencial">>
    >;
  }

  OnDeleteCredencialListener(
    filter?: ModelSubscriptionCredencialFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCredencial">>
  > {
    const statement = `subscription OnDeleteCredencial($filter: ModelSubscriptionCredencialFilterInput) {
        onDeleteCredencial(filter: $filter) {
          __typename
          id
          app
          token_type
          token
          description
          activo
          createdAt
          updateAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCredencial">>
    >;
  }

  OnCreateVoiceCallsPricingListener(
    filter?: ModelSubscriptionVoiceCallsPricingFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVoiceCallsPricing">
    >
  > {
    const statement = `subscription OnCreateVoiceCallsPricing($filter: ModelSubscriptionVoiceCallsPricingFilterInput) {
        onCreateVoiceCallsPricing(filter: $filter) {
          __typename
          id
          fromCountryCode
          toCountryCode
          landlineOrMobile
          vendor
          amountPerMinuteEuro
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateVoiceCallsPricing">
      >
    >;
  }

  OnUpdateVoiceCallsPricingListener(
    filter?: ModelSubscriptionVoiceCallsPricingFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVoiceCallsPricing">
    >
  > {
    const statement = `subscription OnUpdateVoiceCallsPricing($filter: ModelSubscriptionVoiceCallsPricingFilterInput) {
        onUpdateVoiceCallsPricing(filter: $filter) {
          __typename
          id
          fromCountryCode
          toCountryCode
          landlineOrMobile
          vendor
          amountPerMinuteEuro
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateVoiceCallsPricing">
      >
    >;
  }

  OnDeleteVoiceCallsPricingListener(
    filter?: ModelSubscriptionVoiceCallsPricingFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVoiceCallsPricing">
    >
  > {
    const statement = `subscription OnDeleteVoiceCallsPricing($filter: ModelSubscriptionVoiceCallsPricingFilterInput) {
        onDeleteVoiceCallsPricing(filter: $filter) {
          __typename
          id
          fromCountryCode
          toCountryCode
          landlineOrMobile
          vendor
          amountPerMinuteEuro
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteVoiceCallsPricing">
      >
    >;
  }

  OnCreateVoiceCallsPricingSchemeNetworkListener(
    filter?: ModelSubscriptionVoiceCallsPricingSchemeNetworkFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVoiceCallsPricingSchemeNetwork">
    >
  > {
    const statement = `subscription OnCreateVoiceCallsPricingSchemeNetwork($filter: ModelSubscriptionVoiceCallsPricingSchemeNetworkFilterInput) {
        onCreateVoiceCallsPricingSchemeNetwork(filter: $filter) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          default
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateVoiceCallsPricingSchemeNetwork">
      >
    >;
  }

  OnUpdateVoiceCallsPricingSchemeNetworkListener(
    filter?: ModelSubscriptionVoiceCallsPricingSchemeNetworkFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVoiceCallsPricingSchemeNetwork">
    >
  > {
    const statement = `subscription OnUpdateVoiceCallsPricingSchemeNetwork($filter: ModelSubscriptionVoiceCallsPricingSchemeNetworkFilterInput) {
        onUpdateVoiceCallsPricingSchemeNetwork(filter: $filter) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          default
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateVoiceCallsPricingSchemeNetwork">
      >
    >;
  }

  OnDeleteVoiceCallsPricingSchemeNetworkListener(
    filter?: ModelSubscriptionVoiceCallsPricingSchemeNetworkFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVoiceCallsPricingSchemeNetwork">
    >
  > {
    const statement = `subscription OnDeleteVoiceCallsPricingSchemeNetwork($filter: ModelSubscriptionVoiceCallsPricingSchemeNetworkFilterInput) {
        onDeleteVoiceCallsPricingSchemeNetwork(filter: $filter) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          default
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteVoiceCallsPricingSchemeNetwork">
      >
    >;
  }

  OnCreateVoiceCallsPricingSchemeCountryListener(
    filter?: ModelSubscriptionVoiceCallsPricingSchemeCountryFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVoiceCallsPricingSchemeCountry">
    >
  > {
    const statement = `subscription OnCreateVoiceCallsPricingSchemeCountry($filter: ModelSubscriptionVoiceCallsPricingSchemeCountryFilterInput) {
        onCreateVoiceCallsPricingSchemeCountry(filter: $filter) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateVoiceCallsPricingSchemeCountry">
      >
    >;
  }

  OnUpdateVoiceCallsPricingSchemeCountryListener(
    filter?: ModelSubscriptionVoiceCallsPricingSchemeCountryFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVoiceCallsPricingSchemeCountry">
    >
  > {
    const statement = `subscription OnUpdateVoiceCallsPricingSchemeCountry($filter: ModelSubscriptionVoiceCallsPricingSchemeCountryFilterInput) {
        onUpdateVoiceCallsPricingSchemeCountry(filter: $filter) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateVoiceCallsPricingSchemeCountry">
      >
    >;
  }

  OnDeleteVoiceCallsPricingSchemeCountryListener(
    filter?: ModelSubscriptionVoiceCallsPricingSchemeCountryFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVoiceCallsPricingSchemeCountry">
    >
  > {
    const statement = `subscription OnDeleteVoiceCallsPricingSchemeCountry($filter: ModelSubscriptionVoiceCallsPricingSchemeCountryFilterInput) {
        onDeleteVoiceCallsPricingSchemeCountry(filter: $filter) {
          __typename
          id
          network
          amountPerMinuteEuro
          toCountryCode
          regexFilter
          vendor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteVoiceCallsPricingSchemeCountry">
      >
    >;
  }

  OnCreateVoiceCallsBillingNetworkListener(
    filter?: ModelSubscriptionVoiceCallsBillingNetworkFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVoiceCallsBillingNetwork">
    >
  > {
    const statement = `subscription OnCreateVoiceCallsBillingNetwork($filter: ModelSubscriptionVoiceCallsBillingNetworkFilterInput) {
        onCreateVoiceCallsBillingNetwork(filter: $filter) {
          __typename
          id
          customer
          network
          createdAt
          updatedAt
          minutes
          operationalRPM
          operationalRevenue
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateVoiceCallsBillingNetwork">
      >
    >;
  }

  OnUpdateVoiceCallsBillingNetworkListener(
    filter?: ModelSubscriptionVoiceCallsBillingNetworkFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVoiceCallsBillingNetwork">
    >
  > {
    const statement = `subscription OnUpdateVoiceCallsBillingNetwork($filter: ModelSubscriptionVoiceCallsBillingNetworkFilterInput) {
        onUpdateVoiceCallsBillingNetwork(filter: $filter) {
          __typename
          id
          customer
          network
          createdAt
          updatedAt
          minutes
          operationalRPM
          operationalRevenue
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateVoiceCallsBillingNetwork">
      >
    >;
  }

  OnDeleteVoiceCallsBillingNetworkListener(
    filter?: ModelSubscriptionVoiceCallsBillingNetworkFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVoiceCallsBillingNetwork">
    >
  > {
    const statement = `subscription OnDeleteVoiceCallsBillingNetwork($filter: ModelSubscriptionVoiceCallsBillingNetworkFilterInput) {
        onDeleteVoiceCallsBillingNetwork(filter: $filter) {
          __typename
          id
          customer
          network
          createdAt
          updatedAt
          minutes
          operationalRPM
          operationalRevenue
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteVoiceCallsBillingNetwork">
      >
    >;
  }
}
