<app-onboarding-header></app-onboarding-header>
<div class="md:max-w-xl md:m-auto flex flex-col items-center mt-6 p-4">
  <img
    class="flex w-24 h-24 rounded-full"
    src="./assets/icons/email-validation.svg"
    alt="Profile image"
  />
  <h2 class="text-center">{{ 'ONBOARDING.FORGOT.TITLE' | translate }}</h2>
  <p class="text-base text-center">
    {{ 'ONBOARDING.FORGOT.DESC' | translate }}
  </p>
  <mat-form-field class="calls-full-width my-8">
    <mat-label>{{ 'ONBOARDING.FORGOT.FIELD' | translate }}</mat-label>
    <input type="text" matInput [(ngModel)]="username" />
  </mat-form-field>
  <app-button (click)="resetPassWord()">
    {{ 'ONBOARDING.FORGOT.BUTTON' | translate }}
  </app-button>
</div>
<div class="md:max-w-xl md:m-auto p-4">
  <hr />
</div>
<div class="flex flex-col items-center p-4">
  <p class="text-sm mb-8 font-medium">
    {{ 'ONBOARDING.FORGOT.QUESTION' | translate }}
  </p>
  <app-button
    color="secondary"
    routerLink="/onboarding/login"
    [replaceUrl]="true"
  >
    {{ 'ONBOARDING.FORGOT.GO_BACK' | translate }}
  </app-button>
</div>
