import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EsimService } from '../esim.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PurchaseDetailsModalComponent } from '../purchase-details-modal/purchase-details-modal.component';

@Component({
  selector: 'app-topups-modal',
  templateUrl: './topups-modal.component.html',
  styleUrls: ['./topups-modal.component.scss']
})
export class TopupsModalComponent {
  topups: any[] = [];
  s: any[] = [];
  isLoading = true;
  isSubmitting = false;

  constructor(
    public dialogRef: MatDialogRef<TopupsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { iccid: string },
    private esimService: EsimService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getTopups();
  }

  async getTopups(): Promise<void> {
    try {
      console.log('iccid', this.data.iccid);
      
      const response = await this.esimService.topupList(this.data.iccid);
      if (Array.isArray(response)) {
        this.topups = response;
      } else {
        this.topups = [];
      }
    } catch (error) {
      console.error('Error fetching topups', error);
      this.topups = [];
    } finally {
      this.isLoading = false;
    }
  }

  async submitTopUp(packageId: string): Promise<void> {
    const amount = '0';
    const tpvType = 'Esim';

    this.isSubmitting = true;
    try {
      // console.log('iccid', this.data.iccid);
      // console.log('packageId', packageId);
      this.isLoading = true;
      const order = await this.esimService.submitTopUpOrder(amount, tpvType, packageId, this.data.iccid);
      this.snackBar.open('Top-up successful!', 'Close', { duration: 3000 });
      this.dialogRef.close(true);
      this.openPurchaseDetailsModal(order);
    } catch (error) {
      console.error('Error submitting top-up', error);
      this.snackBar.open('Error submitting top-up. Please try again.', 'Close', { duration: 3000 });
    } finally {
      this.isSubmitting = false;
    }
  }

  openPurchaseDetailsModal(order: any) {
    this.dialog.open(PurchaseDetailsModalComponent, {
      data: { order }
    });
  }
}
