import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ModalData {
  titleKey: string;
  descKey: string;
  cancelTextKey?: string;
  acceptTextKey?: string;
}
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: []
})
export class ConfirmationComponent {

  constructor(
      public dialog: MatDialogRef<ConfirmationComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ModalData) { }
  
      close(): void {
        this.dialog.close(false);
      }
      confirm(): void {
        this.dialog.close(true);
      }
  
  }