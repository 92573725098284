import { Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AmplifyUser } from '@aws-amplify/ui';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { ConfigService } from 'src/app/config.service';
import { AuthService } from 'src/app/services/auth.service';
import { Auth } from 'aws-amplify';
import { BalanceService } from 'src/app/services/balance.service';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { VerifyNumberComponent } from './verify-number/verify-number.component';
import { AWSLambda } from 'src/app/AWSLambda.service';
import { VerificationNumberAttempt } from 'src/models/extra';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
})
export class MyProfileComponent implements OnInit {
  user!: AmplifyUser;
  authenticatorSvc = inject(AuthenticatorService);
  lambda = inject(AWSLambda);
  auth = inject(AuthService);
  dialog = inject(MatDialog);
  transfersUrl = `${inject(ConfigService).config.transfersUrl}?tracker=${inject(AuthService).getUser().id}`;
  private balanceSvc = inject(BalanceService);
  name: string | undefined;
  phone_number: string | undefined;
  email: string | undefined;
  balance!: number;
  currency: string = `EUR`;
  signalwire_verified_phone_number: boolean | undefined

  async ngOnInit() {
    await this.auth.refresh()
    this.user = await this.authenticatorSvc.user;
    this.balance = await this.balanceSvc.calculateBalance(this.user.username);
    
    if (this.user.attributes?.['name'] != undefined) this.name = this.user.attributes?.['name'];
    if (this.user.attributes?.['phone_number'] != undefined) this.phone_number = this.user.attributes?.['phone_number'];
    if (this.user.attributes?.['email'] != undefined) this.email = this.user.attributes?.['email'];

    this.signalwire_verified_phone_number = ((this.authenticatorSvc.user.attributes?.['custom:signalwire_ok'] == this.authenticatorSvc.user.attributes?.phone_number) && (this.authenticatorSvc.user.attributes?.phone_number != undefined)) ? true : false
  }

  openModal() {
    this.dialog
      .open(DeleteAccountComponent)
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          this.auth.deleteAccount();
        }
      });
  }

  openVerification() {
    // Send an code to Signalwire
    this.lambda.getLambdaFunction("lambda", "/aws/verification/send", {
      queryStringParameters: { phone_number: this.user.attributes?.phone_number, lang: this.user.attributes?.['locale'] ? this.user.attributes?.['locale'] : 'es' },
    }).then( (data) => {
        this.dialog
          .open(VerifyNumberComponent, {
            data: Object.assign({}, data, { phone_number: this.user.attributes?.phone_number })
          })
          .afterClosed()
          .subscribe(( verificationNumberAttempt: VerificationNumberAttempt) => {
            if (verificationNumberAttempt.verified) {
              this.auth.updateUser({ "custom:signalwire_ok": verificationNumberAttempt.phone_number})
              .then( () => {
                  // PONER AQUI UNA LLAMADA A UN LAMBDA crmUpsertTags pasando el id de usar y tags que quiero.
                  this.lambda.getLambdaFunction("lambda", "/crm/contact/update", {
                    queryStringParameters: { sub:  this.user.attributes?.['sub'] , tags: ["promo_pending"]}
                  })

              })
              .then( () => {
                this.auth.refresh().then( () => {
                  this.signalwire_verified_phone_number = true
                })
              })
            }
          })
    }).catch( () => {
      // Error
    })
  } 
  
}
