import { Injectable } from "@angular/core";
import { environment } from "../environments/environment.main";
import { OptionItem } from "./utils/Item";

export type PayPalConfig = {
  clientId: string | "dummy";
};

export type BBVAConfig = {
  notification_url_api: "https://dummy";
  tpv_redirect_url: string | "https://dummy";
  currency_code: string | "978";
  merchant_code: string | "dummy";
  notification_url_ok: string | "https://dummy/recharge/checkout/ok";
  notification_url_ko: string | "https://dummy/recharge/checkout/ko";
  transaction_type: string | "0";
  merchant_terminal_id: string | "1";
  description: string | "RECARGA SERVICIO TELECOMUNICACIONES";
};

export type Config = {
  domain: string | "localhost";
  environment: string | "dummy";
  pageSize: number | 100;
  paginationSize: number | 5;
  paypal: PayPalConfig;
  bbva: BBVAConfig;
  termsAndConditionsVersion: string | "1";
  transfersUrl: string | "";
  transfersAccountURL: string | "https://transfers-dev.zendy.tel";
};

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  config: Config = {
    domain: "localhost",
    environment: "dummy",
    pageSize: 100,
    paginationSize: 5,
    paypal: {
      clientId: "dummy",
    },
    bbva: {
      notification_url_api: "https://dummy",
      tpv_redirect_url: "https://dummy",
      currency_code: "978",
      merchant_code: "dummy",
      notification_url_ok: "https://dummy/recharge/checkout/ok",
      notification_url_ko: "https://dummy/recharge/checkout/ko",
      transaction_type: "0",
      merchant_terminal_id: "1",
      description: "RECARGA SERVICIO TELECOMUNICACIONES",
    },
    termsAndConditionsVersion: "1",
  } as Config;

  constructor() {
    this.config = environment.config as Config;
  }

  getItems() {
    return this.castToItem([
      {
        id: "5_wallet_to_make_calls",
        value: 5,
      },
      {
        id: "10_wallet_to_make_calls",
        value: 10,
      },
      {
        id: "20_wallet_to_make_calls",
        value: 20,
      },
      {
        id: "30_wallet_to_make_calls",
        value: 30,
      },
      {
        id: "50_wallet_to_make_calls",
        value: 50,
      },
      {
        id: "100_wallet_to_make_calls",
        value: 100,
      },
    ]);
  }

  castToItem(items: any[]) {
    return items.map((i) => {
      return {
        id: i.id,
        icon: "shopping_cart",
        title: `Add ${i.value} EUR to make calls`,
        caption: "Via TPV BBVA",
        caption_format: "shopping_cart",
        value: i.value,
        args: [i.value],
      } as OptionItem;
    });
  }
}
