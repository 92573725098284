
import * as data from '../../../assets/json/countries.json' 

interface CountryData {
    [key: string]: {
        [key: string]: {
            "ISO-3": string;
            "Country": string;
        };
    };
}


export function getCountryInfo(prefix: string, iso2: string, returnType: 'ISO-3' | 'Country'): string | undefined {
    const countries: CountryData = data;
    
    const countryInfo = countries[prefix]?.[iso2];

    if (countryInfo) {
        return countryInfo[returnType];
    } else {
        return undefined;
    }
}

