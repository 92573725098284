import { Component, Inject } from '@angular/core';
import { AWSLambda } from 'src/app/AWSLambda.service';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EsimService } from '../esim.service';
import { LoginComponent } from '../../onboarding/login/login.component';
import { AuthenticatorService } from '@aws-amplify/ui-angular';


@Component({
  selector: 'app-buy-now-modal',
  templateUrl: './buy-now-modal.component.html',
  styleUrls: ['./buy-now-modal.component.scss']
})
export class BuyNowModalComponent {
  IsOnline = false;
  public isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<BuyNowModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private esimService: EsimService,
    private snackBar: MatSnackBar,
    private authenticatorSvc: AuthenticatorService,

  ) { }

  async onBuyNow(): Promise<void> {
    // const packageId = this.data.package.id;

    // const userSub = this.authenticatorSvc.user?.attributes?.["sub"];

    // if (userSub) {
    //   this.IsOnline = true;
    // }

    // Simula la verificación de si el usuario está logueado
    const usuario = await this.esimService.getusuario();
    console.log('isLoggedIn', usuario);
    if (usuario == '') {
      // Cierra el modal actual de compra
      this.dialogRef.close();

      // Abre el modal de login
      this.esimService.openLoginModal();

      return;
    }
    this.isLoading = true;

    const { id: packageId, title: packageTitle, price: price } = this.data.package;
    console.log(this.data);
    const { country } = this.data;
    const operatorTitle = this.data.operador;
    const tpvType = 'Esim';
    const amount = '0';

    if (packageId) {
      try {

        const orderData = {
          packageId,
          country,
          operatorTitle,
          price,
        };

        const result = await this.esimService.submitOrder(orderData, tpvType, amount);
        this.snackBar.open('Order submitted successfully', 'Close', { duration: 3000 });
        this.dialogRef.close();
      } catch (error) {
        console.error('Order submission failed', error);
        this.snackBar.open('Order submission failed', 'Close', { duration: 3000 });
      }
    } else {
      console.error('No package_id');
      this.snackBar.open('No package_id found', 'Close', { duration: 3000 });
    }
  }
}
